import React from 'react';

import styles from './Components.module.scss';

const SellBitgemsDiscretion = () => (
  <div className={styles.sellBitgemsDiscretion}>
    You can withdraw BitGems in any form convenient for you. Just select the
    desired currency for withdrawal and we will calculate the conversion rate.
  </div>
);

export default SellBitgemsDiscretion;
