// You need to change the path and import the functions into rootSaga.js
import { takeEvery } from 'redux-saga/effects';
import {
  disableLoader,
  enableLoader,
} from '../../app/rootSaga';

export function* handleMarketLoaders() {
  yield takeEvery('GET_BITGEMS_PACKAGES', enableLoader);
  yield takeEvery('GET_BITGEMS_PACKAGES_SUCCESS', disableLoader);
  yield takeEvery('GET_BITGEMS_PACKAGES_FAIL', disableLoader);
}
