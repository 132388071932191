import React, { useEffect, useMemo, useState } from 'react';
import Parallax from 'parallax-js';
import clsx from 'clsx';

import GamesTable from '../GamesTable';
import TransactionsTable from '../TransactionsTable';
import bannerBg from '../../assets/images/bg.webp';
import { ReactComponent as BannerViking } from '../../assets/images/viking.svg';
import { ReactComponent as BannerPlay } from '../../assets/icons/banner_play.svg';
import { ReactComponent as BannerBit } from '../../assets/icons/banner_bit.svg';
import { ReactComponent as BannerCht } from '../../assets/icons/banner_cht.svg';

import styles from './Dashboard.module.scss';
import { useDispatch } from 'react-redux';
import { clickSound } from '../../app/appActions';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('games');

  useEffect(() => {
    var scene = document.getElementById('scene');
    var parallaxInstance = new Parallax(scene, {
      relativeInput: true,
      limitY: 0,
      limitX: 50,
      scalarX: 3,
      //invertX: false,
      hoverOnly: true
    });
  }, []);

  const renderedTableTabs = useMemo(
    () => (
      <div className={styles.tableTabs}>
        <div
          className={clsx([
            styles.tableTabs__tab,
            activeTab === 'games' && styles.active
          ])}
          onClick={() => {
            setActiveTab('games')
            dispatch(clickSound())
          }}
        >
          Games
        </div>
        <div
          className={clsx([
            styles.tableTabs__tab,
            activeTab === 'transactions' && styles.active
          ])}
          onClick={() => {
            setActiveTab('transactions')
            dispatch(clickSound())
          }}
        >
          Transactions
        </div>
      </div>
    ),
    [activeTab]
  );

  return (
    <main className={`${styles.root} page-wrap`}>
      <div className={`${styles.container} big-container`}>
        <div className={styles.banner}>
          <div id='scene' className={styles.paralax}>
            <img data-depth='0' src={bannerBg} alt='bannerBg' />
            <BannerViking data-depth='0.6' />
          </div>
          <div className={styles.info}>
            {bannerInfo.map(({ icon, title, desc }, idx) => (
              <div key={idx}>
                {icon}
                <h3>{title}</h3>
                {desc}
              </div>
            ))}
          </div>
        </div>

        <div className='card-wrap'>
          {activeTab === 'games' && (
            <GamesTable customTitle={renderedTableTabs} />
          )}
          {activeTab === 'transactions' && (
            <TransactionsTable customTitle={renderedTableTabs} />
          )}
        </div>
      </div>
    </main>
  );
};

const bannerInfo = [
  {
    icon: <BannerPlay />,
    title: `Guaranteed Wins`,
    desc: (
      <p>
        Play and win every round with Crypto Chests. Secure BitGems or exclusive Chest Tokens, where <b>9/10 players</b> win{' '}
        <strong>1.1x</strong> their entry in BitGems, and the tenth player
        receives Chest Tokens.
      </p>
    )
  },
  {
    icon: <BannerBit />,
    title: `BitGems As Stable Currency`,
    desc: (
      <p>
        Use BitGems, each always worth <strong>$1</strong>, to play and
        effortlessly exchange them for popular cryptocurrencies anytime.
      </p>
    )
  },
  {
    icon: <BannerCht />,
    title: `Chest Tokens - A Growing Investment`,
    desc: (
      <p>
        We use part of our revenue to buy back Chest Tokens, unlocking future
        discounts, staking, and jackpot participation.
      </p>
    )
  }
];

export default Dashboard;
