import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  postCreateBitgemsInvoice,
  getCoinRate,
  setPaymentModalMeta,
  resetPaymentModalMeta,
  getCoinsForPayment
} from './paymentModalsActions';
import { ButtonMUI, DialogMUI } from '../../shared';
import CoinsSelect from './components/CoinsSelect';
import PaymentDiscretion from './components/PaymentDiscretion';
import ExtraCHTForBitgems from './components/ExtraCHTForBitgems';
import { formatPrice, renderLoadingPrice } from '../../helpers/functions';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange-rate.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';

import styles from './styles.module.scss';

const BuyBitgemsModal = () => {
  const {
    coins,
    meta: { youPay, youGet, selectedCoin, blockchainCoinRate },
    fetchingCoinRate,
    profileInfo: { balance_bitgems }
  } = useSelector(({ paymentModals, auth }) => ({ ...paymentModals, ...auth }));
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(null);

  useEffect(() => {
    dispatch(getCoinsForPayment());
  }, []);

  useEffect(() => {
    dispatch(getCoinRate(selectedCoin));
  }, [selectedCoin]);

  const selectedCoinData = useMemo(() => {
    return coins.find(({ code }) => code === selectedCoin);
  }, [coins, selectedCoin]);

  const { coinRate, minBitgemsToBuy, maxBitgemsToBuy } = useMemo(() => {
    return {
      coinRate: blockchainCoinRate.buy.bitgems_rate,
      minBitgemsToBuy: blockchainCoinRate.buy.bitgems_min_amount,
      maxBitgemsToBuy: Math.min(
        blockchainCoinRate.buy.bitgems_max_amount,
        balance_bitgems
      )
    };
  }, [blockchainCoinRate, balance_bitgems]);

  useEffect(() => {
    if (coinRate) {
      const newYouGet = youGet === 0 ? minBitgemsToBuy : youGet;
      dispatch(
        setPaymentModalMeta({
          youGet: newYouGet,
          youPay: formatPrice(newYouGet * coinRate)
        })
      );
    }
  }, [coinRate]);

  const isButtonDisabled = useMemo(() => {
    return (
      !youPay ||
      !youGet ||
      fetchingCoinRate ||
      submitting ||
      youGet % 1 !== 0 ||
      youGet < minBitgemsToBuy ||
      youGet > maxBitgemsToBuy
    );
  }, [
    youPay,
    youGet,
    fetchingCoinRate,
    submitting,
    minBitgemsToBuy,
    maxBitgemsToBuy
  ]);

  const isFixTheAmountDisabled = useMemo(() => {
    if (fetchingCoinRate) return true;
    return (
      youGet % 1 === 0 && youGet >= minBitgemsToBuy && youGet <= maxBitgemsToBuy
    );
  }, [youGet, minBitgemsToBuy, maxBitgemsToBuy, fetchingCoinRate]);

  const onClose = () => {
    dispatch(resetPaymentModalMeta());
  };

  const onYouPayChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: value,
        youGet: formatPrice(value / coinRate)
      })
    );
  };

  const onYouGetChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: formatPrice(value * coinRate),
        youGet: value
      })
    );
  };

  const onFixAmountClick = () => {
    if (youGet < minBitgemsToBuy) {
      onYouGetChange(minBitgemsToBuy);
      return;
    }

    if (youGet > maxBitgemsToBuy) {
      onYouGetChange(maxBitgemsToBuy);
      return;
    }

    if (youGet % 1 === 0) return;

    const amount = youGet - (youGet % 1);
    onYouGetChange(amount || 1);
  };

  const onCoinChange = (code) => {
    dispatch(setPaymentModalMeta({ selectedCoin: code }));
  };

  const onBuyClick = () => {
    setSubmitting(true);
    dispatch(
      postCreateBitgemsInvoice({
        amount: youGet,
        code: selectedCoin
      })
    ).then((res) => {
      if (res.payload?.data?.url) {
        window.location.href = res.payload.data.url;
      } else {
        setSubmitting(false);
      }
    });
  };

  const renderError = (errorText) => (
    <div className={styles.paymentModal__inputError}>
      <ErrorIcon />
      <span>{errorText}</span>
    </div>
  );

  return (
    <DialogMUI
      open
      onClose={onClose}
      paperClassName={styles.paymentModal__dialog}
    >
      <div className={styles.paymentModal__wrapper}>
        <h2 className={styles.paymentModal__title}>Buy Bitgems</h2>

        <div className={styles.upperBlock}>
          <div className={styles.upperBlock__header}>
            <div className={styles.upperBlock__title}>
              You pay
              {selectedCoinData?.name && (
                <div className={styles.upperBlock__coinName}>
                  {selectedCoinData.name}
                </div>
              )}
            </div>
          </div>

          <div className={styles.upperBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.upperBlock__input}
              value={youPay}
              disabled={fetchingCoinRate}
              onChange={(e) => onYouPayChange(e.target.value)}
            />

            <CoinsSelect
              coins={coins}
              selectedCoin={selectedCoin}
              onChange={onCoinChange}
            />
          </div>
        </div>

        <div className={styles.paymentModal__rateSeparator}>
          <div className={styles.paymentModal__rate}>
            <ExchangeIcon />1 BitGem ={' '}
            {renderLoadingPrice(coinRate, fetchingCoinRate, 15)} {selectedCoin}
          </div>
        </div>

        <div className={styles.lowerBlock}>
          <div className={styles.lowerBlock__header}>
            <div className={styles.lowerBlock__title}>You get</div>
            <button
              className={styles.lowerBlock__fixAmount}
              role='button'
              onClick={onFixAmountClick}
              disabled={isFixTheAmountDisabled}
            >
              Fix the amount
            </button>
          </div>

          <div className={styles.lowerBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.lowerBlock__input}
              value={youGet}
              disabled={fetchingCoinRate}
              onChange={(e) => onYouGetChange(e.target.value)}
            />

            <div className={styles.lowerBlock__bitgems}>
              <BitgemIcon />
              <span>BitGems</span>
            </div>
          </div>

          {youGet % 1 !== 0 &&
            renderError(
              'Note that BitGems can only be purchased in whole numbers. Fractional purchases are not supported.'
            )}

          {youGet < minBitgemsToBuy &&
            renderError(
              `The minimum amount of BitGems you can buy is ${formatPrice(
                minBitgemsToBuy
              )}.`
            )}

          {youGet > maxBitgemsToBuy &&
            renderError(
              `The maximum amount of BitGems you can buy is ${maxBitgemsToBuy}.`
            )}
        </div>

        <ExtraCHTForBitgems />

        <PaymentDiscretion />

        <ButtonMUI
          disabled={isButtonDisabled}
          loading={fetchingCoinRate || submitting}
          onClick={onBuyClick}
          loaderType='three-dots'
        >
          Buy
        </ButtonMUI>
      </div>
    </DialogMUI>
  );
};

export default BuyBitgemsModal;
