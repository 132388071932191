import { GAMES_TABLE } from './gamesTableActionTypes';

export function getGamesTable({ queryString }) {
  return {
    type: GAMES_TABLE.GET_GAMES_TABLE,
    payload: {
      client: 'default',
      request: {
        url: `user/games-history/${!!queryString && queryString.length > 0 ? queryString : ''}`,
        method: 'get'
      }
    }
  };
}

export function setGameTableMeta(meta) {
  return {
    type: GAMES_TABLE.SET_GAME_TABLE_META,
    meta
  };
}
