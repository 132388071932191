import React, { useState } from 'react'
import styles from './LandingJoinGame.module.scss'
import image from './images/join_game.jpg'
import imageMob from './images/join_game_mob.jpg'
import { Link } from 'react-router-dom';
import { rootMainPath } from '../../../../routes/paths';
import MouseTooltip from 'react-sticky-mouse-tooltip';

const LandingJoinGame = () => {

    const [isMouseTooltipVisible, setIsMouseTooltipVisible] = useState(false)

    const handleMouseEnter = () => {
        setIsMouseTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsMouseTooltipVisible(false);
    };

    return (
        <div className={styles.root} onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave}>
            <Link to={rootMainPath} className={styles.link}>
                <img  className={styles.mobile__img} src={imageMob} alt="Image" />
            </Link>

            <MouseTooltip
                visible={isMouseTooltipVisible}
                offsetX={15}
                offsetY={10}>
                <div
                    className={styles.join}>
                    Join the game
                </div>
            </MouseTooltip>
        </div>
    )
}

export default LandingJoinGame