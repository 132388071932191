import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { ThreeDots } from 'react-loader-spinner';

export const isDataArray = (array, minLength = 1) =>
  Array.isArray(array) && array.length >= minLength;

export const isDataObject = (object) =>
  typeof object === 'object' &&
  !Array.isArray(object) &&
  Object.keys(object).length > 0;

export const joinQueries = (arr) =>
  `${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`;

export function getOption(label) {
  return (
    <div className={`status ${label}`}>
      <div>
        {label !== 'All networks' && <span />}
        {label}
      </div>
    </div>
  );
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Scroll Restoration
export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// Convert string to slug
export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  let to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
}

export const generateId = () => Math.floor(Math.random() * Date.now());

export const randomInteger = (max) => Math.floor(Math.random() * (max + 1));

export const randomRgbColor = () => {
  let r = randomInteger(255);
  let g = randomInteger(255);
  let b = randomInteger(255);
  return `rgb(${r}, ${g}, ${b})`;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const dateForQuery = (date) =>
  encodeURIComponent(date?.toISOString().replace('.000Z', ''));

export const formatNumber = (value) =>
  Number(value)
    .toLocaleString('fr', {
      maximumFractionDigits: 2
    })
    .replace(',', '.');

export const isNegative = (num) => Math.sign(num) === -1;

export const capitalize = (string) =>
  typeof string === 'string'
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : '';

export const objectToQueryString = (queryParams) => {
  return queryParams
    ? Object.entries(queryParams).reduce((queryString, [key, value]) => {
        const symbol = queryString.length === 0 ? '?' : '&';
        queryString +=
          (value || value === 0) &&
          (typeof value === 'string' || typeof value === 'number')
            ? `${symbol}${key}=${encodeURIComponent(value)}`
            : '';
        return queryString;
      }, '')
    : '';
};

export const scrollToElement = (ref) =>
  ref && ref.current.scrollIntoView({ behavior: 'smooth' });

export const classList = (...classes) => {
  let result = '';
  const addClass = (element) => (result += ` ${element}`);

  classes.forEach((element) => {
    if (!element) return;
    if (typeof element === 'string' || typeof element === 'number')
      addClass(element);
    if (isDataArray(element)) addClass(classList(...element));
    if (isDataObject(element))
      Object.keys(element).forEach((key) => element[key] && addClass(key));
  });
  return result.trim();
};

export const formatDate = (date, template = 'DD.MM.YYYY') =>
  date ? dayjs(date).format(template) : '-';

export const calculatePercent = (value, total, digits = 2) =>
  Number(((value / total) * 100).toFixed(digits));

export const getRandomItem = (array) =>
  isDataArray(array) && array[Math.floor(Math.random() * array.length)];

export const removeDuplicates = (array) =>
  isDataArray(array) && [...new Set(array)];

export const sortBy = (array, key) =>
  isDataArray(array) &&
  array.sort((a, b) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0));

export const isEqual = (first, second) =>
  !!(first && second) && JSON.stringify(first) === JSON.stringify(second);

export const countOccurrences = (array, value) =>
  isDataArray(array) &&
  array.reduce(
    (accumulator, currentValue) =>
      currentValue === value ? accumulator + 1 : accumulator,
    0
  );

export const wait = async (milliseconds = 500) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const pluck = (array, key) =>
  isDataArray(array) &&
  array.filter((object) => object[key]).map((object) => object[key]);

export const insert = (array, index, newItem) =>
  isDataArray(array) &&
  typeof index === 'number' && [
    ...array.slice(0, index),
    newItem,
    ...array.slice(index)
  ];

// New helpers
export function isInteger(number) {
  if (typeof number === 'string') number = Number(number);
  return Number.isInteger(number);
}

export const isNullOrUndefined = (value) => value == null;

const cardTypes = [
  { name: 'visa', pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },
  { name: 'mastercard', pattern: /^5[1-5][0-9]{14}$/ },
  { name: 'amex', pattern: /^3[47][0-9]{13}$/ },
  { name: 'discover', pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/ },
  { name: 'dinersClub', pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/ },
  { name: 'jcb', pattern: /^(?:2131|1800|35\d{3})\d{11}$/ }
];

export function isCreditCard(cardNumber) {
  return cardTypes.some((cardType) => cardType.pattern.test(cardNumber));
}

export function getCreditCardType(cardNumber) {
  const matchedCard = cardTypes.find((cardType) =>
    cardType.pattern.test(cardNumber)
  );
  return matchedCard ? matchedCard.name : null;
}

export function isEmail(email) {
  const regex =
    /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return regex.test(email);
}

export const endsWith = (string, suffix) => string.endsWith(suffix);

export const startsWith = (string, prefix) => string.startsWith(prefix);

export const isURL = (url) => {
  const regex =
    /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+/;
  return regex.test(url);
};

export const isHexColor = (hex) => {
  const regex = /#?([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/;
  return regex.test(hex);
};

export const isPostalCode = (postalCode, countryCode) => {
  if (countryCode === 'US') {
    const regex = /[0-9]{5}(?:-[0-9]{4})?/;
    return regex.test(postalCode);
  } else if (countryCode === 'CA') {
    const regex =
      /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] [0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/;
    return regex.test(postalCode.toUpperCase());
  } else {
    // Add regex for other country codes as needed
    return false;
  }
};

export const isDOMElement = (value) => value instanceof Element;

export const isCSSLength = (value) =>
  /([-+]?[\d.]+)(%|[a-z]{1,2})/.test(String(value));

export const isDateString = (value) => !isNaN(Date.parse(value));

export const isEthereumAddress = (address) => {
  const regex = /0x[a-fA-F0-9]{40}/;
  return regex.test(address);
};

export const isBitcoinAddress = (address) => {
  const regex = /[13][a-km-zA-HJ-NP-Z0-9]{25,34}/;
  return regex.test(address);
};

export const isRippleAddress = (address) => {
  const regex = /r[0-9a-zA-Z]{33}/;
  return regex.test(address);
};

export const isRGBColor = (rgb) => {
  const regex =
    /rgb\(\s*([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\s*\)/;
  return regex.test(rgb);
};

export const toArray = (arrayLike) => Array.prototype.slice.call(arrayLike);

export const debounce = (fn, time) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(this, args), time);
  };
};

export const openTab = (url) => {
  window.open(url, '_blank');
};

export const dateDiff = (firstDate, secondDate) =>
  Math.abs(new Date(firstDate) - new Date(secondDate));

export const convertToDays = (milliseconds) =>
  milliseconds / 1000 / 60 / 60 / 24;

export const randomString = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const prettyScroll = (target, exception) => {
  if (!!target) {
    let timer = null;
    target.classList.add('pretty-scroll');
    if (!!exception && target.offsetHeight >= target.scrollHeight) {
      target.classList.remove('pretty-scroll');
      // console.log(target.offsetHeight > target.scrollHeight)
    }

    target.addEventListener('scroll', () => {
      target.classList.add('scroll');
      clearTimeout(timer);
      timer = setTimeout(() => {
        target.classList.remove('scroll');
      }, 1000);
    });
  }
};

export const createPosition = (id) => {
  switch (id) {
    case 0:
      return {
        width: `5%`,
        transform: `translate(-200%, -300%)`,
        animationDuration: '5s'
      };
    case 1:
      return {
        width: `4%`,
        transform: `translate(-400%, -200%)`,
        animationDuration: '6s'
      };
    case 2:
      return {
        width: `7%`,
        transform: `translate(-250%, 0)`,
        animationDuration: '7s'
      };
    case 3:
      return {
        width: `9%`,
        transform: `translate(-90%, 50%)`,
        animationDuration: '6s'
      };
    case 4:
      return {
        width: `10%`,
        transform: `translate(30%, 50%)`,
        animationDuration: '5s'
      };
    case 5:
      return {
        width: `4%`,
        transform: `translate(-330%, 220%)`,
        animationDuration: '8s'
      };
    case 6:
      return {
        width: `7%`,
        transform: `translate(20%, -40%)`,
        animationDuration: '10s'
      };
    case 7:
      return {
        width: `10%`,
        transform: `translate(-100%, -70%)`,
        animationDuration: '9s'
      };
    case 8:
      return {
        width: `5%`,
        transform: `translate(0, -200%)`,
        animationDuration: '7s'
      };
    case 9:
      return {
        width: `5%`,
        transform: `translate(150%, -150%)`,
        animationDuration: '6s'
      };
    default:
      break;
  }
};

export const parseWalletAddress = (address) => {
  if (address) {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  }
  return '';
};

export const formatPrice = (amount) => {
  if (!amount) return 0;
  if (amount < 0.000001) return amount.toFixed(8);
  return parseFloat(amount.toFixed(8));
};

export const renderLoadingPrice = (amount, isLoading, loaderSize) => {
  if (isLoading)
    return (
      <ThreeDots
        color='#111627a6'
        height={loaderSize}
        width={loaderSize}
        wrapperClass='loading-price'
      />
    );
  return formatPrice(amount);
};

export const renderLoadingText = (text, isLoading, loaderSize) => {
  if (isLoading)
    return (
      <ThreeDots
        color='#111627a6'
        height={loaderSize}
        width={loaderSize}
        wrapperClass='loading-price'
      />
    );
  return text;
};

export const sendEvent = (event, data) => {
  if (
    !window.dataLayer ||
    window.location.hostname !== 'cryptochests.com'
  ) {
    return;
  }
  window.dataLayer.push({
    event,
    ...data
  });
};
