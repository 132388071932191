import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { usePaymentModals } from '../../../PaymentModals';
import { renderLoadingPrice, formatPrice } from '../../../../helpers/functions';
import { ButtonMUI } from '../../../../shared';
import { ReactComponent as UpIcon } from '../../../../assets/icons/up.svg';
import { ReactComponent as DownIcon } from '../../../../assets/icons/down.svg';
import { ReactComponent as VCHTIcon } from '../../../../assets/icons/vcht.svg';

import styles from './Components.module.scss';

const VCHTBalance = () => {
  const {
    bitgem_packages: { cht_balance },
    fetchingRate,
    selectedCoin,
    coinRate
  } = useSelector(({ market }) => market);
  const { handleBuyVCHTModal, handleSellVCHTModal } = usePaymentModals();

  const onSellClick = () => {
    handleSellVCHTModal({
      coin: selectedCoin,
      amount: 0
    });
  };

  const onBuyClick = () => {
    handleBuyVCHTModal({
      coin: selectedCoin,
      amount: 0
    });
  };

  return (
    <div className={clsx(['card-wrap', styles.vchtBalance])}>
      <ButtonMUI
        disabled={fetchingRate || cht_balance < coinRate.sell?.cht_min_amount}
        className={styles.vchtBalance__btn}
        onClick={onSellClick}
        loading={fetchingRate}
        loaderType='three-dots'
      >
        <UpIcon />
        Sell • {formatPrice(coinRate.sell?.cht_rate)} {selectedCoin}
      </ButtonMUI>

      <div className={styles.vchtBalance__balance}>
        <p>Balance</p>
        <div className={styles.vchtBalance__amount}>
          <VCHTIcon />
          <strong>{formatPrice(cht_balance)}</strong>
        </div>
        <p>
          You need at least&nbsp;
          {renderLoadingPrice(coinRate.sell?.cht_min_amount, fetchingRate, 13)}
          &nbsp;to sell
        </p>
      </div>

      <ButtonMUI
        disabled={fetchingRate}
        className={styles.vchtBalance__btn}
        onClick={onBuyClick}
        loading={fetchingRate}
        loaderType='three-dots'
      >
        <DownIcon />
        Buy • {formatPrice(coinRate.buy?.cht_rate)} {selectedCoin}
      </ButtonMUI>
    </div>
  );
};

export default VCHTBalance;
