import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _isUndefined from 'lodash/isUndefined';
import clsx from 'clsx';

import { getWalletsInfo } from './walletsActions';
import {
  classList,
  parseWalletAddress,
  renderLoadingPrice,
  renderLoadingText
} from '../../helpers/functions';
import { ButtonMUI } from '../../shared';
import TransactionsTable from '../TransactionsTable';
import { usePaymentModals } from '../PaymentModals';
import { notifyError } from '../../helpers/notifySnack';
import { useQueryParams } from '../../helpers/hooks';
import { mainPath } from '../../routes/paths';
import { ReactComponent as CHTIcon } from '../../assets/icons/cht.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as ConnectIcon } from '../../assets/icons/connect.svg';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange.svg';
import { ReactComponent as MoreInfoIcon } from '../../assets/icons/more-info.svg';

import styles from './Wallets.module.scss';

const Wallets = () => {
  const { walletsInfo: info, boolToRefetchCurrentPage } = useSelector(
    ({ wallets, app }) => ({
      ...wallets,
      ...app
    })
  );
  const dispatch = useDispatch();
  const {
    handleBuyBitgemsModal,
    handlePaymentSuccessModal,
    handleConvertBitgemsToVCHTModal,
    handleConvertCHTModal,
    handleConvertVCHTModal,
    handleSellBitgemsModal,
    handleCHTmoreInfoModal
  } = usePaymentModals();
  const history = useHistory();

  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams.get('status') === 'success') {
      handlePaymentSuccessModal(queryParams.get('id'));
    }

    if (queryParams.get('status') === 'error') {
      notifyError("Your payment wasn't successful. Please try again.");
    }

    history.push(mainPath.wallets.path);
  }, []);

  useEffect(() => {
    dispatch(getWalletsInfo());
  }, [boolToRefetchCurrentPage]);

  const onBuyClick = () => {
    handleBuyBitgemsModal({
      coin: 'USDTERC20',
      amount: 0
    });
  };

  const onConvertToVCHTClick = () => {
    handleConvertBitgemsToVCHTModal();
  };

  const onSellClick = () => {
    handleSellBitgemsModal();
  };

  const onExchangeClick = () => {
    handleConvertCHTModal();
  };

  const onMoreInfoClick = () => {
    handleCHTmoreInfoModal();
  };

  return (
    <main className={clsx([styles.root, 'page-wrap'])}>
      <div className={clsx([styles.container, 'container'])}>
        <section className={clsx(['card-wrap', styles.bitgem])}>
          <div className={styles.bitgem__header}>
            <div className={styles.bitgem__header__block}>
              <div className={styles.bitgem__header__icon}>
                <BitgemIcon />
              </div>

              <div>
                <div className={styles.bitgem__header__coinName}>BitGem</div>
                <div className={styles.bitgem__header__coinDescription}>
                  Inner currency
                </div>
              </div>
            </div>

            <div className={styles.bitgem__header__profit}>
              <div className={styles.bitgem__header__profitLabel}>
                All time win
              </div>

              <div className={styles.bitgem__header__profitValue}>
                {renderLoadingPrice(
                  info.bitgems_all_time_profit_usdt,
                  _isUndefined(info.bitgems_all_time_profit_usdt),
                  14
                )}
                &nbsp;USDT
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.bitgem__body}>
            <div>
              <div className={styles.tokens__balance}>
                {renderLoadingPrice(
                  info.bitgems_balance,
                  _isUndefined(info.bitgems_balance),
                  28
                )}
              </div>

              <div className={styles.tokens__balanceInUSDT}>
                {!_isUndefined(info.bitgems_usdt_amount) && '≈'}
                {renderLoadingPrice(
                  info.bitgems_usdt_amount,
                  _isUndefined(info.bitgems_usdt_amount),
                  14
                )}
                &nbsp;USDT
              </div>
            </div>

            <div className={styles.bitgem__body__actions}>
              <ButtonMUI
                onClick={onBuyClick}
                className={styles.bitgem__body__btn}
              >
                Buy
              </ButtonMUI>

              <ButtonMUI
                color='secondary'
                className={styles.bitgem__body__btn}
                onClick={onConvertToVCHTClick}
              >
                Convert to <VCHTIcon />
              </ButtonMUI>

              <ButtonMUI
                color='secondary'
                className={styles.bitgem__body__btn}
                onClick={onSellClick}
              >
                Sell
              </ButtonMUI>
            </div>
          </div>
        </section>

        <section className={clsx(['card-wrap', styles.tokens])}>
          <div className={styles.cht__header}>
            <div className={styles.cht__block}>
              <div className={styles.cht__icon}>
                <CHTIcon />
              </div>
              <div>
                <div className={styles.cht__coinName}>Chest Token</div>
                <div className={styles.cht__coinCode}>CHT</div>
              </div>
            </div>
            <div className={styles.cht__block}>
              <ConnectIcon />
              <div>
                <div className={styles.cht__walletLabel}>Connected wallet</div>
                <div className={styles.cht__walletAddress}>
                  {renderLoadingText(
                    parseWalletAddress(info.cht_blockchain_address),
                    _isUndefined(info.cht_blockchain_address),
                    13
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.vcht__header}>
            <div className={styles.cht__block}>
              <div className={styles.vcht__icon}>
                <VCHTIcon />
              </div>
              <div>
                <div className={styles.cht__coinName}>Chest Token</div>
                <div className={styles.cht__coinCode}>vCHT</div>
              </div>
            </div>

            <div className={styles.vcht__moreInfo} onClick={onMoreInfoClick}>
              <MoreInfoIcon />
              <span>More info</span>
            </div>
          </div>

          <div className={styles.cht__body}>
            <div className={styles.tokens__balance}>
              {renderLoadingPrice(
                info.cht_blockchain_balance,
                _isUndefined(info.cht_blockchain_balance),
                28
              )}
            </div>

            <div className={styles.tokens__balanceInUSDT}>
              {!_isUndefined(info.cht_usdt_amount) && '≈'}
              {renderLoadingPrice(
                info.cht_blockchain_usdt,
                _isUndefined(info.cht_blockchain_usdt),
                14
              )}
              &nbsp;USDT
            </div>
          </div>

          <div className={styles.vcht__body}>
            <div className={styles.tokens__balance}>
              {renderLoadingPrice(
                info.cht_balance,
                _isUndefined(info.cht_balance),
                28
              )}
            </div>

            <div className={styles.tokens__balanceInUSDT}>
              Min&nbsp;
              {renderLoadingPrice(
                info.cht_min_amount_to_sell,
                _isUndefined(info.cht_min_amount_to_sell),
                14
              )}
              &nbsp;to sell
            </div>

            <ButtonMUI
              color='secondary'
              className={classList(styles.bitgem__body__btn, styles.tokens__convertBtn)}
              onClick={handleConvertVCHTModal}
            >
              Convert to <BitgemIcon />
            </ButtonMUI>

            <div className={styles.tokens__exchange} onClick={onExchangeClick}>
              <ExchangeIcon />
            </div>
          </div>
        </section>

        <section className='card-wrap'>
          <TransactionsTable
            customTitle={
              <div>
                <div className={styles.transactionsTable__title}>
                  Transactions
                </div>
                <div className={styles.transactionsTable__subtitle}>
                  Follow the status of your transactions here.
                </div>
              </div>
            }
          />
        </section>
      </div>
    </main>
  );
};

export default Wallets;
