export const APP = {
  LOADING: 'LOADING',
  BUTTON_LOADING: 'BUTTON_LOADING',

  ERROR_SNACK_OPEN: 'ERROR_SNACK_OPEN',
  ERROR_SNACK_CLOSE: 'ERROR_SNACK_CLOSE',

  SUCCESS_SNACK_OPEN: 'SUCCESS_SNACK_OPEN',
  SUCCESS_SNACK_CLOSE: 'SUCCESS_SNACK_CLOSE',

  SET_VOLUME: 'SET_VOLUME',
  SET_MUSIC: 'SET_MUSIC',
  CLICK_SOUND: 'CLICK_SOUND',
  PRE_OPEN_CHEST_SOUND: 'PRE_OPEN_CHEST_SOUND',
  SET_CUSTOM_SOUND: 'SET_CUSTOM_SOUND',

  TOGGLE_CURRENT_PAGE_REFETCH: 'TOGGLE_CURRENT_PAGE_REFETCH',
};
