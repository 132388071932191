import styles from './TimeCounter.module.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
dayjs.extend(utc)

const TimeCounter = ({ start }) => {
  const countDownDate = new Date(dayjs(start).format().split('+')[0]).getTime();
  let dateNow = new Date(dayjs().utc().format().replace('Z', '')).getTime();
  const [countDown, setCountDown] = useState(dateNow - countDownDate)

  useEffect(() => {
    const interval = setInterval(() => {
      dateNow = new Date(dayjs().utc().format().replace('Z', '')).getTime()
      setCountDown(dateNow - countDownDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return <div className={styles.counter}>{getReturnValues(countDown)}</div>
};

const getReturnValues = (countDown) => {
  // calculate time left
  let days = Math.floor(countDown / (1000 * 60 * 60 * 24)) + 'd ';
  let hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ) + ':';
  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)) + ':';
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  if (days === '0d ') days = ''
  if (hours === '0:') hours = ''
  if (minutes.length <= 2) minutes = '0' + minutes
  if (seconds.toString().length <= 1) seconds = '0' + seconds

  return [days, hours, minutes, seconds];
};

export default TimeCounter;
