import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPaymentModalMeta } from './paymentModalsActions';

import BuyBitgemsModal from './BuyBitgemsModal';
import SellBitgemsModal from './SellBitgemsModal';
import BuyVCHTModal from './BuyVCHTModal';
import SellVCHTModal from './SellVCHTModal';
import ConvertBitgemsToVCHTModal from './ConvertBitgemsToVCHTModal';
import ConvertCHTModal from './ConvertCHTModal';
import PaymentSuccessModal from './PaymentSuccessModal';
import CHTmoreInfoModal from './CHTmoreInfoModal';

const usePaymentModals = () => {
  const dispatch = useDispatch();
  return {
    handleBuyBitgemsModal: ({ coin, amount }) => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'buyBitgems',
          selectedCoin: coin,
          youGet: amount
        })
      );
    },
    handlePaymentSuccessModal: (receiptId = undefined) => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'paymentSuccess',
          receiptId
        })
      );
    },
    handleConvertBitgemsToVCHTModal: () => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'convertBitgemsToVCHT'
        })
      );
    },
    handleConvertCHTModal: () => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'convertCHT',
          selectedCoin: 'CHT'
        })
      );
    },
    handleConvertVCHTModal: () => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'convertCHT',
          selectedCoin: 'vCHT',
          initialConvertTo: 'BitGems'
        })
      );
    },
    handleSellBitgemsModal: () => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'sellBitgems',
          selectedCoin: 'USDTERC20'
        })
      );
    },
    handleCHTmoreInfoModal: () => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'CHTmoreInfo'
        })
      );
    },
    handleBuyVCHTModal: ({ coin, amount }) => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'buyVCHT',
          selectedCoin: coin,
          youGet: amount
        })
      );
    },
    handleSellVCHTModal: ({ coin, amount }) => {
      dispatch(
        setPaymentModalMeta({
          activeModal: 'sellVCHT',
          selectedCoin: coin,
          youGet: amount
        })
      );
    }
  };
};

const PaymentModals = () => {
  const { meta } = useSelector(({ paymentModals }) => paymentModals);

  switch (meta.activeModal) {
    case 'buyBitgems':
      return <BuyBitgemsModal />;
    case 'paymentSuccess':
      return <PaymentSuccessModal />;
    case 'convertBitgemsToVCHT':
      return <ConvertBitgemsToVCHTModal />;
    case 'convertCHT':
      return <ConvertCHTModal />;
    case 'sellBitgems':
      return <SellBitgemsModal />;
    case 'CHTmoreInfo':
      return <CHTmoreInfoModal />;
    case 'buyVCHT':
      return <BuyVCHTModal />;
    case 'sellVCHT':
      return <SellVCHTModal />;
    default:
      return null;
  }
};

export default PaymentModals;
export { usePaymentModals };
