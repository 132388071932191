export const AUTH = {
  CHECK_EXISTS: 'CHECK_EXISTS',
  CHECK_EXISTS_SUCCESS: 'CHECK_EXISTS_SUCCESS',
  CHECK_EXISTS_FAIL: 'CHECK_EXISTS_FAIL',

  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',

  CHECK_USERNAME: 'CHECK_USERNAME',
  CHECK_USERNAME_SUCCESS: 'CHECK_USERNAME_SUCCESS',
  CHECK_USERNAME_FAIL: 'CHECK_USERNAME_FAIL',

  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',

  SET_AUTH_DATA: 'SET_AUTH_DATA',
  SET_AUTH_DATA_SUCCESS: 'SET_AUTH_DATA_SUCCESS',
  SET_AUTH_DATA_FAIL: 'SET_AUTH_DATA_FAIL',

  GET_PROFILE_INFO: 'GET_PROFILE_INFO',
  GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',
  GET_PROFILE_INFO_FAIL: 'GET_PROFILE_INFO_FAIL',

  SET_TEMP_PROFILE_PERMANENT: 'SET_TEMP_PROFILE_PERMANENT',
};
