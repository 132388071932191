import React from 'react';
import {DialogMUI, ButtonMUI} from '../../../shared';
import mapImage from '../../../assets/images/map.png';
import bnbSmartChainImage from '../../../assets/images/bnb-smart-chain.png';

export default function ChangeRequestDialog({open, onClose, onReturn, onRetry, styles}) {
  const {network, networkText} = styles;

  return (
    <DialogMUI open={open} onClose={onClose} onReturn={onReturn}>
      <img className='mb-24 mx-auto' src={mapImage} alt='map' width='94px' height='94px' />
      <h1 className='h1 mb-16 t-center'>Oops...</h1>
      <p className='mb-32 mx-auto max-w-288 t-center'>
        You are currently connected to <br />
        non-supported network, whereas Crypto Chests dApp runs on BNB Smart Chain. <br />
        Please switch network.
      </p>
      <div className={network}>
        <img src={bnbSmartChainImage} alt='BNB Smart Chain' width='40px' height='40px' />
        <div className={networkText}>
          <h3>BNB Smart Chain</h3>
          <span>Requested a network change</span>
        </div>
        <ButtonMUI color='secondary' size='small' onClick={onRetry}>
          Try again
        </ButtonMUI>
      </div>
    </DialogMUI>
  );
}
