import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './Arena.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomInner, leaveRoom, setRoomResults, setRoomStatus } from './storage/arenaActions';
import { classList, createPosition } from '../../helpers/functions';
import { ReactComponent as FlagIcon } from './LowBalanceModalAssets/not enough flag.svg';
import { ButtonMUI, DialogMUI, TimeCounter, TooltipMUI } from '../../shared';
import { roomsBackground, roomsMusic } from './Arena';
import { mainPath } from '../../routes/paths';
import { setProfileInfo } from '../Auth/authActions';
import CountDown from './CountDown';
import RoomResults from './RoomResults';
import WinnerModal from './WinnerModal';
import _isEmpty from 'lodash/isEmpty';
import { setCustomSound } from '../../app/appActions';
import waitingSound from '../../assets/sound/Waiting Room Background.mp3'
import countdownSound from '../../assets/sound/Game Start Countdown.mp3'
import percussionSound from '../../assets/sound/Quest Epic Percussion.mp3'
import connectToRoomSound from '../../assets/sound/Short Trumpet Fanfare.mp3'
import backgroundAudio from '../../assets/sound/The Medieval Looped.mp3'

const WaitingRoom = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roomID } = useParams();
  const { roomInner, roomStatus, profileInfo } = useSelector(({ arena, auth }) => ({ ...arena, ...auth }))
  const [leaveModalStatus, setLeaveModalStatus] = useState(false)
  const [winnerModalStatus, setWinnerModalStatus] = useState(false);

  useEffect(() => {
    if (_isEmpty(roomInner) && !_isEmpty(profileInfo)) {
      dispatch(getRoomInner(roomID)).then(res => {
        if (res.type.includes('_SUCCESS') && !!res.payload.data.closed_at && profileInfo.hasOwnProperty('seen_result') && !profileInfo.seen_result && profileInfo.hasOwnProperty('current_room') && !profileInfo.current_room) {
          dispatch(setRoomResults(res.payload.data))
          setWinnerModalStatus(true)
        }
        else if (profileInfo.hasOwnProperty('current_room') && !profileInfo.current_room) history.push(mainPath.arena.path)
      })
    }
  }, [profileInfo])

  useEffect(() => {
    switch (roomStatus) {
      case 'waiting':
        dispatch(setCustomSound(waitingSound));
        dispatch(setCustomSound(connectToRoomSound, true));
        return
      case 'countDown':
        dispatch(setCustomSound(countdownSound))
        return
      case 'results':
        return dispatch(setCustomSound(roomsMusic[roomInner.room_name]))
      default:
        return null
    }
  }, [roomStatus])

  useEffect(() => {
    if (+roomInner?.participants_count === 10) dispatch(setCustomSound(percussionSound))
  }, [roomInner])

  const renderRoom = () => {
    switch (roomStatus) {
      case 'waiting':
        return <><div className={styles.arenaOverlay2} />
          <div className={styles.waitingRoomContainer}>
            <div className={styles.waitingRoomHeading}>
              <div className={styles.users}>
                <p>Search for rivals...</p>
                <p>{roomInner.participants_count}<span>/{roomInner.max_participants}</span></p>
              </div>
              <div className={styles.actions}>
                <TimeCounter start={roomInner.first_connect_at} />
                <ButtonMUI
                  color='error'
                  size='medium'
                  onClick={() => setLeaveModalStatus(true)}
                >
                  Leave
                </ButtonMUI>
              </div>
            </div>
            <div className={styles.waitingRoomParticipants}>
              {testUsers.map((el, idx) => ({ ...el, ...roomInner.participants[idx] })).map((el, idx) => <div className={styles.waitingRoomParticipantsItem} key={idx} style={el.positions}>
                {!!el.avatar && <TooltipMUI title={el.username} placement='right-end' className={styles.waitingRoomUserTooltip}><img src={el.avatar} alt={el.username} /></TooltipMUI>}
              </div>)}
            </div>
          </div>
          <LeaveModal
            status={leaveModalStatus}
            setStatus={setLeaveModalStatus}
          />
        </>
      case 'countDown':
        return <div className={styles.results}>
          <div className={styles.arenaOverlayTotal} />
          <CountDown
            onAnimateEnd={() => dispatch(setRoomStatus('results'))}
          />
        </div>
      case 'results':
        return <RoomResults />
      default:
        return null
    }
  }

  return (!!roomInner
    ? <main className={classList(styles.arena, styles.waitingRoom, `page-wrap`)} style={{ backgroundImage: `url("${roomsBackground[roomInner.room_name]}")` }}>
      {renderRoom()}
      <WinnerModal
        open={winnerModalStatus}
        onClose={() => setWinnerModalStatus(false)}
      />
    </main>
    : null)
};

const testUsers = [...Array(10).keys()].map(el => ({ positions: createPosition(el) }))

const LeaveModal = ({ status, setStatus }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const leave = () => {
    dispatch(leaveRoom()).then(res => {
      if (res.type.includes('_SUCCESS')) {
        setStatus(false)
        history.push(mainPath.arena.path)
      }
    })
  }

  return <DialogMUI
    open={status}
    onClose={() => setStatus(false)}
  >
    <div className={styles.leaveModal}>
      <h1>Are you sure you want to leave the game?</h1>
      <p>Do not worry, gallant player, even if you choose to leave the game, your coins will be returned to you.</p>
      <div className={styles.buttons}>
        <ButtonMUI
          variant={'text'}
          onClick={() => setStatus(false)}
        >
          Back
        </ButtonMUI>
        <ButtonMUI
          color='error'
          size='medium'
          onClick={leave}
        >
          Leave
        </ButtonMUI>
      </div>
    </div>
  </DialogMUI>
}

export const KickModal = ({ status, setStatus }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const kick = () => {
    dispatch(setProfileInfo({ current_room: null }))
    setStatus(false)
    history.push(mainPath.arena.path)
  }

  return <DialogMUI
    open={status}
    onClose={kick}
  >
    <div className={styles.leaveModal}>
      <FlagIcon />
      <h1>Not enough BitGems</h1>
      <p>Oh, you haven't enough funds to start, we have to exclude you from this game.</p>
      <div className={styles.buttons}>
        <ButtonMUI
          variant={'text'}
          onClick={kick}
        >
          Back
        </ButtonMUI>
      </div>
    </div>
  </DialogMUI>
}

export default WaitingRoom;
