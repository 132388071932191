import React from 'react';
import { Link } from 'react-router-dom';

import { walletAndExchangeTermsPath } from '../../../routes/paths';

import styles from './Components.module.scss';

const PaymentDiscretion = () => (
  <div className={styles.paymentDiscretion}>
    <strong>Important:</strong> By proceeding with your purchase, you
    acknowledge and confirm that you have read and fully agree to the{' '}
    <Link to={walletAndExchangeTermsPath}>Wallet and Exchange Terms</Link> of
    Crypto Chests. Displaying approximate amounts in your chosen currency; an additional processor fee will apply.
  </div>
);

export default PaymentDiscretion;
