import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import {
  getConvertOptions,
  setPaymentModalMeta,
  resetPaymentModalMeta,
  postConvertBitgemsToVCHT
} from './paymentModalsActions';
import { toggleCurrentPageRefetch } from '../../app/appActions';
import { ButtonMUI, DialogMUI } from '../../shared';
import PaymentDiscretion from './components/PaymentDiscretion';
import { formatPrice, renderLoadingPrice } from '../../helpers/functions';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange-rate.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';

import styles from './styles.module.scss';

const ConvertBitgemsToVCHTModal = () => {
  const {
    convertOptions,
    meta: { youPay, youGet }
  } = useSelector(({ paymentModals }) => paymentModals);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(null);

  const {
    fetchingConvertOptions,
    rate,
    minBitgemsToConvert,
    maxBitgemsToConvert
  } = useMemo(() => {
    return {
      fetchingConvertOptions: _isEmpty(convertOptions),
      rate: convertOptions.rates?.BG.VCHT.rate,
      minBitgemsToConvert: convertOptions.rates?.BG.VCHT.min_amount,
      maxBitgemsToConvert: convertOptions.rates?.BG.VCHT.max_amount
    };
  }, [convertOptions]);

  useEffect(() => {
    if (fetchingConvertOptions) dispatch(getConvertOptions());
  }, [fetchingConvertOptions]);

  useEffect(() => {
    if (minBitgemsToConvert && rate) {
      dispatch(
        setPaymentModalMeta({
          youPay: minBitgemsToConvert,
          youGet: formatPrice(minBitgemsToConvert * rate)
        })
      );
    }
  }, [rate, minBitgemsToConvert]);

  const isButtonDisabled = useMemo(() => {
    return (
      !youPay ||
      !youGet ||
      fetchingConvertOptions ||
      submitting ||
      youPay % 1 !== 0 ||
      youPay < minBitgemsToConvert ||
      youPay > maxBitgemsToConvert
    );
  }, [
    youPay,
    youGet,
    convertOptions,
    submitting,
    minBitgemsToConvert,
    maxBitgemsToConvert
  ]);

  const isFixTheAmountDisabled = useMemo(() => {
    if (fetchingConvertOptions) return true;
    return (
      youPay % 1 === 0 &&
      youPay >= minBitgemsToConvert &&
      youPay <= maxBitgemsToConvert
    );
  }, [youPay, minBitgemsToConvert, maxBitgemsToConvert, convertOptions]);

  const onClose = () => {
    dispatch(resetPaymentModalMeta());
  };

  const onYouPayChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: value,
        youGet: formatPrice(value * rate)
      })
    );
  };

  const onYouGetChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: formatPrice(value / rate),
        youGet: value
      })
    );
  };

  const onFixAmountClick = () => {
    if (youPay < minBitgemsToConvert) {
      onYouPayChange(minBitgemsToConvert);
      return;
    }

    if (youPay > maxBitgemsToConvert) {
      onYouPayChange(maxBitgemsToConvert);
      return;
    }

    if (youPay % 1 === 0) return;

    const amount = youPay - (youPay % 1);
    onYouPayChange(amount || 1);
  };

  const onConvertClick = () => {
    setSubmitting(true);
    dispatch(
      postConvertBitgemsToVCHT({
        amount: youPay
      })
    ).then((res) => {
      setSubmitting(false);
      if (res.type.includes('_SUCCESS')) {
        dispatch(toggleCurrentPageRefetch());
        onClose();
      }
    });
  };

  const renderError = (errorText) => (
    <div className={styles.paymentModal__inputError}>
      <ErrorIcon />
      <span>{errorText}</span>
    </div>
  );

  return (
    <DialogMUI
      open
      onClose={onClose}
      paperClassName={styles.paymentModal__dialog}
    >
      <div className={styles.paymentModal__wrapper}>
        <h2 className={styles.paymentModal__title}>Convert BitGems</h2>

        <div className={styles.upperBlock}>
          <div className={styles.upperBlock__header}>
            <div className={styles.upperBlock__title}>You pay</div>
            <button
              className={styles.upperBlock__fixAmount}
              role='button'
              onClick={onFixAmountClick}
              disabled={isFixTheAmountDisabled}
            >
              Fix the amount
            </button>
          </div>

          <div className={styles.upperBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.upperBlock__input}
              value={youPay}
              disabled={fetchingConvertOptions}
              onChange={(e) => onYouPayChange(e.target.value)}
            />

            <div className={styles.upperBlock__bitgems}>
              <BitgemIcon />
              <span>BitGems</span>
            </div>
          </div>

          {youPay % 1 !== 0 &&
            renderError(
              'Note that BitGems can only be selected in whole numbers. Fractional purchases are not supported.'
            )}

          {youPay < minBitgemsToConvert &&
            renderError(
              `The minimum amount of BitGems you can convert is ${formatPrice(
                minBitgemsToConvert
              )}.`
            )}

          {youPay > maxBitgemsToConvert &&
            renderError(
              `The maximum amount of BitGems you can convert is ${maxBitgemsToConvert}.`
            )}
        </div>

        <div className={styles.paymentModal__rateSeparator}>
          <div className={styles.paymentModal__rate}>
            <ExchangeIcon />1 BitGem ={' '}
            {renderLoadingPrice(rate, fetchingConvertOptions, 15)} vCHT
          </div>
        </div>

        <div className={styles.lowerBlock}>
          <div className={styles.lowerBlock__header}>
            <div className={styles.lowerBlock__title}>You get</div>
          </div>

          <div className={styles.lowerBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.lowerBlock__input}
              value={youGet}
              disabled={fetchingConvertOptions}
              onChange={(e) => onYouGetChange(e.target.value)}
            />

            <div className={styles.lowerBlock__CHT}>
              <VCHTIcon />
              <span>Chest token</span>
            </div>
          </div>
        </div>

        <PaymentDiscretion />

        <ButtonMUI
          disabled={isButtonDisabled}
          loading={fetchingConvertOptions || submitting}
          onClick={onConvertClick}
          loaderType='three-dots'
        >
          Convert
        </ButtonMUI>
      </div>
    </DialogMUI>
  );
};

export default ConvertBitgemsToVCHTModal;
