import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useDisconnect } from 'wagmi';
import { InputAdornment } from '@mui/material';
import { authPath, rootMainPath } from '../../routes/paths';
import { useDebounce } from '../../helpers/hooks';
import { InputMUI, ButtonMUI } from '../../shared';
import { postCheckUsername, postSignUp } from './authActions';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import styles from './Auth.module.scss';
import { sendEvent } from '../../helpers/functions';

const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, buttonLoading } = useSelector(({ app }) => app);
  const { crypto_address, challenge, signature } = useSelector(
    ({ auth }) => auth?.authData
  );
  const { isConnected } = useAccount();
  const { disconnect, isSuccess } = useDisconnect();

  /* useEffect(() => {
    (!crypto_address || !challenge || !signature) && disconnect();
  }, []); */

  useEffect(() => {
    isSuccess && history.push(authPath.signIn.path);
  }, [isSuccess]);

  const [username, setUsername] = useState('');
  const debouncedUsername = useDebounce(username);
  const handleInput = (setter) => (event) => setter(event.target.value);

  const [usernameExists, setUsernameExists] = useState(false);
  const checkUsernameExists = () => {
    if (username.length > 0) {
      dispatch(postCheckUsername(username)).then((res) => {
        const data = res?.payload?.data;
        const { is_exists } = data;
        if (data) setUsernameExists(is_exists);
      });
    }
  };

  useEffect(() => {
    checkUsernameExists();
  }, [debouncedUsername]);

  const createAccount = () => {
    sendEvent('click_event', {
      event_name: 'click_create_account'
    });

    if (isConnected) {
      const authData = { username, crypto_address, challenge, signature };
      dispatch(postSignUp(authData))
        .then((res) => {
          const { auth_token } = res?.payload?.data;
          if (auth_token) {
            sendEvent('click_event', {
              event_name: 'sign_up'
            });

            localStorage.setItem('token', auth_token);
            history.push(rootMainPath);
          }
        })
        .catch((error) => console.warn('Sign Up Error:', error));
    }
  };

  const onFocus = () => {
    sendEvent('click_event', {
      event_name: 'click_nickname'
    });
  };

  const onDisconnectClick = () => {
    sendEvent('click_event', {
      event_name: 'click_disconnect_wallet'
    });

    disconnect();
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Create account</h1>
      <p className={styles.desc}>
        Come up your unique name on the game. <br />
        It can be changed at any time.
      </p>

      <InputMUI
        className='my-24'
        label='Nickname'
        value={username}
        onChange={handleInput(setUsername)}
        onFocus={onFocus}
        error={usernameExists}
        helperText={usernameExists && 'Oh, this name is already taken'}
        loading={loading}
        fullWidth
        startAdornment={
          <InputAdornment position='start'>
            <UserIcon />
          </InputAdornment>
        }
      />

      <ButtonMUI
        className='mb-12'
        disabled={loading || buttonLoading || !username || usernameExists}
        loading={buttonLoading}
        fullWidth
        onClick={createAccount}
      >
        Create account
      </ButtonMUI>
      <ButtonMUI color='secondary' fullWidth onClick={onDisconnectClick}>
        Disconnect wallet
      </ButtonMUI>
    </div>
  );
};

export default SignUp;
