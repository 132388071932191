import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import clsx from 'clsx';

import { setSelectedCoin } from '../../marketActions';
import { clickSound } from '../../../../app/appActions';

import styles from './Components.module.scss';

const CoinsList = () => {
  const {
    bitgem_packages: { coins },
    selectedCoin
  } = useSelector(({ market }) => market);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(clickSound())
  };

  const closePopover = () => {
    setAnchorEl(null);
    dispatch(clickSound())
  };

  const onCoinClick = useCallback(
    (code) => {
      closePopover();
      dispatch(setSelectedCoin(code));
    },
    [dispatch]
  );

  const renderCoin = useCallback(
    (coin, inPopover = false) => (
      <div
        key={coin.id}
        className={clsx([
          styles.coinsList__coin,
          inPopover && styles.inPopover,
          selectedCoin === coin.code && styles.selected
        ])}
        onClick={() => onCoinClick(coin.code)}
      >
        <div className={styles.coinsList__coinIconWrapper}>
          <img
            src={coin.logo}
            alt={coin.name}
            className={styles.coinsList__coinIcon}
          />
        </div>

        {inPopover && (
          <div
            className={clsx([
              styles.coinsList__coinName,
              selectedCoin === coin.code && styles.selected
            ])}
          >
            {coin.name}
          </div>
        )}

        <div
          className={clsx([
            styles.coinsList__coinCode,
            inPopover && styles.withName,
            selectedCoin === coin.code && styles.selected
          ])}
        >
          {coin.code}
        </div>
      </div>
    ),
    [onCoinClick, selectedCoin]
  );

  const renderPopover = useCallback(
    (coinList) => {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{
            paper: `${styles.coinsList__popover} pretty-scroll`
          }}
        >
          {coinList.map((c) => renderCoin(c, true))}
        </Popover>
      );
    },
    [anchorEl, renderCoin]
  );

  return (
    <div className={styles.coinsList}>
      {coins.slice(0, 4).map((c) => renderCoin(c))}

      {coins.length > 4 && (
        <Button
          aria-describedby={id}
          className={styles.coinsList__otherCoinsBtn}
          onClick={openPopover}
        >
          +{coins.length - 4} other
        </Button>
      )}

      {renderPopover(coins.slice(4))}
    </div>
  );
};

export default CoinsList;
