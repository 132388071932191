import { WALLETS } from './walletsActionTypes';

export function getWalletsInfo() {
  return {
    type: WALLETS.GET_WALLETS_INFO,
    payload: {
      client: 'default',
      request: {
        url: `user/wallet/`,
        method: 'get'
      }
    }
  };
}
