import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ButtonMUI, InputMUI } from '../../../shared';
import { patchMyAccount } from '../myAccountActions';

import styles from './Components.module.scss';

const MyProfile = () => {
  const { accountInfo: acc } = useSelector(({ myAccount }) => myAccount);
  const { buttonLoading } = useSelector(({ app }) => app);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setError,
    watch
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        username: yup.string().required('Nickname is required')
      })
    ),
    defaultValues: {
      username: acc.username
    }
  });

  const onFormSubmit = async (data) => {
    const res = await dispatch(patchMyAccount(data));
    if (res?.error) {
      setError('username', {
        type: 'manual',
        message: res.error.response?.data?.username?.[0]
      });
    }
  };

  return (
    <div className={styles.myProfile}>
      <h4 className={styles.myProfile__title}>My profile</h4>
      <form onSubmit={handleFormSubmit(onFormSubmit)}>
        <Controller
          name='username'
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <InputMUI
              error={!!errors.username?.message}
              helperText={errors.username?.message}
              label='Custom nickname'
              fullWidth
              {...rest}
            />
          )}
        />

        <hr className={styles.myProfile__separator} />

        <ButtonMUI
          disabled={buttonLoading || watch('username') === acc.username}
          loading={buttonLoading}
          formAction
          className={styles.myProfile__submit}
        >
          Save
        </ButtonMUI>
      </form>
    </div>
  );
};

export default MyProfile;
