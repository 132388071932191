import { ARENA } from './arenaActionTypes';

const INITIAL_STATE = {
  rooms: [],
  selectedRoom: null,
  lowBalanceModalStatus: false,
  roomInner: null,
  roomStatus: 'waiting',
  roomResults: {
    "id": 41,
    "created_at": "2023-08-29T13:10:11.847124",
    "first_connect_at": "2023-08-29T13:12:45.464836",
    "closed_at": "2023-08-29T13:12:53.655434",
    "type": "bronze",
    "room_name": "Old castle",
    "stake": 10,
    "participants_count": 10,
    "max_participants": 10,
    "background_image": null,
    "participants": [
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647326.png",
        "username": "meowchich",
        "user_id": 18,
        "turn": 1,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": true
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647326.png",
        "username": "test user 3",
        "user_id": 5,
        "turn": 2,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647324.svg",
        "username": "test user 2",
        "user_id": 4,
        "turn": 3,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647327.png",
        "username": "test user 10",
        "user_id": 12,
        "turn": 4,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647323.svg",
        "username": "test user 1",
        "user_id": 3,
        "turn": 5,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647323-1.svg",
        "username": "test user 5",
        "user_id": 7,
        "turn": 6,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647323-1.svg",
        "username": "test user 9",
        "user_id": 11,
        "turn": 7,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647326.png",
        "username": "test user 6",
        "user_id": 8,
        "turn": 8,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647327.png",
        "username": "test user 4",
        "user_id": 6,
        "turn": 9,
        "is_bg_winner": true,
        "win_amount": 11,
        "win_currency": "BG",
        "win_currency_display": "BitGems",
        "is_me": false
      },
      {
        "avatar": "https://api.tenchest.4-com.pro/media/sample-avatars/Rectangle_240647324.svg",
        "username": "test user 7",
        "user_id": 9,
        "turn": 10,
        "is_bg_winner": false,
        "win_amount": 10,
        "win_currency": "CHT",
        "win_currency_display": "CHT",
        "is_me": false
      }
    ]
  },
  isCHT: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ARENA.GET_ARENA_ROOMS_SUCCESS:
      let tempRooms = state.rooms.length < 1
        ? action.payload.data
        : [...state.rooms.map(room => ({
          ...room,
          ...action.payload.data.find(el => el.type === room.type)
        }))]

      return {
        ...state,
        rooms: tempRooms,
        selectedRoom: state.selectedRoom === null ? tempRooms.filter(el => !el.type.includes('_cht'))[0] : tempRooms.find(el => el.type === state.selectedRoom.type),
        roomInner: !!state.roomInner
          ? tempRooms.some(el => el.room_id === state.roomInner.id) ? { ...state.roomInner, ...tempRooms.find(el => el.room_id === state.roomInner.id) } : { ...state.roomInner }
          : null
      };
    case ARENA.SELECT_ROOM:
      return { ...state, selectedRoom: action.payload.data };
    case ARENA.JOIN_ROOM_FAIL:
      return { ...state, lowBalanceModalStatus: action.error.response.data.code === 'low_balance' };
    case ARENA.SET_LOW_BALANCE_MODAL_STATUS:
      return { ...state, lowBalanceModalStatus: action.payload.data };
    case ARENA.JOIN_ROOM_SUCCESS:
    case ARENA.GET_ROOM_INNER_SUCCESS:
      return { ...state, roomInner: action.payload.data };
    case ARENA.LEAVE_ROOM_SUCCESS:
      return { ...state, roomInner: null };
    case ARENA.SET_ARENA_ROOMS_STATUS:
      return { ...state, roomStatus: action.payload.data };
    case ARENA.SET_ARENA_ROOMS_RESULTS:
      return { ...state, roomResults: action.payload.data };
    case ARENA.SET_IS_CHT:
      return { ...state, isCHT: action.payload.data };
    default:
      return state;
  }
}
