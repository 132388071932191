import React, { useEffect } from 'react'
import styles from './Landing.module.scss'
import LandingHeader from './components/LandingHeader/LandingHeader'
import LandingHero from './components/LandingHero/LandingHero'
import LandingVideo from './components/LandingVideo/LandingVideo'
import LandingGems from './components/LandingGems'
import LandingChestTokens from './components/LandingChestTokens/LandingChestTokens'
import LandingTokenomics from './components/LandingTokenomics'
import LandingJoinGame from './components/LandingJoinGame/LandingJoinGame'
import LandingFooter from './components/LandingFooter/LandingFooter'
import LandingSlider from './components/LandingSlider/LandingSlider'

const Landing = () => {
  useEffect(() => {
    document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
  
    return () => {
      document.querySelector("meta[name=viewport]").setAttribute('content', 'width=1366, user-scalable=yes')
    }
  }, [])
  
  
  return (
    <div className={styles.root}>
        <LandingHeader />
        <LandingHero />
        <LandingVideo />
        <LandingGems />
        <LandingChestTokens />
        <LandingTokenomics />
        <LandingSlider />
        <LandingJoinGame />
        <LandingFooter />
    </div>
  )
}

export default Landing