// You need to import the function into rootReducer.js
import { PAYMENT_MODALS } from './paymentModalsTypes';

const INITIAL_STATE = {
  meta: {
    /**
     * @type {string}
     * @description The type of modal to show
     * @example
     * 'buyBitgems' - Buy BitGems modal
     * 'sellBitgems' - Sell BitGems modal
     * 'buyVCHT' - Buy vCHT modal
     * 'sellVCHT' - Sell vCHT modal
     * 'paymentSuccess' - Payment success modal
     * 'convertBitgemsToVCHT' - Convert BitGems to vCHT modal
     * 'convertCHT' - Convert CHT/vCHT modal
     * 'CHTmoreInfo' - Understanding CHT and vCHT: Your Chest Tokens modal
     **/
    activeModal: undefined,

    /**
     * @type {number}
     * @description Amount for the "You Pay" input
     */
    youPay: 0,

    /**
     * @type {number}
     * @description Amount for the "You Get" input
     */
    youGet: 0,

    /**
     * @type {string}
     * @description Coin code, optional. For modals where you can select
     * a specific coin.
     */
    selectedCoin: undefined,

    /**
     * @type {Object}
     * @description Coin rate data
     * @property {Object} buy
     * @property {number} buy.coin_min_amount
     * @property {number} buy.cht_rate
     * @property {number} buy.bitgems_rate
     * @property {number} buy.cht_min_amount
     * @property {number} buy.cht_max_amount
     * @property {number} buy.bitgems_min_amount
     * @property {number} buy.bitgems_max_amount
     * @property {number} buy.usd_rate
     * @property {Object} sell
     * @property {number} sell.coin_min_amount
     * @property {number} sell.cht_rate
     * @property {number} sell.bitgems_rate
     * @property {number} sell.cht_min_amount
     * @property {number} sell.cht_max_amount
     * @property {number} sell.bitgems_min_amount
     * @property {number} sell.bitgems_max_amount
     * @property {number} sell.usd_rate
     */

    blockchainCoinRate: {
      buy: {
        coin_min_amount: 0,
        cht_rate: 0,
        bitgems_rate: 0,
        cht_min_amount: 0,
        cht_max_amount: 0,
        bitgems_min_amount: 0,
        bitgems_max_amount: 0,
        usd_rate: 0
      },
      sell: {
        coin_min_amount: 0,
        cht_rate: 0,
        bitgems_rate: 0,
        cht_min_amount: 0,
        cht_max_amount: 0,
        bitgems_min_amount: 0,
        bitgems_max_amount: 0,
        usd_rate: 0
      },

      customAddress: ''
    },

    /**
     * @type {string}
     * @description Payment receipt ID - for the Payment success modal
     */
    receiptId: undefined
  },

  fetchingCoinRate: false,
  fetchingSellEstimate: false,

  /**
   * Coins
   * @type {Array}
   * @property {number} id
   * @property {string} code
   * @property {string} name
   * @property {string} logo
   */
  coins: [],

  /**
   * Convert options
   * @type {Object}
   `* @property {`Object} rates
   * @property {Object} rates.BG
   * @property {Object} rates.BG.VCHT
   * @property {number} rates.BG.VCHT.rate
   * @property {number} rates.BG.VCHT.min_amount
   * @property {number} rates.BG.VCHT.max_amount
   * @property {boolean} rates.BG.VCHT.can_convert
   * @property {Object} rates.CHT
   * @property {Object} rates.CHT.VCHT
   * @property {number} rates.CHT.VCHT.rate
   * @property {number} rates.CHT.VCHT.min_amount
   * @property {number} rates.CHT.VCHT.max_amount
   * @property {boolean} rates.CHT.VCHT.can_convert
   * @property {string} rates.CHT.VCHT.address
   * @property {object} rates.CHT.VCHT.extra
   * @property {string} rates.CHT.VCHT.extra.cht_our_wallet_address
   * @property {string} rates.CHT.VCHT.extra.cht_contract_address
   * @property {Array} rates.CHT.VCHT.extra.cht_token_abi_json
   * @property {Object} rates.VCHT
   * @property {Object} rates.VCHT.CHT
   * @property {number} rates.VCHT.CHT.rate
   * @property {number} rates.VCHT.CHT.min_amount
   * @property {number} rates.VCHT.CHT.max_amount
   * @property {boolean} rates.VCHT.CHT.can_convert
   * @property {Object} rates.VCHT.BG
   * @property {number} rates.VCHT.BG.rate
   * @property {number} rates.VCHT.BG.min_amount
   * @property {number} rates.VCHT.BG.max_amount
   * @property {boolean} rates.VCHT.BG.can_convert
   * @property {Object} rates.VCHT.BG.address
   */
  convertOptions: {},

  /**
   * Payment receipt
   * @type {Object}
   * @property {string} order_id
   * @property {string} created_at
   * @property {string} updated_at
   * @property {string} status
   * @property {string} status_display
   * @property {number} amount
   * @property {string} coin_code
   * @property {string} coin_name
   * @property {number} coin_amount
   * @property {number} currency
   * @property {number} rate
   * @property {number} expected_coin_fee
   * @property {string} details
   * @property {string} type - 'buy' or 'sell'
   */
  paymentReceipt: {},

  /**
   * Sell estimate
   * @type {Object}
   * @property {number} amount
   * @property {string} currency
   * @property {string} code
   * @property {number} coin_amount
   * @property {number} coin_fee
   * @property {number} coin_amount_after_fee
   */

  sellEstimate: {},

  bgToCHTRate: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PAYMENT_MODALS.GET_COINS_FOR_PAYMENT_SUCCESS:
      return {
        ...state,
        coins: action.payload.data,
        meta: {
          ...state.meta,
          selectedCoin: action.payload.data.find(
            (coin) => coin.code === state.meta.selectedCoin
          )
            ? state.meta.selectedCoin
            : action.payload.data[0].code
        }
      };
    case PAYMENT_MODALS.GET_COIN_RATE:
      return {
        ...state,
        fetchingCoinRate: true
      };
    case PAYMENT_MODALS.GET_COIN_RATE_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          blockchainCoinRate: action.payload.data
        },
        fetchingCoinRate: false
      };
    case PAYMENT_MODALS.GET_COIN_RATE_FAIL:
      return {
        ...state,
        fetchingCoinRate: false
      };
    case PAYMENT_MODALS.GET_CONVERT_OPTIONS_SUCCESS:
      return {
        ...state,
        convertOptions: action.payload.data
      };
    case PAYMENT_MODALS.GET_PAYMENT_RECEIPT_SUCCESS:
      return {
        ...state,
        paymentReceipt: { ...action.payload.data, type: 'buy' }
      };
    case PAYMENT_MODALS.GET_BG_TO_CHT_RATE_SUCCESS:
      return {
        ...state,
        bgToCHTRate: action.payload.data.rate
      };
    case PAYMENT_MODALS.POST_SELL_ESTIMATE:
      return {
        ...state,
        fetchingSellEstimate: true
      }
    case PAYMENT_MODALS.POST_SELL_ESTIMATE_SUCCESS:
      return {
        ...state,
        sellEstimate: action.payload.data,
        fetchingSellEstimate: false
      };
    case PAYMENT_MODALS.POST_SELL_ESTIMATE_FAIL:
      return {
        ...state,
        fetchingSellEstimate: false
      }

    case PAYMENT_MODALS.SET_PAYMENT_MODAL_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.meta
        }
      };
    case PAYMENT_MODALS.SET_PAYMENT_RECEIPT:
      return {
        ...state,
        paymentReceipt: action.paymentReceipt
      };
    case PAYMENT_MODALS.RESET_CONVERT_OPTIONS:
      return {
        ...state,
        convertOptions: INITIAL_STATE.convertOptions
      };
    case PAYMENT_MODALS.RESET_PAYMENT_MODAL_META:
      return {
        ...state,
        meta: INITIAL_STATE.meta
      };
    default:
      return state;
  }
}
