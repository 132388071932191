// You need to import the function into rootReducer.js
import { TRANSACTIONS_TABLE } from './transactionsTableActionTypes';
import dayjs from 'dayjs';

const INITIAL_STATE = {
  /**
   * Transactions table data
   * @type {Object}
   * @property {Array} results - Array of transactions
   * @property {Object} results[0] - Transaction object
   * @property {Number} results[0].id - Transaction id
   * @property {String} results[0].created_at - Transaction creation date
   * @property {Number} results[0].amount - Transaction amount
   * @property {String} results[0].currency - Transaction currency
   * @property {String} results[0].currency_display - Transaction currency display
   * @property {String} results[0].icon - Transaction icon
   * @property {String} results[0].type - Transaction type
   * @property {String} results[0].type_display - Transaction type display
   * @property {String} results[0].status - Transaction status
   * @property {String} results[0].status_display - Transaction status display
   * @property {Object} results[0].to_data - Transaction to data
   * @property {Object} results[0].from_data - Transaction from data
   * @property {Object} results[0].details - Transaction details
   */
  transactions: {
    results: []
  },

  /**
   * Transactions options
   * @type {Object}
   * @property {String} first_transaction_at - Oldest transaction creation date
   * @property {Array} statuses - Array of statuses
   * @property {String} statuses[0].type - Status type
   * @property {String} statuses[0].name - Status name
   * @property {String} statuses[0].icon - Status icon
   */
  options: {
    statuses: []
  },
  loading: false,
  meta: {
    page_size: 10,
    ordering: null
  }
};

export default function (state = INITIAL_STATE, { payload, type, meta }) {
  switch (type) {
    case TRANSACTIONS_TABLE.GET_TRANSACTIONS_OPTIONS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          created_at_after: dayjs(payload?.data?.first_transaction_at || undefined).format(
            'YYYY-MM-DDTHH:mm:ss.SSS'
          )
        },
        options: payload.data
      };
    case TRANSACTIONS_TABLE.GET_TRANSACTIONS_TABLE:
      return {
        ...state,
        loading: true
      };
    case TRANSACTIONS_TABLE.GET_TRANSACTIONS_TABLE_SUCCESS:
      return {
        ...state,
        transactions: payload.data,
        loading: false
      };
    case TRANSACTIONS_TABLE.GET_TRANSACTIONS_TABLE_FAIL:
      return {
        ...state,
        loading: false
      };

    case TRANSACTIONS_TABLE.SET_TRANSACTIONS_TABLE_META:
      return {
        ...state,
        meta
      };
    default:
      return state;
  }
}
