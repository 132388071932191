import React, { memo, useState } from 'react';
import { DateRange as ReactDateRange } from 'react-date-range';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './DateRange.module.scss';

const defaultCalendarState = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }
];

const DateRange = (props) => {
  const {
    defaultRange = defaultCalendarState,
    onChange,
    containerStyle
  } = props;
  const [ranges, setRanges] = useState(defaultRange);

  const onRangeChange = (item) => {
    setRanges([item.selection]);
    onChange(
      dayjs(item.selection.startDate).format('YYYY-MM-DD'),
      dayjs(item.selection.endDate).format('YYYY-MM-DD')
    );
  };

  return (
    <div className={clsx([styles.dateRange, containerStyle])}>
      <ReactDateRange
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        onChange={onRangeChange}
        ranges={ranges}
        moveRangeOnFirstSelection={false}
      />
    </div>
  );
};

DateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  containerStyle: PropTypes.string
};

export default memo(DateRange);
