import React from 'react';
import {DialogMUI, ButtonMUI} from '../../../shared';
import errorImage from '../../../assets/images/error.png';

export default function ConnectionErrorDialog({open, onClose, onReturn, onRetry}) {
  return (
    <DialogMUI open={open} onClose={onClose} onReturn={onReturn}>
      <img className='mb-24 mx-auto' src={errorImage} alt='error' width='94px' height='94px' />
      <h1 className='h1 mb-16 t-center'>Error connecting</h1>
      <p className='mb-32 mx-auto max-w-288 t-center'>
        The connection attempt failed. please click try again and follow the steps to connect in your wallet.
      </p>
      <ButtonMUI fullWidth onClick={onRetry}>
        Try again
      </ButtonMUI>
    </DialogMUI>
  );
}
