import { TRANSACTIONS_TABLE } from './transactionsTableActionTypes';

export function getTransactionsTable({ queryString }) {
  return {
    type: TRANSACTIONS_TABLE.GET_TRANSACTIONS_TABLE,
    payload: {
      client: 'default',
      request: {
        url: `user/transactions/${!!queryString && queryString.length > 0 ? queryString : ''}`,
        method: 'get'
      }
    }
  };
}

export function getTransactionsOptions() {
  return {
    type: TRANSACTIONS_TABLE.GET_TRANSACTIONS_OPTIONS,
    payload: {
      client: 'default',
      request: {
        url: `user/transactions/options/`,
        method: 'get'
      }
    }
  };
}

export function setGameTableMeta(meta) {
  return {
    type: TRANSACTIONS_TABLE.SET_TRANSACTIONS_TABLE_META,
    meta
  };
}
