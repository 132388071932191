import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from '../helpers/notifySnack';
import ErrorBoundary from '../shared/ErrorBoundary';
import { ScrollToTop } from '../helpers/functions';
import AppRoutes from '../routes/AppRoutes';
import { toast, ToastContainer } from 'react-toastify';

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from '@web3modal/ethereum';
import { useWeb3ModalEvents, Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { goerli, arbitrum } from 'wagmi/chains';
import { authPath } from '../routes/paths';

import 'react-toastify/dist/ReactToastify.css';
import '../assets/styles/main.scss';

//const chains = [arbitrumGoerli];
const projectId = process.env.REACT_APP_PROJECT_ID;

const { chains, publicClient } = configureChains(
  [arbitrum],
  [w3mProvider({ projectId })]
);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const App = () => {
  toast.configure();
  const history = useHistory();

  const { errorSnack, errorSnackText, successSnack, successSnackText } =
    useSelector(({ app }) => app);

  useWeb3ModalEvents((event) => {
    console.table(event);
    if (event.name === 'ACCOUNT_DISCONNECTED') {
      localStorage.removeItem('token');
      history.push(authPath.signIn.path);
    }
  });

  useEffect(() => {
    if (errorSnack) {
      notifyError(errorSnackText);
    }
  }, [errorSnack]);

  useEffect(() => {
    if (successSnack) {
      notifySuccess(successSnackText);
    }
  }, [successSnack]);

  return (
    <ErrorBoundary>
      <WagmiConfig config={wagmiConfig}>
        <ScrollToTop />
        <AppRoutes />
        <ToastContainer />
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        defaultChain={arbitrum}
        ethereumClient={ethereumClient}
        themeMode='light'
      />
    </ErrorBoundary>
  );
};

export default App;
