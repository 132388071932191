import { PAYMENT_MODALS } from './paymentModalsTypes';

export function getCoinsForPayment() {
  return {
    type: PAYMENT_MODALS.GET_COINS_FOR_PAYMENT,
    payload: {
      client: 'default',
      request: {
        url: `coins/`,
        method: 'get'
      }
    }
  };
}

export function getCoinRate(code) {
  return {
    type: PAYMENT_MODALS.GET_COIN_RATE,
    payload: {
      client: 'default',
      request: {
        url: `coins/${code}/rate/`,
        method: 'get'
      }
    }
  };
}

export function getConvertOptions() {
  return {
    type: PAYMENT_MODALS.GET_CONVERT_OPTIONS,
    payload: {
      client: 'default',
      request: {
        url: `convert/options/`,
        method: 'get'
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 10000,
 *   "code": "BTC"
 * }
 */
export function postCreateBitgemsInvoice(data) {
  return {
    type: PAYMENT_MODALS.POST_CREATE_BITGEMS_INVOICE,
    payload: {
      client: 'default',
      request: {
        url: `buy/np-create-invoice/`,
        method: 'post',
        data: {
          ...data,
          currency: 'BG'
        }
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 10000,
 *   "code": "BTC"
 * }
 */
export function postCreateVCHTInvoice(data) {
  return {
    type: PAYMENT_MODALS.POST_CREATE_VCHT_INVOICE,
    payload: {
      client: 'default',
      request: {
        url: `buy/np-create-invoice/`,
        method: 'post',
        data: {
          ...data,
          currency: 'CHT'
        }
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 100,
 *   "code": "USDTERC20",
 *   "address": "0x000000000000000000000000000000000000dead"
 * }
 */
export function postCreateBitgemsPayout(data) {
  return {
    type: PAYMENT_MODALS.POST_CREATE_BITGEMS_PAYOUT,
    payload: {
      client: 'default',
      request: {
        url: `sell/np-create-payout/`,
        method: 'post',
        data: {
          currency: 'BG',
          ...data
        }
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 100,
 *   "code": "USDTERC20",
 *   "address": "0x000000000000000000000000000000000000dead"
 * }
 */
export function postCreateVCHTPayout(data) {
  return {
    type: PAYMENT_MODALS.POST_CREATE_VCHT_PAYOUT,
    payload: {
      client: 'default',
      request: {
        url: `sell/np-create-payout/`,
        method: 'post',
        data: {
          currency: 'CHT',
          ...data
        }
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 100,
 *   "currency": "BG",
 *   "code": "USDTERC20"
 * }
 */
export function postSellEstimate(data) {
  return {
    type: PAYMENT_MODALS.POST_SELL_ESTIMATE,
    payload: {
      client: 'default',
      request: {
        url: `sell/estimate/`,
        method: 'post',
        data
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 1,
 * }
 */
export function postConvertBitgemsToVCHT(data) {
  return {
    type: PAYMENT_MODALS.POST_CONVERT_BITGEMS_TO_VCHT,
    payload: {
      client: 'default',
      request: {
        url: `convert/BG/CHT/`,
        method: 'post',
        data
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 1,
 *   "transaction_hex": "string",
 * }
 */
export function postConvertCHTtoVCHT(data) {
  return {
    type: PAYMENT_MODALS.POST_CONVERT_CHT_TO_VCHT,
    payload: {
      client: 'default',
      request: {
        url: `convert/CHT/deposit/`,
        method: 'post',
        data
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 2500
 * }
 */
export function postConvertVCHTtoCHT(data) {
  return {
    type: PAYMENT_MODALS.POST_CONVERT_VCHT_TO_CHT,
    payload: {
      client: 'default',
      request: {
        url: `convert/CHT/withdraw/`,
        method: 'post',
        data
      }
    }
  };
}

/**
 *
 * Data example:
 * {
 *   "amount": 2500
 * }
 */
export function postConvertVCHTtoBG(data) {
  return {
    type: PAYMENT_MODALS.POST_CONVERT_VCHT_TO_BG,
    payload: {
      client: 'default',
      request: {
        url: `convert/CHT/BG/`,
        method: 'post',
        data
      }
    }
  };
}

export function getPaymentReceipt(id) {
  return {
    type: PAYMENT_MODALS.GET_PAYMENT_RECEIPT,
    payload: {
      client: 'default',
      request: {
        url: `buy/${id}/check/`,
        method: 'get'
      }
    }
  };
}

export function getBGtoCHTrate() {
  return {
    type: PAYMENT_MODALS.GET_BG_TO_CHT_RATE,
    payload: {
      client: 'default',
      request: {
        url: `coins/BGCHT/rate/`,
        method: 'get'
      }
    }
  };
}

export function setPaymentModalMeta(meta) {
  return {
    type: PAYMENT_MODALS.SET_PAYMENT_MODAL_META,
    meta
  };
}

export function setPaymentReceipt(paymentReceipt) {
  return {
    type: PAYMENT_MODALS.SET_PAYMENT_RECEIPT,
    paymentReceipt
  };
}

export function resetConvertOptions() {
  return {
    type: PAYMENT_MODALS.RESET_CONVERT_OPTIONS
  };
}

export function resetPaymentModalMeta() {
  return {
    type: PAYMENT_MODALS.RESET_PAYMENT_MODAL_META
  };
}
