import { MY_ACCOUNT } from './myAccountActionTypes';

export function getMyAccount() {
  return {
    type: MY_ACCOUNT.GET_MY_ACCOUNT,
    payload: {
      client: 'default',
      request: {
        url: `user/profile/account/`,
        method: 'get'
      }
    }
  };
}

export function patchMyAccount(data) {
  return {
    type: MY_ACCOUNT.PATCH_MY_ACCOUNT,
    payload: {
      client: 'default',
      request: {
        url: `user/profile/account/`,
        method: 'patch',
        data
      }
    }
  };
}

export function postContactSupport(data) {
  return {
    type: MY_ACCOUNT.POST_CONTACT_SUPPORT,
    payload: {
      client: 'default',
      request: {
        url: `user/contact-support/`,
        method: 'post',
        data
      }
    }
  };
}
