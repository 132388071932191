import React from 'react';

import Header from '../../layout/Header';
import NavSidebar from '../../layout/NavSidebar';
import MainRoutes from '../../routes/MainRoutes';
import PaymentModals from '../../features/PaymentModals';
import styles from './MainContainer.module.scss';

import Sound from '../../layout/Sound'
import Socket from '../../layout/Socket'

const MainContainer = () => {
  return (
    <>
      <div className={styles.root}>
        <NavSidebar />
        <div className={styles.container}>
          <Header />
          <MainRoutes />
        </div>
      </div>
      <Sound />
      <Socket />
      <PaymentModals />
    </>
  );
};

export default MainContainer;
