import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';

import { mainPath } from '../../routes/paths';
import { getMyAccount } from './myAccountActions';
import TimeSwitchers from './components/TimeSwitchers';
import MyProfile from './components/MyProfile';
import CompanyInfo from './components/CompanyInfo';
import CryptoBalance from '../../shared/CryptoBalance';
import { prettyScroll } from '../../helpers/functions';
import { clickSound } from '../../app/appActions';

import { ReactComponent as BackBubbleIcon } from '../../assets/icons/back-bubble.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down-red.svg';
import { ReactComponent as ChartIcon } from '../../assets/icons/chart-red.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as CompanyIcon } from '../../assets/icons/company.svg';

import styles from './MyAccount.module.scss';

const MyAccount = () => {
  const dispatch = useDispatch();
  const { accountInfo: acc } = useSelector(({ myAccount }) => myAccount);
  const { search } = useLocation();
  const [meta, setMeta] = useState({
    gameStatsTimePeriod: 'week',
    winningStatsTimePeriod: 'week'
  });

  const currentSection = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('section');
  }, [search]);

  /**
   * @type {boolean|undefined}
   * @description If true - shows green arrow and chart icons, if false - red
   */
  const areGameStatsPositive = useMemo(() => {
    if (_isEmpty(acc)) {
      return undefined;
    }
    return acc.games.week_percent_change >= 0;
  }, [acc.games?.week_percent_change]);

  useEffect(() => {
    dispatch(getMyAccount());
  }, []);

  const onTimePeriodChange = (type, timePeriod) => {
    setMeta({
      ...meta,
      [type]: timePeriod
    });
  };

  if (_isEmpty(acc)) {
    return <main className={styles.root} />;
  }

  return (
    <main className={`${styles.root} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.container} container`}>
        <Link
          className={styles.arrowBack}
          to='/main'
          onClick={() => dispatch(clickSound())}
        >
          <BackBubbleIcon />
        </Link>

        <section className={`${styles.baseInfo} card-wrap`}>
          <div className={styles.baseInfo__wrapper}>
            <img
              className={styles.baseInfo__avatar}
              src={acc.avatar}
              alt='avatar'
            />
            <div>
              <p className={styles.baseInfo__label}>Signed in as</p>
              <strong className={styles.baseInfo__username}>
                {acc.username}
              </strong>
            </div>
          </div>

          <CryptoBalance />
        </section>

        <div className={styles.stats}>
          <section className={`${styles.gameStats} card-wrap`}>
            <div className={styles.gameStats__header}>
              <h4 className={styles.gameStats__title}>Games</h4>
              <TimeSwitchers
                timePeriod={meta.gameStatsTimePeriod}
                onTimePeriodChange={(period) => {
                  onTimePeriodChange('gameStatsTimePeriod', period);
                  dispatch(clickSound());
                }}
              />
            </div>

            <div className={styles.gameStats__total}>
              <strong>
                {meta.gameStatsTimePeriod === 'week'
                  ? acc.games.this_week_count
                  : acc.games.all_time_count}
              </strong>

              {meta.gameStatsTimePeriod === 'week' && (
                <ArrowDownIcon
                  className={`${styles.gameStats__arrowIndicator} ${areGameStatsPositive
                      ? styles.gameStats__arrowIndicator__up
                      : styles.gameStats__arrowIndicator__down
                    }`}
                />
              )}
            </div>

            {meta.gameStatsTimePeriod === 'week' && (
              <div
                className={`${styles.gameStats__percentChange} ${areGameStatsPositive
                    ? styles.gameStats__percentChange__up
                    : styles.gameStats__percentChange__down
                  }`}
              >
                <ChartIcon />
                <strong>{acc.games.week_percent_change ? acc.games.week_percent_change.toFixed(2) : 0}%</strong>
              </div>
            )}
          </section>

          <section className={`${styles.winningStats} card-wrap`}>
            <div className={styles.winningStats__header}>
              <h4 className={styles.winningStats__title}>Winnings stats</h4>
              <TimeSwitchers
                timePeriod={meta.winningStatsTimePeriod}
                onTimePeriodChange={(period) => {
                  onTimePeriodChange('winningStatsTimePeriod', period);
                  dispatch(clickSound());
                }}
              />
            </div>

            <div className={styles.winningStats__totals}>
              <div className={styles.winningStats__currency}>
                <div className={styles.winningStats__currencyIcon}>
                  <BitgemIcon />
                </div>

                <div className={styles.winningStats__currencyInfo}>
                  <div className={styles.winningStats__currencyLabel}>
                    BitGems
                  </div>
                  <strong className={styles.winningStats__currencyAmount}>
                    {meta.winningStatsTimePeriod === 'week'
                      ? acc.winning_stats.won_bitgems_7days
                      : acc.winning_stats.won_bitgems_all_time}
                  </strong>
                </div>
              </div>

              <div className={styles.winningStats__currency}>
                <div className={styles.winningStats__currencyIcon}>
                  <VCHTIcon />
                </div>

                <div className={styles.winningStats__currencyInfo}>
                  <div className={styles.winningStats__currencyLabel}>vCHT</div>
                  <strong className={styles.winningStats__currencyAmount}>
                    {meta.winningStatsTimePeriod === 'week'
                      ? acc.winning_stats.won_cht_7days
                      : acc.winning_stats.won_cht_all_time}
                  </strong>
                </div>
              </div>
            </div>

            <Link
              className={styles.winningStats__link}
              to={mainPath.gameHistory.path}
            >
              View history log
              <ArrowRightIcon className={styles.winningStats__linkIcon} />
            </Link>
          </section>
        </div>

        <section className={`${styles.accountSections} card-wrap`}>
          <nav className={styles.accountSections__nav}>
            <NavLink
              className={styles.accountSections__navItem}
              activeClassName={styles.activeProfileLink}
              to={mainPath.myAccount.path}
              isActive={(match, location) => {
                if (!location) return false;
                return !location.search.includes('section');
              }}
              onClick={() => dispatch(clickSound())}
            >
              <UserIcon />
              My profile
            </NavLink>
            <NavLink
              className={styles.accountSections__navItem}
              activeClassName={styles.activeProfileLink}
              to={`${mainPath.myAccount.path}?section=company-info`}
              isActive={(match, location) => {
                if (!location) return false;
                return location.search.includes('company-info');
              }}
              onClick={() => dispatch(clickSound())}
            >
              <CompanyIcon />
              Company info
            </NavLink>
          </nav>

          <div className={styles.accountSections__content}>
            {!currentSection && <MyProfile />}
            {currentSection === 'company-info' && <CompanyInfo />}
          </div>
        </section>
      </div>
    </main>
  );
};

export default MyAccount;
