import {takeEvery} from 'redux-saga/effects';
import {disableButtonLoader, disableLoader, enableButtonLoader, enableLoader, openErrorSnack} from '../../app/rootSaga';

export function* handleAuthSnacks() {
  yield takeEvery('CHECK_EXISTS_FAIL', openErrorSnack);
  yield takeEvery('SIGN_IN_FAIL', openErrorSnack);
  yield takeEvery('CHECK_USERNAME_FAIL', openErrorSnack);
  yield takeEvery('SIGN_UP_FAIL', openErrorSnack);
}

export function* handleAuthLoaders() {
  yield takeEvery('SIGN_IN', enableButtonLoader);
  yield takeEvery('SIGN_IN_SUCCESS', disableButtonLoader);
  yield takeEvery('SIGN_IN_FAIL', disableButtonLoader);

  yield takeEvery('CHECK_USERNAME', enableLoader);
  yield takeEvery('CHECK_USERNAME_SUCCESS', disableLoader);
  yield takeEvery('CHECK_USERNAME_FAIL', disableLoader);

  yield takeEvery('SIGN_UP', enableButtonLoader);
  yield takeEvery('SIGN_UP_SUCCESS', disableButtonLoader);
  yield takeEvery('SIGN_UP_FAIL', disableButtonLoader);
}
