import React from 'react';

import BitgemPacks from './BitgemPacks';
import { prettyScroll } from '../../helpers/functions';

import styles from './Market.module.scss';

const Market = () => (
  <main className={styles.root} ref={prettyScroll}>
    <BitgemPacks />
  </main>
);

export default Market;
