import React from 'react';

import TextPageConstructor from '../../shared/TextPageConstructor';

const pageComponents = [
  {
    type: 'title',
    text: 'Wallet and Exchange Terms'
  },
  {
    type: 'paragraph',
    text: 'Effective Date: 20/09/2023'
  },
  {
    type: 'subtitle',
    text: '1. Wallet Terms:'
  },
  {
    type: 'heading',
    text: '1.1 Wallet Integration:'
  },
  {
    type: 'list',
    listItems: [
      'User Agreement: By creating a wallet account, you agree to provide accurate and complete information. You are responsible for maintaining the security of your account credentials.',
      'Security Responsibility: It is your sole responsibility to keep your wallet secure. This includes implementing appropriate security measures, such as setting a strong password, enabling two-factor authentication, and regularly updating your software.',
      'Transaction Responsibility: You may use your wallet to send and receive Chest Tokens, a utility token, and BitGems within the platform. It is imperative that you enter the correct recipient address, as transactions cannot be reversed once confirmed.',
      'Privacy Assurance: We respect your privacy and handle your personal information in strict accordance with our privacy policy. We commit to not sharing your information with third parties without your explicit consent, except as required by applicable law.'
    ]
  },
  {
    type: 'subtitle',
    text: '2. Exchange Terms:'
  },
  {
    type: 'heading',
    text: '2.1 Our Marketplace:'
  },
  {
    type: 'list',
    listItems: [
      'Trading Venue: The platform operates its own marketplace for the buying and selling of CHT (Chest Tokens). Users should be aware that prices on our marketplace may differ from those on external cryptocurrency exchanges. Users acknowledge that market prices may fluctuate and accept full responsibility for conducting their independent research before engaging in trading activities on our platform.'
    ]
  },
  {
    type: 'heading',
    text: '2.2 Selling Chest Tokens:'
  },
  {
    type: 'list',
    listItems: [
      'User Responsibility: Users have the option to sell their Chest Tokens on our platform and receive various cryptocurrencies in return. Users explicitly acknowledge and accept responsibility for covering all network fees associated with these transactions, including, but not limited to, blockchain transaction fees. These fees will be deducted from the final transaction amount.',
      'No Liability for Discrepancies: The platform bears no liability for discrepancies in the final transaction amount caused by network fees or fluctuations in cryptocurrency prices. Users are strongly encouraged to meticulously review transaction details before confirming sales.'
    ]
  },
  {
    type: 'heading',
    text: '2.3 Exchange Flexibility:'
  },
  {
    type: 'list',
    listItems: [
      'Fee-Free Conversion: Users can enjoy the flexibility of exchanging BitGems and VCHT without incurring any network fees, and vice versa. The platform is committed to providing a seamless and cost-effective conversion service to enhance the user experience.'
    ]
  },
  {
    type: 'heading',
    text: '2.4 Payment Processor Disclaimer:'
  },
  {
    type: 'list',
    listItems: [
      "Partnership with NowPayments: The platform has entered into a partnership with NowPayments, a reputable crypto payment processor, to facilitate cryptocurrency transactions. Users acknowledge that the estimated amount displayed on our platform for cryptocurrency transactions may differ from the final amount processed by NowPayments. Variations may arise due to factors such as market volatility, network fees, and exchange rate fluctuations. Users fully understand and accept that any discrepancies between the estimated amount and the final processed amount fall beyond the platform's control, and the platform shall not be held responsible for such discrepancies."
    ]
  },
  {
    type: 'subtitle',
    text: '3. Prohibited Activities:'
  },
  {
    type: 'list',
    listItems: [
      'Strict Prohibitions: Engaging in any illegal or fraudulent activities on our platform is strictly prohibited. This includes, but is not limited to, money laundering, market manipulation, and unauthorised account usage. Violations of these terms may result in account suspension or legal action.'
    ]
  }
];

const WalletAndExchangeTerms = () => (
  <TextPageConstructor pageComponents={pageComponents} />
);

export default WalletAndExchangeTerms;
