import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { setRoomResults, setRoomStatus, setRooms } from '../../features/Arena/storage/arenaActions';
import { setProfileInfo } from '../../features/Auth/authActions';
import { KickModal } from '../../features/Arena/WaitingRoom';
import { WS_URL } from '../../config';
//import styles from './Socket.module.scss';

const Socket = () => {
  const dispatch = useDispatch()
  const { profileInfo } = useSelector(({ auth }) => auth)
  const [kickModalStatus, setKickModalStatus] = useState(false)

  const { sendMessage } = useWebSocket(`${WS_URL}main/`, {
    onOpen: () => console.log('opened'),
    onMessage: (e) => {
      if (JSON.parse(e.data).hasOwnProperty('error')) {
        console.error('WebSoket error: ', JSON.parse(e.data).error);
      } else {
        if (JSON.parse(e.data).category === 'broadcast' && JSON.parse(e.data).type === 'rooms') dispatch(setRooms(JSON.parse(e.data).data)) // change room status(participants)
        if (JSON.parse(e.data).category === 'user' && JSON.parse(e.data).type === 'balance') {
          let tempObj = JSON.parse(e.data).data;
          console.log(profileInfo)
          if (!!profileInfo.current_room) tempObj.tempStatus = true
          dispatch(setProfileInfo(tempObj))
        } // change user balance
        if (JSON.parse(e.data).category === 'user' && JSON.parse(e.data).type === 'leave_current_room') setKickModalStatus(true) // kick user from room waiting
        if (JSON.parse(e.data).category === 'user' && JSON.parse(e.data).type === 'room_result') {
          setTimeout(() => dispatch(setRoomStatus('countDown')), 3000) // room status
          dispatch(setRoomResults(JSON.parse(e.data).data)) // room results
        } // room results event
      }
    },
    shouldReconnect: (closeEvent) => true,
    queryParams: {
      token: localStorage.getItem('token')
    }
  });

  return <>
    <KickModal
      status={kickModalStatus}
      setStatus={setKickModalStatus}
    />
  </>
};

export default Socket;
