import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ButtonMUI, InputMUI } from '../../../shared';
import { postContactSupport } from '../myAccountActions';
import {
  privacyPolicyPath,
  termsAndConditionsPath
} from '../../../routes/paths';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as DiscordIcon } from '../../../assets/icons/discord.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/Dribbble.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow_right.svg';

import styles from './Components.module.scss';

const CompanyInfo = () => {
  const { accountInfo: acc } = useSelector(({ myAccount }) => myAccount);
  const { buttonLoading } = useSelector(({ app }) => app);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup
          .string()
          .required('Email is required')
          .email('Email is invalid'),
        message: yup.string().required('Message is required')
      })
    ),
    defaultValues: {
      name: '',
      email: '',
      message: ''
    }
  });

  const onFormSubmit = async (data) => {
    const res = await dispatch(postContactSupport(data));
    if (res?.error) {
      Object.entries(res.error.response?.data).forEach(([key, value]) => {
        setError(key, {
          type: 'manual',
          message: typeof value === 'string' ? value : value[0]
        });
      });
    } else {
      reset();
    }
  };

  return (
    <div className={styles.companyInfo}>
      <h4 className={styles.companyInfo__title}>Organization</h4>
      {/* <p className={styles.companyInfo__warningCapsule}>
        Crypto, Inc., and/or its affiliates provides its software services
        through its website located at cryptochests.com and related products.
        Before using our Services, please read the{' '}
        <Link to={termsAndConditionsPath}>Terms of Service</Link> carefully,
        along with any other <Link to={privacyPolicyPath}>policies</Link> or
        notices on our website.
      </p> */}

      <div className={styles.companyInfo__contacts}>
        <div className={styles.companyInfo__contactsSection}>
          <a
            className={styles.companyInfo__emailIcon}
            href={`mailto:${acc.company_contact_info?.support_email}`}
          >
            <EmailIcon />
          </a>
          <div>
            <div className={styles.companyInfo__contactsLabel}>Email</div>
            <div className={styles.companyInfo__contactsValue}>
              {acc.company_contact_info?.support_email}
            </div>
          </div>
        </div>
        <div className={styles.companyInfo__contactsSection}>
          <div className={styles.companyInfo__contactsIcons}>
            <a
              href={acc.company_contact_info?.support_discord_link}
              target='_blank'
              rel='noreferrer'
            >
              <DiscordIcon />
            </a>
            <a
              href={acc.company_contact_info?.support_telegram_link}
              target='_blank'
              rel='noreferrer'
            >
              <TelegramIcon />
            </a>
            <a
              href={acc.company_contact_info?.support_twitter_link}
              target='_blank'
              rel='noreferrer'
            >
              <Twitter />
            </a>
          </div>
          <div
            className={styles.companyInfo__contactsValue}
            style={{ maxWidth: '84px' }}
          >
            Official group & chats
          </div>
        </div>
      </div>

      <a
        target="_blank"
        href="http://help.cryptochests.com/"
        className={styles.companyInfo__knowledgeLink}
      >
        Knowledge Base <ArrowRight />
      </a>

      <h4 className={styles.companyInfo__title}>Contact us</h4>
      <p className={styles.companyInfo__subtitle}>
        Write if you need help with something. Or maybe you have some unusual
        ideas for us?
      </p>

      <hr className={styles.companyInfo__separator} />

      <form
        className={styles.companyInfo__form}
        onSubmit={handleFormSubmit(onFormSubmit)}
      >
        <Controller
          name='name'
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <InputMUI
              error={!!errors.name?.message}
              helperText={errors.name?.message}
              label='Your name'
              placeholder='Enter your name...'
              fullWidth
              {...rest}
            />
          )}
        />

        <Controller
          name='email'
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <InputMUI
              error={!!errors.email?.message}
              helperText={errors.email?.message}
              label='Contact email'
              placeholder='example@gmail.com'
              fullWidth
              {...rest}
            />
          )}
        />

        <Controller
          name='message'
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <InputMUI
              error={!!errors.message?.message}
              helperText={errors.message?.message}
              label='Your message'
              placeholder='Enter your text...'
              multiline
              minRows={5}
              fullWidth
              {...rest}
            />
          )}
        />

        <ButtonMUI
          disabled={buttonLoading}
          loading={buttonLoading}
          formAction
          className={styles.companyInfo__submit}
        >
          Sent to support
        </ButtonMUI>
      </form>
    </div>
  );
};

export default CompanyInfo;
