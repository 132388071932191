export const ARENA = {
  GET_ARENA_ROOMS: 'GET_ARENA_ROOMS',
  GET_ARENA_ROOMS_SUCCESS: 'GET_ARENA_ROOMS_SUCCESS',
  GET_ARENA_ROOMS_FAIL: 'GET_ARENA_ROOMS_FAIL',

  GET_ROOM_INNER: 'GET_ROOM_INNER',
  GET_ROOM_INNER_SUCCESS: 'GET_ROOM_INNER_SUCCESS',
  GET_ROOM_INNER_FAIL: 'GET_ROOM_INNER_FAIL',

  JOIN_ROOM: 'JOIN_ROOM',
  JOIN_ROOM_SUCCESS: 'JOIN_ROOM_SUCCESS',
  JOIN_ROOM_FAIL: 'JOIN_ROOM_FAIL',

  LEAVE_ROOM: 'LEAVE_ROOM',
  LEAVE_ROOM_SUCCESS: 'LEAVE_ROOM_SUCCESS',
  LEAVE_ROOM_FAIL: 'LEAVE_ROOM_FAIL',

  SELECT_ROOM: 'SELECT_ROOM',

  SET_LOW_BALANCE_MODAL_STATUS: 'SET_LOW_BALANCE_MODAL_STATUS',

  SET_ARENA_ROOMS_STATUS: 'SET_ARENA_ROOMS_STATUS',

  SET_ARENA_ROOMS_RESULTS: 'SET_ARENA_ROOMS_RESULTS',
  
  SET_IS_CHT: 'SET_IS_CHT',
};
