import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  rootMainPath,
  rootAuthPath,
  termsAndConditionsPath,
  privacyPolicyPath,
  walletAndExchangeTermsPath
} from './paths';
import AuthContainer from '../containers/AuthContainer';
import MainContainer from '../containers/MainContainer';
import TermsAndConditions from '../features/TermsAndConditions';
import PrivacyPolicy from '../features/PrivacyPolicy';
import WalletAndExchangeTerms from '../features/WalletAndExchangeTerms';
import Landing from '../features/Landing/Landing';

const AppRoutes = () => {
  return (
    <Switch>
      {/* <Route
        path='/'
        exact
        render={() =>
          localStorage.token ? (
            <Redirect to={rootMainPath} />
          ) : (
            <Redirect to={rootAuthPath} />
          )
        }
      /> */}
      <Route path={rootAuthPath} component={AuthContainer} />
      <Route path={rootMainPath} component={MainContainer} />
      <Route path={termsAndConditionsPath} component={TermsAndConditions} />
      <Route path={privacyPolicyPath} component={PrivacyPolicy} />
      <Route
        path={walletAndExchangeTermsPath}
        component={WalletAndExchangeTerms}
      />
      <Route path={'/'} component={Landing} />
      <Route path='*' render={() => <Redirect to={rootAuthPath} />} />

    </Switch>
  );
};

export default AppRoutes;
