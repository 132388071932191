import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { formatPrice } from '../../helpers/functions';
import { DialogMUI } from '../../shared';
import {
  getPaymentReceipt,
  resetPaymentModalMeta
} from './paymentModalsActions';
import { ReactComponent as ReceiptIcon } from '../../assets/icons/receipt.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';
import SuccessIcon from '../../assets/images/success.png';

import styles from './styles.module.scss';

const PaymentSuccessModal = () => {
  const {
    meta: { receiptId },
    paymentReceipt: data
  } = useSelector(({ paymentModals }) => paymentModals);
  const dispatch = useDispatch();

  useEffect(() => {
    if (receiptId) dispatch(getPaymentReceipt(receiptId));
  }, [receiptId]);

  const onCloseDialog = () => {
    dispatch(resetPaymentModalMeta());
  };

  return (
    <DialogMUI
      open
      onClose={onCloseDialog}
      paperClassName={styles.successDialog__dialog}
    >
      <div className={styles.successDialog__wrapper}>
        <img
          src={SuccessIcon}
          alt='success'
          className={styles.successDialog__successIcon}
        />

        <h2 className={styles.successDialog__title}>All done, congrats!</h2>

        <div className={styles.purchaseDetails}>
          <div className={styles.purchaseDetails__header}>
            <ReceiptIcon />
            {data.type === 'buy' ? 'Purchase' : 'Withdrawal'} details
          </div>

          <div className={styles.purchaseDetails__row}>
            <div className={styles.purchaseDetails__label}>Order id</div>
            <div className={styles.purchaseDetails__value}>{data.order_id}</div>
          </div>

          {data.type === 'buy' && (
            <div className={styles.purchaseDetails__row}>
              <div className={styles.purchaseDetails__label}>
                {data.coin_name} price
              </div>
              <div className={styles.purchaseDetails__value}>
                {data.details?.actually_paid || data.coin_amount} {data.coin_code}
              </div>
            </div>
          )}

          <div className={styles.purchaseDetails__row}>
            <div className={styles.purchaseDetails__label}>Payment status</div>
            <div className={styles.purchaseDetails__value}>
              <i
                className={clsx([
                  styles.purchaseDetails__status,
                  styles[data.status]
                ])}
              />
              <span style={{ textTransform: 'capitalize' }}>
                {data.status_display}
              </span>
            </div>
          </div>

          <div className={styles.purchaseDetails__row}>
            <div className={styles.purchaseDetails__label}>
              Creation date and time
            </div>
            <div className={styles.purchaseDetails__value}>
              {dayjs
                .utc(data.created_at)
                .local()
                .format('DD-MM-YYYY, HH:mm:ss')}
            </div>
          </div>

          {data.type === 'buy' && (
            <div className={styles.purchaseDetails__row}>
              <div className={styles.purchaseDetails__label}>Last update</div>
              <div className={styles.purchaseDetails__value}>
                {dayjs
                  .utc(data.updated_at)
                  .local()
                  .format('DD-MM-YYYY, HH:mm:ss')}
              </div>
            </div>
          )}

          {data.type === 'sell' && (
            <div className={styles.purchaseDetails__row}>
              <div className={styles.purchaseDetails__label}>Network fee</div>
              <div className={styles.purchaseDetails__value}>
                {data.expected_coin_fee} {data.coin_code}
              </div>
            </div>
          )}

          <hr className={styles.purchaseDetails__separator} />

          {data.type === 'buy' && (
            <div className={styles.purchaseDetails__row}>
              <div className={styles.purchaseDetails__value}>You get</div>
              {data.currency === 'BG' && (
                <div className={styles.purchaseDetails__value}>
                  <BitgemIcon />
                  <span>{data.amount} BitGems</span>
                </div>
              )}
              {data.currency === 'CHT' && (
                <div className={styles.purchaseDetails__value}>
                  <VCHTIcon />
                  <span>{data.amount} Chest token</span>
                </div>
              )}
            </div>
          )}

          {data.type === 'sell' && (
            <div className={styles.purchaseDetails__row}>
              <div className={styles.purchaseDetails__value}>You get</div>
              <div className={styles.purchaseDetails__value}>
                <span>
                  ≈{formatPrice(data.coin_amount - data.expected_coin_fee)}
                  &nbsp;
                  {data.coin_code}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </DialogMUI>
  );
};

export default PaymentSuccessModal;
