import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from '../features/Auth/authReducer';
import myAccountReducer from '../features/MyAccount/myAccountReducer';
import marketReducer from '../features/Market/marketReducer';
import arenaReducer from '../features/Arena/storage/arenaReducer';
import gamesTableReducer from '../features/GamesTable/gamesTableReducer';
import transactionsTableReducer from '../features/TransactionsTable/transactionsTableReducer';
import walletsReducer from '../features/Wallets/walletsReducer';
import paymentModalsReducer from '../features/PaymentModals/paymentModalsReducer';
import landingReducer from '../features/Landing/landingReducer';
import { APP } from './appActionTypes';
import { ARENA } from '../features/Arena/storage/arenaActionTypes';

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: '',
  successSnack: '',
  successSnackText: '',
  musicVolume: !!localStorage.getItem('SFXVolume') ? Number(localStorage.getItem('SFXVolume')) : 0.07,
  musicStatus: false, //localStorage.getItem('SFX') === 'true',
  clickSoundCounter: 0,
  preOpenChestSoundCounter: 0,
  customSound: false,
  surroundSound: false,

  /**
   * @type {boolean}
   * @description If true, the current page sensitive data should be refetched.
   * For example, if the user converts bitgems to vCHT, the user's balance
   * should be refetched.
   */
  boolToRefetchCurrentPage: false
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP.LOADING:
      return { ...state, loading: action.payload };
    case APP.BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload };
    case APP.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: action.payload };
    case APP.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case APP.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: action.payload };
    case APP.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    case APP.SET_MUSIC:
      //localStorage.setItem('SFX', action.payload.data)
      return { ...state, musicStatus: action.payload.data };
    case APP.SET_VOLUME:
      localStorage.setItem('SFXVolume', action.payload.data)
      return { ...state, musicVolume: action.payload.data };
    case APP.CLICK_SOUND:
      return { ...state, clickSoundCounter: state.clickSoundCounter + 1 };
    case APP.PRE_OPEN_CHEST_SOUND:
      return {
        ...state,
        preOpenChestSoundCounter: state.preOpenChestSoundCounter + 1
      };
    case APP.SET_CUSTOM_SOUND:
      let tempObj = {};
      tempObj[
        `${!!action.payload.data.surround ? 'surround' : 'custom'}Sound`
      ] = action.payload.data.sound;
      return { ...state, ...tempObj };
    case APP.TOGGLE_CURRENT_PAGE_REFETCH:
      return {
        ...state,
        boolToRefetchCurrentPage: !state.boolToRefetchCurrentPage
      };
    case ARENA.LEAVE_ROOM_SUCCESS:
      return { ...state, surroundSound: false };
    default:
      return state;
  }
};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    app: appReducer,
    myAccount: myAccountReducer,
    market: marketReducer,
    arena: arenaReducer,
    gamesTable: gamesTableReducer,
    transactionsTable: transactionsTableReducer,
    wallets: walletsReducer,
    paymentModals: paymentModalsReducer,
    landing: landingReducer
  });

export default rootReducer;
