import { useEffect } from 'react';
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi';
import { parseEther } from 'viem';

import WheelLoader from '../../../shared/WheelLoader';

const WagmiTransfer = ({
  amount,
  onSuccess,
  onError,
  contractAddress,
  walletAddress
}) => {
  const {
    config,
    isError: isPrepareError,
    error: prepareError
  } = usePrepareContractWrite({
    address: contractAddress,
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'to',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'transfer',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ],
    functionName: 'transfer',
    args: [walletAddress, parseEther(String(amount))]
  });

  const { data, isError, error, write } = useContractWrite(config);
  const { isSuccess } = useWaitForTransaction({
    hash: data?.hash
  });

  useEffect(() => {
    if (write) write();
  }, [write]);

  useEffect(() => {
    if (isSuccess) onSuccess(data.hash);
  }, [isSuccess]);

  useEffect(() => {
    if (isPrepareError || isError) onError(prepareError || error);
  }, [isError, isPrepareError]);

  return <WheelLoader />;
};

export default WagmiTransfer;
