import React from 'react';
import AuthRoutes from '../../routes/AuthRoutes';
import logo from '../../assets/images/logo.png';
import styles from './AuthContainer.module.scss';

const AuthContainer = () => {
  return (
    <main className={styles.root}>
      <img className={styles.logo} src={logo} alt='logo' width='200px' height='78px' />
      <div className={styles.content}>
        <AuthRoutes />
      </div>
    </main>
  );
};

export default AuthContainer;
