import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Modal, useWeb3ModalEvents } from '@web3modal/react';
import { useSignMessage, useAccount, useDisconnect } from 'wagmi';
import {
  authPath,
  privacyPolicyPath,
  rootMainPath,
  termsAndConditionsPath
} from '../../routes/paths';
import { randomString, sendEvent } from '../../helpers/functions';
import { ButtonMUI } from '../../shared';
import { postCheckExists, postSignIn, setAuthData } from './authActions';
import ConnectionErrorDialog from './Dialogs/ConnectionErrorDialog';
import ChangeRequestDialog from './Dialogs/ChangeRequestDialog';
import { ReactComponent as ConnectWalletIcon } from '../../assets/icons/connect-wallet.svg';
import styles from './Auth.module.scss';

export default function SignIn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { buttonLoading } = useSelector(({ app }) => app);

  useEffect(() => {
    localStorage.getItem('token') && history.push(rootMainPath);
  }, []);

  // useWeb3ModalEvents((event) => console.info(event));
  const { isOpen, open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, connector, isConnected } = useAccount({
    onConnect({ address }) {
      isConnected && address && checkAddressExists();
    }
  });

  const signText =
    'Welcome to Ten Chest, please sign this message to verify your identity. Your custom challenge is:';
  const [addressExists, setAddressExists] = useState(undefined);

  const checkAddressExists = () => {
    dispatch(postCheckExists(address)).then((res) => {
      if (res.type.includes('_SUCCESS')) {
        const { is_exists } = res?.payload?.data;
        setMessage(`${signText} ${randomString(12)}`);
        setAddressExists(is_exists);
      }
    });
  };

  const [message, setMessage] = useState('');
  const {
    data: signature,
    isLoading,
    isSuccess,
    signMessage
  } = useSignMessage({
    message,
    onSuccess(data) {
      const authData = {
        crypto_address: address,
        challenge: message,
        signature: data
      };
      if (data && message) {
        if (addressExists) {
          dispatch(postSignIn(authData))
            .then((res) => {
              if (res?.payload?.data?.auth_token) {
                sendEvent('click_event', {
                  event_name: 'login',
                });

                localStorage.setItem('token', res?.payload?.data?.auth_token);
                history.push(rootMainPath);
              }
            })
            .catch((error) => error && openErrorDialog());
        } else {
          dispatch(setAuthData(authData));
          history.push(authPath.signUp.path);
        }
      }
    },
    onError(error) {
      error && openErrorDialog();
    }
  });

  const [errorDialog, setErrorDialog] = useState(false);
  const openErrorDialog = () => {
    disconnect();
    setMessage('');
    setErrorDialog(true);
  };
  const closeErrorDialog =
    (type = 'close') =>
    () => {
      setErrorDialog(false);
      type === 'retry' && open();
    };

  const [changeRequestDialog, setChangeRequestDialog] = useState(false);

  useEffect(() => {
    if (message && isConnected && connector && !signature) {
      signMessage({ message });
    }
  }, [message, isConnected, signature]);

  const onConnectClick = () => {
    sendEvent('click_event', {
      event_name: 'click_connect_wallet'
    });

    open();
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Welcome to the game</h1>
      <p className={styles.desc}>
        Participate in battles, get chests and earn.
        <br />
        Connect a wallet to log in to account.
      </p>

      <ButtonMUI
        className='my-32 min-w-212'
        startIcon={<ConnectWalletIcon />}
        disabled={buttonLoading || isOpen || isLoading}
        loading={buttonLoading || isOpen || isLoading}
        onClick={onConnectClick}
      >
        {isSuccess && isConnected ? 'Connected' : 'Connect wallet'}
      </ButtonMUI>

      <div className={styles.footer}>
        <p>
          By connecting the wallet, you agree to CryptoChests{' '}
          <Link to={privacyPolicyPath}>Privacy Policy</Link> and{' '}
          <Link to={termsAndConditionsPath}>Terms of service.</Link>
        </p>
      </div>

      {errorDialog && (
        <ConnectionErrorDialog
          open={errorDialog}
          onClose={closeErrorDialog()}
          onReturn={closeErrorDialog('retry')}
          onRetry={closeErrorDialog('retry')}
        />
      )}
      {changeRequestDialog && (
        <ChangeRequestDialog
          open={changeRequestDialog}
          onClose={() => setChangeRequestDialog(false)}
          onReturn={() => setChangeRequestDialog(false)}
          onRetry={() => setChangeRequestDialog(false)}
          styles={styles}
        />
      )}
    </div>
  );
}
