import { APP } from './appActionTypes';

export function closeErrorSnack() {
  return {
    type: APP.ERROR_SNACK_CLOSE
  };
}

export function setMusicStatus(isPlay) {
  return {
    type: APP.SET_MUSIC,
    payload: {
      data: isPlay
    }
  };
}

export function setMusicVolume(volume) {
  return {
    type: APP.SET_VOLUME,
    payload: {
      data: volume
    }
  };
}

export const clickSound = () => ({ type: APP.CLICK_SOUND });

export const preOpenChestSoundAction = () => ({
  type: APP.PRE_OPEN_CHEST_SOUND
});

export const setCustomSound = (sound, surround = false) => ({
  type: APP.SET_CUSTOM_SOUND,
  payload: {
    data: { sound, surround }
  }
});

export const toggleCurrentPageRefetch = () => ({
  type: APP.TOGGLE_CURRENT_PAGE_REFETCH
});
