export const MY_ACCOUNT = {
  GET_MY_ACCOUNT: 'GET_MY_ACCOUNT',
  GET_MY_ACCOUNT_SUCCESS: 'GET_MY_ACCOUNT_SUCCESS',
  GET_MY_ACCOUNT_FAIL: 'GET_MY_ACCOUNT_FAIL',

  PATCH_MY_ACCOUNT: 'PATCH_MY_ACCOUNT',
  PATCH_MY_ACCOUNT_SUCCESS: 'PATCH_MY_ACCOUNT_SUCCESS',
  PATCH_MY_ACCOUNT_FAIL: 'PATCH_MY_ACCOUNT_FAIL',

  POST_CONTACT_SUPPORT: 'POST_CONTACT_SUPPORT',
  POST_CONTACT_SUPPORT_SUCCESS: 'POST_CONTACT_SUPPORT_SUCCESS',
  POST_CONTACT_SUPPORT_FAIL: 'POST_CONTACT_SUPPORT_FAIL'
};
