import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { ReactComponent as ArrowBackIcon } from './icons/arrow-back.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import styles from './DialogMUI.module.scss';
import { classList } from '../../helpers/functions';
import { useDispatch } from 'react-redux';
import { clickSound } from '../../app/appActions';

const DialogMUI = ({
  open,
  onClose,
  className = '',
  paperClassName = '',
  children,
  fullScreen = false,
  onReturn,
  withoutPaddings = false,
  exitBtn = true
}) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={className}
      classes={{
        root: styles.root,
        paper: classList(styles.paper, paperClassName, { [styles.withoutPaddings]: withoutPaddings }),
        paperFullScreen: styles.paperFullScreen
      }}
      fullScreen={fullScreen}
    >
      <div className={classList(styles.content, { [styles.withoutPaddings]: withoutPaddings })}>
        {!!onReturn && (
          <button
            className={`${styles.button} ${styles.buttonLeft}`}
            type='button'
            aria-label='Return dialog button'
            onClick={e => {
              dispatch(clickSound())
              onReturn && onReturn(e)
            }}
          >
            <ArrowBackIcon className={styles.buttonIcon} />
          </button>
        )}
        {exitBtn && <button
          className={`${styles.button} ${styles.buttonRight}`}
          type='button'
          aria-label='Close dialog button'
          onClick={e => {
            dispatch(clickSound())
            onClose && onClose(e)
          }}
        >
          <CloseIcon className={styles.buttonIcon} />
        </button>}
        {children}
      </div>
    </Dialog>
  );
};

DialogMUI.muiName = 'Dialog';
DialogMUI.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onReturn: PropTypes.func
};

export default DialogMUI;
