import React, { useEffect, useState } from 'react';
import styles from './Arena.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRooms, joinRoom, selectRoom } from './storage/arenaActions';
import { classList, prettyScroll } from '../../helpers/functions';
import { ReactComponent as BitIcon } from '../../assets/icons/Bit_icon.svg';
import { ReactComponent as CHTIcon } from '../../assets/icons/vcht_switcher.svg';
import { ButtonMUI, CheckboxMUI, DialogMUI } from '../../shared';
import { useHistory } from 'react-router-dom';
import { mainPath } from '../../routes/paths';

const AgreementModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedRoom, isCHT } = useSelector(({ arena }) => arena)
  const [checkboxStatus, setCheckboxStatus] = useState(false)

  const join = () => {
    dispatch(joinRoom(selectedRoom.type, { current_room: selectedRoom.room_id, do_not_show_entry_payment: checkboxStatus })).then(res => {
      if (res.type.includes('_SUCCESS')) history.push(mainPath.waitingRoom.path.replace(':roomID', res.payload.data.id))
      onClose()
    })
  }

  return <DialogMUI
    open={open}
    onClose={onClose}
  >
    <div className={styles.agreementModal}>
      <h1>Entry payment</h1>
      <div className={styles.agreementModalStake}>
        {isCHT ? <CHTIcon /> : <BitIcon />}
        <p>{selectedRoom.stake}<span>{isCHT ? 'vCHT' : 'BitGems'}</span></p>
      </div>
      <span>This is the price of participation in the game. {isCHT ? 'vCHT' : 'BitGems'} will be deducted from your account.</span>
      <div className={styles.agreementModalText}>
        <div className={styles.agreementModalTextInner} ref={prettyScroll}>
          <>
            <h2>CRYPTO CHESTS GAME AGREEMENT</h2>
            <p>This Game Agreement ("Agreement") is entered into between the participant (“Player”) and the individual licensor (“Licensor”) of the Crypto Chests gaming platform (“Platform”), located in the United Kingdom.</p>
            <h2>1. ACCEPTANCE OF TERMS</h2>
            <p>1.1 By participating in the Platform, the Player agrees to be bound by the terms and conditions set forth in this Agreement, as well as any additional terms, policies, or procedures that may be adopted by the Licensor.</p>
            <h2>2. GAME RULES</h2>
            <ul>
              <p>2.1 The Player agrees to abide by the following rules of the Platform:</p>
              <li>a) Players participate in rounds, each requiring an entry fee in {isCHT ? 'vCHT' : 'BitGems'}.</li>
              <li>b) In each round, players receive one chest containing {isCHT ? 'vCHT' : 'BitGems'} or Chest Tokens. Nine out of ten players win 1.1 times their entry in {isCHT ? 'vCHT' : 'BitGems'}, while one wins Chest Tokens.</li>
              <li>c) {isCHT ? 'vCHT' : 'BitGems'} are virtual currency with a fixed value equivalent to 1 USD and can be exchanged for cryptocurrency. Chest Tokens have variable value and utility within the Platform.</li>
              <li>d) Players must provide accurate information during account registration.</li>
              <li>e) The use of scripts, bots, or any other unauthorized means to interact with the Platform is prohibited.</li>
            </ul>
            <p>2.2 The Licensor reserves the right to modify the Game rules at any time without prior notice.</p>
            <h2>3. ACCOUNT CREATION AND DATA COLLECTION</h2>
            <p>3.1 The Player may be required to create an account to use the Platform. The Player agrees to provide accurate information during account registration and to keep this information up-to-date. The Licensor may collect and store personal data as necessary for the operation of the Platform.</p>
            <h2>4. THIRD-PARTY INTEGRATIONS</h2>
            <p>4.1 The Platform may contain integrations with third-party services. The Licensor does not assume responsibility or liability for any third-party services or content. Players are advised to read the terms and conditions of third-party providers.</p>
            <h2>5. NO WARRANTIES</h2>
            <p>5.1 The Platform is provided “AS IS,” without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <h2>6. LIMITATION OF LIABILITY</h2>
            <p>6.1 In no event shall the Licensor be liable for any claim, damages, or other liabilities, whether in an action of contract, tort or otherwise, arising from, out of, or in connection with the use or inability to use the Platform.</p>
            <h2>7. GOVERNING LAW</h2>
            <p>7.1 This Agreement and any dispute arising out of or in connection with this Agreement shall be governed by and construed in accordance with the laws applicable to cryptocurrency projects.</p>
            <h2>8. ENTIRE AGREEMENT</h2>
            <p>8.1 This Agreement constitutes the entire agreement between the parties concerning the use of the Platform and supersedes all prior understandings and agreements.</p>
            <p>By participating in the Platform, the Player acknowledges that they have read, understood, and agree to be bound by the terms of this Agreement.</p>
          </>
        </div>
      </div>
      <label className={styles.agreementModalCheckbox}>
        <CheckboxMUI
          checked={checkboxStatus}
          onChange={() => setCheckboxStatus(prev => !prev)}
        />
        <p>Do not show this warning window again</p>
      </label>
      <div className={styles.agreementModalButtons}>
        <ButtonMUI
          variant={'text'}
          onClick={onClose}
        >
          Go back
        </ButtonMUI>
        <ButtonMUI
          onClick={join}
        >
          Enter
        </ButtonMUI>
      </div>
    </div>
  </DialogMUI>
};

export default AgreementModal;
