import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';

import {
  postCreateBitgemsPayout,
  getCoinRate,
  setPaymentModalMeta,
  resetPaymentModalMeta,
  getCoinsForPayment,
  postSellEstimate,
  setPaymentReceipt
} from './paymentModalsActions';
import { ButtonMUI, DialogMUI, InputMUI } from '../../shared';
import CoinsSelect from './components/CoinsSelect';
import SellBitgemsDiscretion from './components/SellBitgemsDiscretion';
import { formatPrice, renderLoadingPrice } from '../../helpers/functions';
import { toggleCurrentPageRefetch } from '../../app/appActions';
import { usePaymentModals } from './index';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange-rate.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';

import styles from './styles.module.scss';

const SellBitgemsModal = () => {
  const {
    coins,
    meta: { youPay, youGet, selectedCoin, blockchainCoinRate, customAddress },
    fetchingCoinRate,
    fetchingSellEstimate,
    sellEstimate,
    profileInfo: { balance_bitgems }
  } = useSelector(({ paymentModals, auth }) => ({ ...paymentModals, ...auth }));
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(null);
  const [apiError, setApiError] = useState(null);
  const { handlePaymentSuccessModal } = usePaymentModals();

  const fetchSellEstimate = (amount) => {
    dispatch(
      postSellEstimate({
        amount,
        currency: 'BG',
        code: selectedCoin
      })
    );
  };

  const debouncedFetchSellEstimate = useCallback(
    _debounce(fetchSellEstimate, 500),
    [selectedCoin]
  );

  useEffect(() => {
    dispatch(getCoinsForPayment());
  }, []);

  useEffect(() => {
    dispatch(getCoinRate(selectedCoin));
  }, [selectedCoin]);

  const { coinRate, minBitgemsToSell, maxBitgemsToSell } = useMemo(() => {
    return {
      coinRate: blockchainCoinRate.sell.bitgems_rate,
      minBitgemsToSell: blockchainCoinRate.sell.bitgems_min_amount,
      maxBitgemsToSell: Math.min(
        blockchainCoinRate.sell.bitgems_max_amount,
        balance_bitgems
      )
    };
  }, [blockchainCoinRate, balance_bitgems]);

  useEffect(() => {
    if (coinRate) {
      const newYouPay = youPay === 0 ? minBitgemsToSell : youPay;
      dispatch(
        setPaymentModalMeta({
          youPay: newYouPay,
          youGet: formatPrice(newYouPay * coinRate)
        })
      );
      fetchSellEstimate(newYouPay);
    }
  }, [coinRate]);

  const isButtonDisabled = useMemo(() => {
    return (
      !youPay ||
      !youGet ||
      !customAddress ||
      fetchingCoinRate ||
      submitting ||
      youPay % 1 !== 0 ||
      youPay < minBitgemsToSell ||
      youPay > maxBitgemsToSell
    );
  }, [
    youPay,
    youGet,
    customAddress,
    fetchingCoinRate,
    submitting,
    minBitgemsToSell,
    maxBitgemsToSell
  ]);

  const isFixTheAmountDisabled = useMemo(() => {
    if (fetchingCoinRate) return true;
    return (
      youPay % 1 === 0 &&
      youPay >= minBitgemsToSell &&
      youPay <= maxBitgemsToSell
    );
  }, [youPay, minBitgemsToSell, maxBitgemsToSell, fetchingCoinRate]);

  const onClose = () => {
    dispatch(resetPaymentModalMeta());
  };

  const onYouPayChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: value,
        youGet: formatPrice(value * coinRate)
      })
    );
    debouncedFetchSellEstimate(value);
  };

  const onYouGetChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: formatPrice(value / coinRate),
        youGet: value
      })
    );
  };

  const onFixAmountClick = () => {
    if (youPay < minBitgemsToSell) {
      onYouPayChange(minBitgemsToSell);
      return;
    }

    if (youPay > maxBitgemsToSell) {
      onYouPayChange(maxBitgemsToSell);
      return;
    }

    if (youPay % 1 === 0) return;

    const amount = youPay - (youPay % 1);
    onYouPayChange(amount || 1);
  };

  const onCoinChange = (code) => {
    dispatch(setPaymentModalMeta({ selectedCoin: code }));
  };

  const onSellClick = () => {
    setSubmitting(true);
    setApiError(null);
    dispatch(
      postCreateBitgemsPayout({
        amount: youPay,
        code: selectedCoin,
        address: customAddress
      })
    ).then((res) => {
      if (res.type.includes('_SUCCESS')) {
        dispatch(toggleCurrentPageRefetch());
        dispatch(
          setPaymentReceipt({
            ...res.payload.data,
            type: 'sell',
            status: 'pending',
            status_display: 'Pending'
          })
        );
        handlePaymentSuccessModal();
      } else {
        setApiError(res.error.response?.data);
        setSubmitting(false);
      }
    });
  };

  const renderError = (errorText) => (
    <div className={styles.paymentModal__inputError}>
      <ErrorIcon />
      <span>{errorText}</span>
    </div>
  );

  return (
    <DialogMUI
      open
      onClose={onClose}
      paperClassName={styles.paymentModal__dialog}
    >
      <div className={styles.paymentModal__wrapper}>
        <h2 className={styles.paymentModal__title}>Sell Bitgems</h2>

        <SellBitgemsDiscretion />

        <div className={styles.upperBlock}>
          <div className={styles.upperBlock__header}>
            <div className={styles.upperBlock__title}>AMOUNT</div>
            <button
              className={styles.upperBlock__fixAmount}
              role='button'
              onClick={onFixAmountClick}
              disabled={isFixTheAmountDisabled}
            >
              Fix the amount
            </button>
          </div>

          <div className={styles.upperBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.upperBlock__input}
              value={youPay}
              disabled={fetchingCoinRate}
              onChange={(e) => onYouPayChange(e.target.value)}
            />

            <div className={styles.upperBlock__bitgems}>
              <BitgemIcon />
              <span>BitGems</span>
            </div>
          </div>

          {youPay % 1 !== 0 &&
            renderError(
              'Note that BitGems can only be sold in whole numbers. Fractional purchases are not supported.'
            )}

          {youPay < minBitgemsToSell &&
            renderError(
              `The minimum amount of BitGems you can sell is ${formatPrice(
                minBitgemsToSell
              )}.`
            )}

          {youPay > maxBitgemsToSell &&
            renderError(
              `The maximum amount of BitGems you can sell is ${maxBitgemsToSell}.`
            )}

          {!!apiError?.amount && renderError(apiError.amount[0])}
        </div>

        <div className={styles.paymentModal__rateSeparator}>
          <div className={styles.paymentModal__rate}>
            <ExchangeIcon />1 BitGems ={' '}
            {renderLoadingPrice(coinRate, fetchingCoinRate, 15)} {selectedCoin}
          </div>
        </div>

        <div className={styles.lowerBlock}>
          <div className={styles.lowerBlock__header}>
            <div className={styles.lowerBlock__title}>YOU GET</div>
          </div>

          <div className={styles.lowerBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.lowerBlock__input}
              value={youGet}
              disabled={fetchingCoinRate}
              onChange={(e) => onYouGetChange(e.target.value)}
            />

            <CoinsSelect
              coins={coins}
              selectedCoin={selectedCoin}
              onChange={onCoinChange}
            />
          </div>
        </div>

        <div className={styles.externalWallet}>
          <InputMUI
            placeholder='Your external wallet'
            name='customAddress'
            error={!customAddress}
            fullWidth
            value={customAddress}
            onChange={(e) =>
              dispatch(
                setPaymentModalMeta({ customAddress: e.target.value.trim() })
              )
            }
            startAdornment={
              <WalletIcon className={styles.externalWallet__icon} />
            }
          />
        </div>

        <div className={styles.walletAddressError}>
          {!!apiError?.address && renderError(apiError.address[0])}
        </div>

        <div className={styles.paymentModal__rateSeparator} />

        <div className={styles.paymentModal__estimate}>
          <div className={styles.paymentModal__estimateLine}>
            <div className={styles.paymentModal__estimateLabel}>
              Transaction fee
            </div>
            <div className={styles.paymentModal__estimateValue}>
              {renderLoadingPrice(
                sellEstimate.coin_fee,
                fetchingSellEstimate,
                14
              )}
              &nbsp;
              {selectedCoin}
            </div>
          </div>
          <div className={styles.paymentModal__estimateLine}>
            <div className={styles.paymentModal__estimateLabel}>
              <strong>Total</strong>
            </div>
            <div className={styles.paymentModal__estimateValue}>
              <strong>
                {renderLoadingPrice(
                  sellEstimate.coin_amount_after_fee,
                  fetchingSellEstimate,
                  14
                )}
                &nbsp;
                {selectedCoin}
              </strong>
            </div>
          </div>
        </div>

        <ButtonMUI
          disabled={isButtonDisabled}
          loading={fetchingCoinRate || submitting}
          onClick={onSellClick}
          loaderType='three-dots'
        >
          Sell
        </ButtonMUI>
      </div>
    </DialogMUI>
  );
};

export default SellBitgemsModal;
