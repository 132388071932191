import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { rootMainPath, rootAuthPath, mainPath } from './paths';
import { ComingSoon, NotFound } from '../shared';

const MainRoutes = () => {
  if (!localStorage.token) return <Redirect to={rootAuthPath} />;

  return (
    <Switch>
      <Redirect from={rootMainPath} exact to={mainPath.overview.path} />
      {Object.values(mainPath).map(
        ({ name, path, component, comingSoon, isNavItem }, idx) => (
          <Route
            key={idx}
            path={path}
            exact={isNavItem}
            component={
              comingSoon ? () => <ComingSoon name={name} /> : component
            }
          />
        )
      )}

      {/* Payment redirects, no need to add them to paths to avoid confusion */}
      {/* @param {string} id - payment id*/}
      <Route
        path='/main/payment-cancel/:id'
        render={(r) => (
          <Redirect
            to={`${mainPath.wallets.path}?id=${r.match.params.id}&status=error`}
          />
        )}
      />
      <Route
        path='/main/payment-success/:id'
        render={(r) => (
          <Redirect
            to={`${mainPath.wallets.path}?id=${r.match.params.id}&status=success`}
          />
        )}
      />

      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default MainRoutes;
