import React from 'react';
import { Link } from 'react-router-dom';

import { prettyScroll } from '../../helpers/functions';
import GamesTable from '../GamesTable';
import { mainPath } from '../../routes/paths';
import { ReactComponent as BackBubbleIcon } from '../../assets/icons/back-bubble.svg';

import styles from './GameHistory.module.scss';

const GameHistory = () => {
  return (
    <main className={`${styles.root} page-wrap`} ref={prettyScroll}>
      <Link className={styles.backToProfile} to={mainPath.myAccount.path}>
        <BackBubbleIcon />
        <div>Back to profile</div>
      </Link>

      <div className='card-wrap'>
        <GamesTable
          customTitle={<div className={styles.tableTitle}>Game history</div>}
        />
      </div>
    </main>
  );
};

export default GameHistory;
