import React, { useEffect, useState, useMemo } from 'react';
import styles from './Arena.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRooms, joinRoom, selectRoom, setRoomStatus } from './storage/arenaActions';
import { classList, prettyScroll } from '../../helpers/functions';
import { ReactComponent as BitIcon } from '../../assets/images/bg_win_img.svg';
import { ReactComponent as CTHIcon } from '../../assets/images/cht_win_img.svg';
import { ButtonMUI, CheckboxMUI, DialogMUI } from '../../shared';
import { useHistory } from 'react-router-dom';
import { mainPath } from '../../routes/paths';
import { patchProfileInfo, setProfileInfo } from '../Auth/authActions';

const WinnerModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roomInner, roomResults } = useSelector(({ arena }) => arena)
  const myWin = useMemo(() => {
    if (!!roomResults && !!roomResults.participants) {
      return roomResults?.participants.find(el => el.is_me)
    } else if (!!roomInner && !!roomInner.participants) {
      return roomInner?.participants.find(el => el.is_me)
    }
  }
    , [roomInner?.participants, roomResults?.participants])

  const join = () => {
    dispatch(joinRoom(roomInner.type)).then(res => {
      if (res.type.includes('_SUCCESS')) {
        onClose()
        dispatch(setProfileInfo({ current_room: res.payload.data.id }))
        dispatch(setRoomStatus('waiting'))
        history.push(mainPath.waitingRoom.path.replace(':roomID', res.payload.data.id))
      }
    })
  }

  useEffect(() => {
    if (!!open) dispatch(patchProfileInfo({ seen_result: true }))
  }, [open])

  return <DialogMUI
    open={open}
    onClose={() => {
      onClose();
      dispatch(setRoomStatus('waiting'))
      if (history.location.pathname.includes('arena')) history.push(mainPath.arena.path)
    }}
  >
    {!!myWin && <div className={styles.leaveModal}>
      {myWin.win_currency === 'BG'
        ? <h1>You have won {myWin.win_amount} {myWin.win_currency_display} this time</h1>
        : myWin.win_currency === 'CHT'
          ? <h1>Keep the gold close to you! Here's {myWin.win_amount} {myWin.win_currency_display}</h1>
          : <h1>You lost</h1>}
      {myWin.win_currency === 'BG'
        ? <BitIcon />
        : myWin.win_currency === 'CHT'
          ? <CTHIcon />
          : null}
      {myWin.win_currency === 'BG'
        ? <p>Claim the prize and return to the game for more victories whenever you desire.</p>
        : myWin.win_currency === 'CHT'
        ?<p>Keep your Chest Tokens to benefit from their growth potential, or exchange them for BitGems to continue playing the game.</p>
      :<p>You lost your bet. Try one more time!</p>}
      <div className={styles.buttons}>
        <ButtonMUI
          className={styles.normal}
          onClick={join}
        >
          Play again
        </ButtonMUI>
      </div>
    </div>}
  </DialogMUI>
};

export default WinnerModal;
