import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

import { ButtonMUI, NotFound, Table } from '../../shared';
import { getGamesTable, setGameTableMeta } from './gamesTableActions';
import { mainPath } from '../../routes/paths';

import { ReactComponent as ChestIcon } from '../../assets/icons/chest.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';
import { ReactComponent as NoGamesIcon } from '../../assets/icons/no-games.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as RadioCheckedIcon } from '../../assets/icons/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../assets/icons/radio-unchecked.svg';
import styles from './GamesTable.module.scss';

const columns = [
  {
    name: 'ID',
    width: 15,
    renderKey: 'room_id',
    render: (id) => {
      return <div className={styles.games__row}>{id}</div>;
    }
  },
  {
    name: 'Date',
    value: 'date',
    width: 15,
    renderKey: 'date',
    render: (date) => {
      return (
        <div className={clsx([styles.games__row, styles.date])}>
          {dayjs.utc(date).local().format('MMM D, HH:mm')}
        </div>
      );
    }
  },
  {
    name: 'Room',
    width: 20,
    renderKey: ['room_name', 'room_icon'],
    render: (i) => {
      return (
        <div className={styles.games__row}>
          <img
            src={i.room_icon}
            alt={i.room_name}
            className={styles.roomIcon}
          />
          <span>{i.room_name}</span>
        </div>
      );
    }
  },
  {
    name: 'Bet',
    width: 15,
    renderKey: ['bet', 'room_currency'],
    render: (i) => {
      return (
        <div className={styles.games__row}>
          {i.room_currency === 'BG' ? <BitgemIcon /> : <VCHTIcon />}
          <span>{i.bet}</span>
        </div>
      );
    }
  },
  {
    name: 'Your turn',
    width: 15,
    renderKey: 'turn',
    render: (turn) => {
      return (
        <div className={styles.games__row}>
          <ChestIcon />
          <span>{turn}</span>
        </div>
      );
    }
  },
  {
    name: 'Winnings',
    width: 20,
    renderKey: ['win_amount', 'win_currency_display', 'win_currency'],
    render: (i) => {
      return (
        <div className={styles.games__row}>
          {i.win_currency === 'BG' ? <BitgemIcon /> : <VCHTIcon />}
          <span>
            {!!i.win_amount && '+'}
            {i.win_amount} {i.win_currency_display}
          </span>
        </div>
      );
    }
  }
];

const GamesTable = (props) => {
  const { customTitle } = props;
  const dispatch = useDispatch();
  const { games, loading, meta } = useSelector(({ gamesTable }) => gamesTable);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const setParams = useCallback(
    (params) => {
      dispatch(setGameTableMeta(params));
    },
    [dispatch]
  );

  const onFilterOptionClick = (newValue) => {
    closePopover();
    if (newValue === meta.win_currency) return;
    setParams({
      ...meta,
      win_currency: newValue
    });
  };

  const renderedFilters = useMemo(
    () => (
      <div className={styles.games__filters}>
        <div className={styles.games__filter} onClick={openPopover}>
          <FilterIcon />
        </div>
        <Popover
          id={Boolean(anchorEl) ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{
            paper: styles.games__popover
          }}
        >
          <div>
            <div className={styles.games__popover__subtitle}>
              Winnings type:
            </div>

            <div className={styles.games__popover__options}>
              <div
                className={clsx([
                  styles.games__popover__option,
                  !meta.win_currency && styles.selected
                ])}
                onClick={() => onFilterOptionClick(undefined)}
              >
                {!meta.win_currency ? (
                  <RadioCheckedIcon />
                ) : (
                  <RadioUncheckedIcon />
                )}
                All
              </div>
              <div
                className={clsx([
                  styles.games__popover__option,
                  meta.win_currency === 'CHT' && styles.selected
                ])}
                onClick={() => onFilterOptionClick('CHT')}
              >
                {meta.win_currency === 'CHT' ? (
                  <RadioCheckedIcon />
                ) : (
                  <RadioUncheckedIcon />
                )}
                Tokens
              </div>
              <div
                className={clsx([
                  styles.games__popover__option,
                  meta.win_currency === 'BG' && styles.selected
                ])}
                onClick={() => onFilterOptionClick('BG')}
              >
                {meta.win_currency === 'BG' ? (
                  <RadioCheckedIcon />
                ) : (
                  <RadioUncheckedIcon />
                )}
                BitGems
              </div>
            </div>
          </div>
        </Popover>
      </div>
    ),
    [setParams, meta.win_currency, anchorEl]
  );

  return (
    <Table
      loading={loading}
      rows={games.results}
      params={meta}
      rowsCount={games.count}
      columns={columns}
      action={getGamesTable}
      setParams={setParams}
      className={styles.games}
      tableTitle={customTitle}
      renderedFilters={renderedFilters}
      noRowsComponent={
        <NotFound
          className={styles.games__noGames}
          icon={<NoGamesIcon />}
          noTitle
        >
          <p>No game history yet...</p>
          <ButtonMUI href={mainPath.arena.path}>Start the game</ButtonMUI>
        </NotFound>
      }
    />
  );
};

GamesTable.propTypes = {
  customTitle: PropTypes.node
};

export default GamesTable;
