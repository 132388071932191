import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

import { ButtonMUI, NotFound, Table } from '../../shared';
import {
  getTransactionsOptions,
  getTransactionsTable,
  setGameTableMeta
} from './transactionsTableActions';
import { mainPath } from '../../routes/paths';
import DateRange from '../../shared/Calendar/DateRange';
import CopyAddress from '../../shared/CopyAddress';

import { ReactComponent as NoTransactionsIcon } from '../../assets/icons/no-transactions.svg';
import { ReactComponent as NoResultsByFilterIcon } from '../../assets/icons/no-results-by-filter.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as RadioCheckedIcon } from '../../assets/icons/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../assets/icons/radio-unchecked.svg';

import styles from './TransactionsTable.module.scss';

const columns = [
  {
    name: 'Name',
    width: 20,
    renderKey: ['icon', 'type_display'],
    render: (i) => {
      return (
        <div className={styles.transactions__row}>
          <img className={styles.icon} src={i.icon} alt='' />
          {i.type_display}
        </div>
      );
    }
  },
  {
    name: 'Date',
    value: 'created_at',
    width: 16,
    renderKey: 'created_at',
    render: (created_at) => {
      return (
        <div className={clsx([styles.transactions__row, styles.date])}>
          {dayjs.utc(created_at).local().format('MMM D, HH:mm')}
        </div>
      );
    }
  },
  {
    name: 'Amount',
    width: 16,
    renderKey: ['amount', 'currency', 'currency_display'],
    render: (i) => {
      return (
        <div className={styles.transactions__row}>
          {i.amount > 0 && '+'}
          {i.amount}&nbsp;{i.currency_display || i.currency}
        </div>
      );
    }
  },
  {
    name: 'To',
    width: 16,
    renderKey: 'to_data',
    render: (to_data) => {
      return (
        <div className={clsx([styles.transactions__row, styles.toData])}>
          <div className={styles.toSource}>{to_data.text || '-'}</div>
          {to_data.amount && (
            <div className={styles.toAmount}>
              {to_data.amount}&nbsp;{to_data.currency_display}
            </div>
          )}
          {to_data.address && (
            <CopyAddress
              address={to_data.address}
              containerStyle={styles.toAddress}
            />
          )}
        </div>
      );
    }
  },
  {
    name: 'From',
    width: 16,
    renderKey: 'from_data',
    render: (from_data) => {
      return (
        <div className={clsx([styles.transactions__row, styles.fromData])}>
          <div className={styles.fromSource}>{from_data.text || '-'}</div>
          {from_data.amount && (
            <div className={styles.fromAmount}>
              {from_data.amount}&nbsp;{from_data.currency_display}
            </div>
          )}
          {from_data.address && (
            <CopyAddress
              address={from_data.address}
              containerStyle={styles.fromAddress}
            />
          )}
        </div>
      );
    }
  },
  {
    name: 'Status',
    width: 16,
    renderKey: ['status', 'status_display'],
    render: (i) => {
      return (
        <div className={styles.transactions__row}>
          {i.status && <i className={styles[i.status.toLowerCase()]} />}
          {i.status_display}
        </div>
      );
    }
  }
];

const TransactionsTable = (props) => {
  const { customTitle } = props;
  const dispatch = useDispatch();
  const { transactions, loading, meta, options } = useSelector(
    ({ transactionsTable }) => transactionsTable
  );
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch(getTransactionsOptions());
  }, []);

  const openDateFilterPopover = (event) => {
    setDateFilterAnchorEl(event.currentTarget);
  };

  const closeDateFilterPopover = () => {
    setDateFilterAnchorEl(null);
  };

  const openFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const closeFilterPopover = () => {
    setFilterAnchorEl(null);
  };

  const setParams = useCallback(
    (params) => {
      dispatch(setGameTableMeta(params));
    },
    [dispatch]
  );

  const someFilterIsActive = useMemo(
    () => !!meta.type || meta.created_at_before,
    [
      meta.type,
      options.first_transaction_at,
      meta.created_at_after,
      meta.created_at_before
    ]
  );

  const onDateFilterChange = (startDate, endDate) => {
    setParams({
      ...meta,
      created_at_after: dayjs(startDate)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
      created_at_before: dayjs(endDate)
        .add(1, 'day')
        .subtract(1, 'second')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
    });
  };

  const onClearDateFilter = (e) => {
    e.stopPropagation();
    closeDateFilterPopover();
    setParams({
      ...meta,
      created_at_after: dayjs(options.first_transaction_at).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSSS'
      ),
      created_at_before: undefined
    });
  };

  const onFilterOptionClick = (newValue) => {
    closeFilterPopover();
    if (newValue === meta.type) return;
    setParams({
      ...meta,
      type: newValue
    });
  };

  const renderedFilters = useMemo(
    () => (
      <div className={styles.transactions__filters}>
        <div
          className={styles.transactions__dateFilter}
          onClick={openDateFilterPopover}
        >
          <CalendarIcon />
          {/*<span>Not 25 - Nov 30</span>*/}
          <span>
            {dayjs.utc(meta.created_at_after).local().format('MMM D')} -{' '}
            {dayjs.utc(meta.created_at_before).local().format('MMM D')}
          </span>

          {meta.created_at_before && <p onClick={onClearDateFilter}>Clear</p>}
        </div>

        <Popover
          id={Boolean(dateFilterAnchorEl) ? 'simple-popover' : undefined}
          open={Boolean(dateFilterAnchorEl)}
          anchorEl={dateFilterAnchorEl}
          onClose={closeDateFilterPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{
            paper: styles.transactions__popover
          }}
        >
          <div>
            <DateRange
              defaultRange={[
                {
                  startDate: new Date(dayjs.utc(meta.created_at_after).local()),
                  endDate: meta.created_at_before
                    ? new Date(dayjs.utc(meta.created_at_before).local())
                    : new Date(),
                  key: 'selection'
                }
              ]}
              onChange={onDateFilterChange}
            />
          </div>
        </Popover>

        <div
          className={clsx([
            styles.transactions__filter,
            !!meta.type && styles.transactions__filter__active
          ])}
          onClick={openFilterPopover}
        >
          <FilterIcon />
          {!!meta.type && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='8'
              viewBox='0 0 8 8'
              fill='none'
              className={styles.transactions__filter__dot}
            >
              <circle cx='4' cy='4' r='4' fill='#F74A4A' />
            </svg>
          )}
        </div>

        <Popover
          id={Boolean(filterAnchorEl) ? 'simple-popover' : undefined}
          open={Boolean(filterAnchorEl)}
          anchorEl={filterAnchorEl}
          onClose={closeFilterPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{
            paper: styles.transactions__filterPopover
          }}
        >
          <div>
            <div className={styles.transactions__filterPopover__options}>
              <div
                className={clsx([
                  styles.transactions__filterPopover__option,
                  !meta.type && styles.selected
                ])}
                onClick={() => onFilterOptionClick(undefined)}
              >
                {!meta.type ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                All
              </div>
              {options.statuses.map((s) => (
                <div
                  className={clsx([
                    styles.transactions__filterPopover__option,
                    meta.type === s.type && styles.selected
                  ])}
                  onClick={() => onFilterOptionClick(s.type)}
                >
                  {meta.type === s.type ? (
                    <RadioCheckedIcon />
                  ) : (
                    <RadioUncheckedIcon />
                  )}
                  <img src={s.icon} alt={s.type} />
                  {s.name}
                </div>
              ))}
            </div>
          </div>
        </Popover>
      </div>
    ),
    [
      setParams,
      meta.type,
      dateFilterAnchorEl,
      filterAnchorEl,
      meta.created_at_after,
      meta.created_at_before
    ]
  );

  if (!options.statuses.length) return null;

  return (
    <Table
      loading={loading}
      rows={transactions.results}
      params={meta}
      rowsCount={transactions.count}
      columns={columns}
      action={getTransactionsTable}
      setParams={setParams}
      className={styles.transactions}
      tableTitle={customTitle}
      renderedFilters={renderedFilters}
      noRowsComponent={
        someFilterIsActive ? (
          <NotFound
            className={styles.transactions__noGames}
            icon={<NoResultsByFilterIcon />}
            noTitle
          >
            <p>No results by filter...</p>
          </NotFound>
        ) : (
          <NotFound
            className={styles.transactions__noGames}
            icon={<NoTransactionsIcon />}
            noTitle
          >
            <p>No transactions yet...</p>
            <ButtonMUI href={mainPath.market.path}>Buy BitGems</ButtonMUI>
          </NotFound>
        )
      }
    />
  );
};

TransactionsTable.propTypes = {
  customTitle: PropTypes.node
};

export default TransactionsTable;
