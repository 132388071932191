import { ARENA } from '../Arena/storage/arenaActionTypes';
import { AUTH } from './authActionTypes';
import { MARKET } from '../Market/marketActionTypes';
import { WALLETS } from '../Wallets/walletsActionTypes';

const INITIAL_STATE = {
  authData: {
    username: '',
    crypto_address: '',
    challenge: '',
    signature: ''
  },
  profileInfo: {},
  tempProfileInfo: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH.SET_AUTH_DATA:
      return {
        ...state,
        authData: {
          ...state.authData,
          ...action?.payload
        }
      };
    case AUTH.GET_PROFILE_INFO_SUCCESS:
      if (action.payload.data.tempStatus) { // if balance has changed while client play on arena
        return {
          ...state,
          tempProfileInfo: { ...state.profileInfo, ...action.payload.data }
        }
      } else {
        return {
          ...state,
          profileInfo: { ...state.profileInfo, ...action.payload.data }
        };
      }
    case AUTH.SET_TEMP_PROFILE_PERMANENT:
      return {
        ...state,
        profileInfo: { ...state.tempProfileInfo },
        tempProfileInfo: {}
      };
    case ARENA.JOIN_ROOM_SUCCESS:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          ...action.payload.config.additionalParams
        }
      };
    /* case ARENA.JOIN_ROOM_FAIL: // rewrite modal status, even customer have no money to start a game
      return { ...state, profileInfo: { ...state.profileInfo, ...action.meta.previousAction.payload.request.data } } */
    // case AUTH.EMAIL_VER_FAIL:
    //   return {...state, emailVerError: action?.error?.response?.data?.security_token};
    //
    // case AUTH.USER_INFO_SUCCESS:
    //   return {...state, userInfo: action?.payload?.data};

    case MARKET.GET_BITGEMS_PACKAGES_SUCCESS:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          balance_cht: action.payload.data.cht_balance
        }
      };
    case WALLETS.GET_WALLETS_INFO_SUCCESS:

      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          balance_cht: action.payload.data.cht_balance,
          balance_bitgems: action.payload.data.bitgems_balance
        }
      }
    default:
      return state;
  }
}
