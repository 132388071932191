// You need to import the function into rootReducer.js
import { MARKET } from './marketActionTypes';

const INITIAL_STATE = {
  /**
   * Bitgem packages
   * @type {Object}
   * @property {number} min_bitgems_to_buy
   * @property {number} max_bitgems_to_buy
   * @property {number} bitgem_usd_rate
   * @property {number} cht_balance
   * @property {number} cht_min_amount_to_sell
   * @property {Array} packages
   * @property {number} packages.id
   * @property {number} packages.amount
   * @property {number} packages.cht_amount
   * @property {string} packages.type
   * @property {string} packages.label
   * @property {Array} coins
   * @property {number} coins.id
   * @property {string} coins.code
   * @property {string} coins.name
   * @property {string} coins.logo
   */
  bitgem_packages: {},

  selectedCoin: null,

  /**
   * @type {Object}
   * @description Coin rate data
   * @property {Object} buy
   * @property {number} buy.coin_min_amount
   * @property {number} buy.cht_rate
   * @property {number} buy.bitgems_rate
   * @property {number} buy.cht_min_amount
   * @property {number} buy.bitgems_min_amount
   * @property {number} buy.bitgems_max_amount
   * @property {number} buy.usd_rate
   * @property {Object} sell
   * @property {number} sell.coin_min_amount
   * @property {number} sell.cht_rate
   * @property {number} sell.bitgems_rate
   * @property {number} sell.cht_min_amount
   * @property {number} sell.bitgems_min_amount
   * @property {number} sell.bitgems_max_amount
   * @property {number} sell.usd_rate
   */
  coinRate: {},
  fetchingRate: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARKET.GET_BITGEMS_PACKAGES_SUCCESS:
      const defaultCoinCode = 'USDTERC20';
      const USDTERC20Index = action.payload.data.coins.findIndex(
        (coin) => coin.code === defaultCoinCode
      );

      if (USDTERC20Index === -1) {
        return {
          ...state,
          bitgem_packages: action.payload.data,
          selectedCoin: action.payload.data.coins[0].code
        };
      }

      if (USDTERC20Index >= 4) {
        const newCoins = [...action.payload.data.coins];
        const newCoin = newCoins.splice(USDTERC20Index, 1);
        newCoins.splice(3, 0, newCoin[0]);
        return {
          ...state,
          selectedCoin: defaultCoinCode,
          bitgem_packages: {
            ...action.payload.data,
            coins: newCoins
          }
        };
      }

      return {
        ...state,
        bitgem_packages: action.payload.data,
        selectedCoin: defaultCoinCode
      };
    case MARKET.GET_COIN_RATE:
      return { ...state, fetchingRate: true };
    case MARKET.GET_COIN_RATE_SUCCESS:
      return { ...state, coinRate: action.payload.data, fetchingRate: false };
    case MARKET.GET_COIN_RATE_FAIL:
      return { ...state, fetchingRate: false };
    case MARKET.SET_SELECTED_COIN:
      const newCoinIndex = state.bitgem_packages.coins.findIndex(
        (coin) => coin.code === action.coinCode
      );
      /**
       * If the new coin index is greater than 4,
       * we need to move it to be the fourth element in the array
       * to show it outside the popover
       */
      if (newCoinIndex >= 4) {
        const newCoins = [...state.bitgem_packages.coins];
        const newCoin = newCoins.splice(newCoinIndex, 1);
        newCoins.splice(3, 0, newCoin[0]);
        return {
          ...state,
          selectedCoin: action.coinCode,
          bitgem_packages: {
            ...state.bitgem_packages,
            coins: newCoins
          }
        };
      }
      return {
        ...state,
        selectedCoin: action.coinCode
      };
    default:
      return state;
  }
}
