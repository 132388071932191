import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WheelIcon } from '../../assets/icons/wheel.svg';
import { ReactComponent as RockIcon } from '../../assets/icons/rock.svg';

import styles from './WheelLoader.module.scss';

const WheelLoader = ({ className = '', size = 120 }) => (
  <div
    className={`${styles.loader} ${className}`}
    style={{ width: size, height: size }}
  >
    <WheelIcon className={styles.wheel} />
    <RockIcon className={styles.rock} />
  </div>
);

WheelLoader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number
};

export default WheelLoader;
