import React, { Fragment, useEffect, useState } from 'react';
import styles from './Arena.module.scss';
import { ReactComponent as One } from './CountDownAssets/1.svg'
import { ReactComponent as Two } from './CountDownAssets/2.svg'
import { ReactComponent as Three } from './CountDownAssets/3.svg'
import { ReactComponent as Four } from './CountDownAssets/4.svg'
import { ReactComponent as Five } from './CountDownAssets/5.svg'

const CountDown = ({ onAnimateEnd }) => {

  useEffect(() => {
    console.log(document.getElementById('CountDownWrapper').querySelectorAll('svg'));
    [...document.getElementById('CountDownWrapper').querySelectorAll('svg')].forEach((el, idx) => {
      setTimeout(() => {
        el.classList.add(styles.visible)
        setTimeout(() => {
          el.classList.add(styles.animate)
        }, 500);
        if (idx === icons.length - 1) {
          setTimeout(() => {
            onAnimateEnd()
          }, 1000);
        }
      }, idx * 1000);
    })
  }, [])

  return <div id={'CountDownWrapper'} className={styles.countDown}>
    {icons.map((el, idx) => <Fragment key={idx}>{el}</Fragment>)}
    <h1>
      All players are in place!<br />
      Start in...
    </h1>
  </div>
};

const icons = [<One />, <Two />, <Three />, <Four />, <Five />].reverse()

export default CountDown;
