export const TRANSACTIONS_TABLE = {
  GET_TRANSACTIONS_TABLE: 'GET_TRANSACTIONS_TABLE',
  GET_TRANSACTIONS_TABLE_SUCCESS: 'GET_TRANSACTIONS_TABLE_SUCCESS',
  GET_TRANSACTIONS_TABLE_FAIL: 'GET_TRANSACTIONS_TABLE_FAIL',

  GET_TRANSACTIONS_OPTIONS: 'GET_TRANSACTIONS_OPTIONS',
  GET_TRANSACTIONS_OPTIONS_SUCCESS: 'GET_TRANSACTIONS_OPTIONS_SUCCESS',
  GET_TRANSACTIONS_OPTIONS_FAIL: 'GET_TRANSACTIONS_OPTIONS_FAIL',

  SET_TRANSACTIONS_TABLE_META: 'SET_TRANSACTIONS_TABLE_META',
};
