import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDisconnect } from 'wagmi';
import { authPath, mainPath } from '../../routes/paths';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';
import { ReactComponent as BitIcon } from '../../assets/icons/Bit_icon.svg';
import { ReactComponent as VolumeOff } from '../../assets/icons/volume off.svg';
import { ReactComponent as VolumeOn } from '../../assets/icons/vollume on.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/profile_user_icon.svg';
import { getProfileInfo, postLogout } from '../../features/Auth/authActions';
import { ButtonMUI } from '../../shared';
import styles from './Header.module.scss';
import { classList } from '../../helpers/functions';
import { ClickAwayListener } from '@mui/base';
import CryptoBalance from '../../shared/CryptoBalance';
import { clickSound, setMusicStatus, setMusicVolume } from '../../app/appActions';
import WinnerModal from '../../features/Arena/WinnerModal';
import { getRoomInner, setRoomResults } from '../../features/Arena/storage/arenaActions';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';


const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profileInfo, musicStatus, roomStatus, musicVolume } =
    useSelector(({ auth, arena, app }) => ({ ...auth, ...arena, ...app }));
  const { isSuccess } = useDisconnect();
  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);
  const [winnerModalStatus, setWinnerModalStatus] = useState(false);

  let isRoom = history.location.pathname.includes('/main/arena/room/'),
    isRoomWaiting = roomStatus === 'waiting';
  const isActiveLink = isRoom && isRoomWaiting ? mainPath.waitingRoom.path.replace(':roomID', profileInfo.current_room) : false;

  const handleClick = () => {
    setOpenProfileDropdown((prev) => !prev);
    dispatch(clickSound())
  };

  const handleClickAway = () => {
    setOpenProfileDropdown(false);
    openProfileDropdown && dispatch(clickSound())
  };

  const logout = () => {
    dispatch(postLogout()).then((res) => {
      if (res?.payload?.data?.message) {
        localStorage.removeItem('token');
        history.push(authPath.signIn.path);
      }
    });
  };

  useEffect(() => {
    isSuccess && logout();
  }, [isSuccess]);

  useEffect(() => {
    dispatch(getProfileInfo()).then(res => {
      if (res.type.includes('_SUCCESS') && !!res.payload.data.current_room && !history.location.pathname.includes(`/main/arena/room/`)) {
        dispatch(getRoomInner(res.payload.data.current_room)).then(roomResponse => {
          if (roomResponse.type.includes('_SUCCESS')) {
            if (!!roomResponse.payload.data.closed_at) {
              dispatch(setRoomResults(roomResponse.payload.data))
              setWinnerModalStatus(true)
            }
            else history.push(mainPath.waitingRoom.path.replace(':roomID', roomResponse.payload.data.id));
          }
        })
      }
    })
  }, []);

  const toggleMusic = () => {
    dispatch(setMusicStatus(!musicStatus))
  }

  return (
    <header className={styles.root}>
      <div className={classList(styles.container, styles.header)}>
        <div className={styles.headerButtons}>
          <ButtonMUI
            // className='mb-12'
            href={mainPath.myAccount.path}
            variant='outlined'
            className={classList(styles.myAccountButton, { 'disable': !!isActiveLink })}
          >
            My account
          </ButtonMUI>
          {/* <a className={styles.linkToLanding} href='https://google.com' target='_blank'>Public page</a> */}
        </div>
        <div className={styles.headerInfo}>
          <div className={classList(styles.balance, styles.balance_cht)}>
            <VCHTIcon />
            <div>
              <p>{profileInfo.balance_cht ?? '...'}</p>
              <span>vCHT token</span>
            </div>
          </div>
          <div className={classList(styles.balance, styles.balance_bit)}>
            <BitIcon />
            <div>
              <p>{profileInfo.balance_bitgems ?? '...'}</p>
              <span>BitGems</span>
            </div>
          </div>
          <div className={styles.volumeWrapper}>
            {musicStatus ? <VolumeOn id='soundToggler' onClick={toggleMusic} /> : <VolumeOff id='soundToggler' onClick={toggleMusic} />}
            <div className={styles.volumeSliderWrapper}>
              <div className={styles.volumeSliderWrapperInner}>
                <VolumeDown />
                <Slider aria-label="Volume" min={0} max={0.35} step={0.025} value={musicVolume} onChange={(e, newVal) => dispatch(setMusicVolume(newVal))} />
                <VolumeUp />
              </div>
            </div>
          </div>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classList(styles.profileDropdown, { [styles.open]: openProfileDropdown })}>
              <button type="button" className={classList(styles.profileDropdown__button, { [styles.open]: openProfileDropdown })} onClick={handleClick}>
                <img src={profileInfo.avatar} alt="avatar" />
                <ArrowDown />
              </button>
              {openProfileDropdown ? (
                <div className={styles.profileDropdown__content}>
                  <Link to={mainPath.myAccount.path} onClick={handleClickAway} className={classList({ 'disable': !!isActiveLink })}>
                    <UserIcon />
                    Account
                  </Link>
                  <hr />
                  <CryptoBalance />
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
          <WinnerModal
            open={winnerModalStatus}
            onClose={() => setWinnerModalStatus(false)}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
