// You need to change the path and import the functions into rootSaga.js
import { takeEvery } from 'redux-saga/effects';
import { openErrorSnack, openSuccessSnack } from '../../app/rootSaga';

export function* handlePaymentModalsSnacks() {
  yield takeEvery('POST_CONVERT_BITGEMS_TO_VCHT_SUCCESS', () =>
    openSuccessSnack('Operation successful')
  );
  yield takeEvery('POST_CONVERT_CHT_TO_VCHT_SUCCESS', () =>
    openSuccessSnack('Operation successful')
  );
  yield takeEvery('POST_CONVERT_VCHT_TO_CHT_SUCCESS', () =>
    openSuccessSnack('Operation successful')
  );
  yield takeEvery('POST_CONVERT_VCHT_TO_BG_SUCCESS', () =>
    openSuccessSnack('Operation successful')
  );

  yield takeEvery('POST_CREATE_BITGEMS_INVOICE_FAIL', openErrorSnack);
  yield takeEvery('POST_CREATE_VCHT_INVOICE_FAIL', openErrorSnack);
  yield takeEvery('POST_CONVERT_BITGEMS_TO_VCHT_FAIL', openErrorSnack);
  yield takeEvery('POST_CONVERT_CHT_TO_VCHT_FAIL', openErrorSnack);
  yield takeEvery('POST_CONVERT_VCHT_TO_CHT_FAIL', openErrorSnack);
  yield takeEvery('POST_CONVERT_VCHT_TO_BG_FAIL', openErrorSnack);
}
