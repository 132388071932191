// You need to import the function into rootReducer.js
import { GAMES_TABLE } from './gamesTableActionTypes';

const INITIAL_STATE = {
  /**
   * Games table data
   * @type {Object}
   * @property {Number} count - Total number of games
   * @property {Number} page - Current page
   * @property {Number} pages - Total number of pages
   * @property {Number} page_size - Number of games per page
   * @property {String} ordering - Ordering of the games
   * @property {String} next - Next page URL
   * @property {String} previous - Previous page URL
   * @property {Array} results - Array of games
   * @property {Number} results.id - Game ID
   * @property {Number} results.room_id - Room ID
   * @property {String} results.date - Date of the game
   * @property {String} results.room_icon - Room icon URL
   * @property {String} results.room_name - Room name
   * @property {Number} results.bet - Bet amount
   * @property {Number} results.room_currency - Bet amount
   * @property {Number} results.turn - Turn number
   * @property {Boolean} results.is_bg_winner - Is BitGems winner
   * @property {Number} results.win_amount - Win amount
   * @property {String} results.win_currency - Win currency code
   * @property {String} results.win_currency_display - Win currency display name
   */
  games: {
    results: []
  },
  loading: false,
  meta: {
    page_size: 10,
    ordering: null // date | -date | room_name | -room_name
  }
};

export default function (state = INITIAL_STATE, { payload, type, meta }) {
  switch (type) {
    case GAMES_TABLE.GET_GAMES_TABLE:
      return {
        ...state,
        loading: true
      };
    case GAMES_TABLE.GET_GAMES_TABLE_SUCCESS:
      return {
        ...state,
        games: payload.data,
        loading: false
      };
    case GAMES_TABLE.GET_GAMES_TABLE_FAIL:
      return {
        ...state,
        loading: false
      };

    case GAMES_TABLE.SET_GAME_TABLE_META:
      return {
        ...state,
        meta
      };
    default:
      return state;
  }
}
