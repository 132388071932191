import React, { useEffect, useState } from 'react';
import styles from './Arena.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from "lottie-react";
import { classList } from '../../helpers/functions';
import { ReactComponent as CHTIcon } from '../../assets/icons/vcht_switcher.svg';
import { ReactComponent as BitIcon } from '../../assets/icons/Bit_icon.svg';
import rays from '../../assets/images/rays-overlay.webp';
import { ButtonMUI } from '../../shared';
import { patchProfileInfo, setProfileInfo, setTempProfilePermanent } from '../Auth/authActions';
import { joinRoom, setRoomStatus } from './storage/arenaActions';
import { useHistory } from 'react-router-dom';
import { mainPath } from '../../routes/paths';
import WinnerModal from './WinnerModal';
import { preOpenChestSoundAction, setCustomSound } from '../../app/appActions';
import regularChestsOpeningSound from '../../assets/sound/Regular Chests Opening.mp3'
import playerWinsBitGemsSound from '../../assets/sound/Player Wins BitGems.mp3'
import tenChestOpeningSound from '../../assets/sound/Medieval Epic Fanfare.mp3'

const RoomResults = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roomResults, roomStatus } = useSelector(({ arena }) => arena)
  const [winnersSet, setWinnersSet] = useState([])
  const [buttonsStatus, setButtonsStatus] = useState(false)
  const [winnerModalStatus, setWinnerModalStatus] = useState(false);
  const lastWinner = winnersSet[winnersSet.length - 1]
  const winnersCount = !!lastWinner?.win_currency_display
    ? winnersSet?.length
    : winnersSet.length > 9 ? 9 : winnersSet.length // it`s for token game, where are only 9 winners
  const firstLink = '' + history.location.pathname

  useEffect(() => {
    roomResults.participants.forEach((el, idx) => {
      setTimeout(() => {
        if (history.location.pathname === firstLink && roomResults.participants.length - 1 === idx) {
          setWinnersSet(prev => ([...prev, el]));
          [...document.querySelectorAll(`.${styles.winners}`)][[...document.querySelectorAll(`.${styles.winners}`)].length - 1].classList.remove(styles.secret);
          dispatch(setCustomSound(tenChestOpeningSound));
          if (el.is_me) {
            [...document.querySelectorAll(`.${styles.winners}`)][[...document.querySelectorAll(`.${styles.winners}`)].length - 1].classList.add(styles.me);
            dispatch(setTempProfilePermanent())
          }
          setWinnerModalStatus(true);
        } else if (history.location.pathname === firstLink) {
          setWinnersSet(prev => ([...prev, el]))
          dispatch(preOpenChestSoundAction());

          setTimeout(() => {
            if (history.location.pathname === firstLink) {
              document.querySelector(`.${styles.waitingRoomParticipantsResult} > div`).classList.remove(styles.hide)
              document.querySelector(`.${styles.rays}`).classList.remove(styles.hide)
            }
          }, 0);
          setTimeout(() => {
            if (el.is_me) dispatch(setCustomSound(playerWinsBitGemsSound, true))
            else dispatch(setCustomSound(regularChestsOpeningSound + `?v=${idx + 1}`, true))
          }, 1500)
          setTimeout(() => {
            if (history.location.pathname === firstLink) {
              document.querySelector(`.${styles.waitingRoomParticipantsResult} > img:not(.${styles.rays})`).classList.add(styles.animate);
              document.querySelector(`.${styles.winAmount}`).classList.add(styles.animate);
              [...document.querySelectorAll(`.${styles.winners}`)][[...document.querySelectorAll(`.${styles.winners}`)].length - 1].classList.remove(styles.secret);
              if (!!el.extra_random_win_message) document.querySelector(`.${styles.waitingRoomWinMessage}`).classList.add(styles.visible)
              if (el.is_me) {
                document.querySelector(`.${styles.waitingRoomParticipantsResult} > h1`).classList.add(styles.visible);
                [...document.querySelectorAll(`.${styles.winners}`)][[...document.querySelectorAll(`.${styles.winners}`)].length - 1].classList.add(styles.me)
                setButtonsStatus(true)
                dispatch(patchProfileInfo({ seen_result: true }))
                dispatch(setProfileInfo({ current_room: null }))
                dispatch(setTempProfilePermanent())
              }
            }
          }, 2000);
          setTimeout(() => {
            if (history.location.pathname === firstLink) {
              document.querySelector(`.${styles.waitingRoomParticipantsResult} > img:not(.${styles.rays})`).classList.remove(styles.animate)
              document.querySelector(`.${styles.winAmount}`).classList.remove(styles.animate);
              document.querySelector(`.${styles.waitingRoomParticipantsResult} > div`).classList.add(styles.hide)
              document.querySelector(`.${styles.rays}`).classList.add(styles.hide)
              document.querySelector(`.${styles.waitingRoomParticipantsResult} > h1`).classList.remove(styles.visible);
              document.querySelector(`.${styles.waitingRoomWinMessage}`).classList.remove(styles.visible)
            }
          }, 4950);
        }
      }, idx * 5250);
    })
  }, [])

  const join = () => {
    dispatch(joinRoom(roomResults.type)).then(res => {
      if (res.type.includes('_SUCCESS')) {
        dispatch(setProfileInfo({ current_room: res.payload.data.id }))
        dispatch(setRoomStatus('waiting'))
        history.push(mainPath.waitingRoom.path.replace(':roomID', res.payload.data.id))
      }
    })
  }

  const leave = () => {
    dispatch(setRoomStatus('waiting'))
    history.push(mainPath.arena.path)
  }

  return <><div className={styles.arenaOverlay2} />
    <div className={styles.waitingRoomContainer}>
      <div className={styles.waitingRoomHeading}>
        <div className={styles.users}>
          <p>{!!winnersCount && `${winnersCount} winner${winnersCount > 1 ? 's' : ''}`}</p>
          {winnersSet.map((el, idx) => <div key={idx} className={classList(styles.winners, styles.secret, { [styles.looser]: !el.win_currency })}>
            <img src={el.avatar} alt={el.username} />
            {el.win_currency === null
              ? null
              : el.win_currency === 'CHT' ? <CHTIcon /> : <BitIcon />}
            <div className={styles.userName}>
              {el.is_me && !!el.win_currency && <span>You won</span>}
              <p>{el.is_me && !!el.win_currency ? `${el.win_amount} ${el.win_currency_display}` : el.username}</p>
            </div>
          </div>)}
        </div>
      </div>
      <div className={classList(styles.waitingRoomParticipants, styles.roomResults)}>
        <div className={styles.waitingRoomParticipantsResult}>
          <img className={classList(styles.rays, styles.hide)} src={rays} alt="rays" />
          <Lottie className={styles.hide} key={!!lastWinner && !!lastWinner.user_id && lastWinner.user_id} animationData={require(`../../assets/chests_animations/${roomResults.room_name}/chest.json`)} loop={false} />
          {!!lastWinner && !!lastWinner.avatar && <img src={lastWinner.avatar} alt={lastWinner.username} />}
          {!!lastWinner && !!lastWinner.win_amount && <div className={styles.winAmount}>{lastWinner.win_amount}<div>{lastWinner.win_currency_display}</div></div>}
          <h1>You win!</h1>
        </div>
      </div>
      <div className={styles.waitingRoomWinMessage}>
        {!!lastWinner && !!lastWinner.extra_random_win_message && lastWinner.extra_random_win_message}
      </div>
      {!!buttonsStatus && (
        <div className={styles.waitingRoomActionButtons}>
          <ButtonMUI
            variant={'text'}
            onClick={leave}
          >
            Leave
          </ButtonMUI>
          <ButtonMUI
            onClick={join}
          >
            Play again
          </ButtonMUI>
        </div>
      )}
    </div>
    <WinnerModal
      open={winnerModalStatus}
      onClose={() => setWinnerModalStatus(false)}
    />
  </>
};

export default RoomResults;
