import styles from './ComingSoon.module.scss';
import {ReactComponent as ComingSoonIcon} from '../../assets/icons/coming_soon.svg';

const ComingSoon = ({name}) => (
  <main className={`${styles.comingSoon} page-wrap`}>
    <div className={`${styles.comingSoonInner} container`}>
      <h1>{name}</h1>
      <div className='inner-wrap'>
        <ComingSoonIcon />
        <h2>Coming soon...</h2>
      </div>
    </div>
  </main>
);

export default ComingSoon;
