import React, { useEffect, useState } from 'react';
import styles from './Arena.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRooms, joinRoom, selectRoom, setLowBalanceModalStatus } from './storage/arenaActions';
import { classList, prettyScroll } from '../../helpers/functions';
import { ReactComponent as LeaveLeft } from './LowBalanceModalAssets/leaves1.svg';
import { ReactComponent as LeaveRight } from './LowBalanceModalAssets/leaves2.svg';
import roof from './LowBalanceModalAssets/roof.png';
import { ReactComponent as TitleTable } from './LowBalanceModalAssets/title-table.svg';
import { ReactComponent as FlagIcon } from './LowBalanceModalAssets/not enough flag.svg';
import { ReactComponent as CHTIcon } from '../../assets/icons/vcht_switcher.svg';
import { ReactComponent as BitIcon } from '../../assets/icons/Bit_icon.svg';
import { ButtonMUI, DialogMUI } from '../../shared';
import BitgemPacks from '../Market/BitgemPacks';

const LowBalanceModal = () => {
  const dispatch = useDispatch();
  const { selectedRoom, lowBalanceModalStatus, profileInfo, isCHT } = useSelector(({ arena, auth }) => ({ ...arena, ...auth }))

  return <DialogMUI
    open={lowBalanceModalStatus}
    onClose={() => dispatch(setLowBalanceModalStatus(false))}
    withoutPaddings
    exitBtn={false}
  >
    <div className={styles.LowBalanceModal}>
      <LeaveLeft className={styles.LeaveRight} />
      <LeaveRight className={styles.LeaveLeft} />
      <img src={roof} className={styles.Roof} />
      <TitleTable className={styles.TitleTable} />
      <div className={styles.LowBalanceModalWrapper} ref={prettyScroll}>
        <div className={styles.LowBalanceModalInner}>
          <h1>Not enough {isCHT ? 'vCHT' : 'BitGems'}</h1>
          <div className={styles.balance}>
            <FlagIcon />
            <div>
              <div>
                <BitIcon />
                <div>
                  <span>BitGems</span>
                  <p>{profileInfo.balance_bitgems}</p>
                </div>
              </div>
              <div className={styles.separator} />
              <div>
                <CHTIcon />
                <div>
                  <span>Tokens</span>
                  <p>{profileInfo.balance_cht}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.desc}>
            <p>This is the price of participation in the game. {isCHT ? 'vCHT' : 'BitGems'} will be deducted from your account.</p>
            <ButtonMUI
              variant='transparent'
              onClick={() => dispatch(setLowBalanceModalStatus(false))}
            >
              Go back
            </ButtonMUI>
          </div>
        </div>
        {!isCHT && <BitgemPacks isFull={false} />}
      </div>
    </div>
  </DialogMUI>
};

export default LowBalanceModal;
