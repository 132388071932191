import React from 'react'
import styles from './LandingGems.module.scss'
import image from './images/gems_bg.png'
import imageMob from './images/gems_bg_mob.jpg'

const LandingGems = () => {
  return (
    <div className={styles.root}>
        <img className={styles.desktop} src={image} alt="Gems sale" />
        <img className={styles.mobile} src={imageMob} alt="Gems sale" />
    </div>
  )
}

export default LandingGems