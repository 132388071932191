import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { BaseError, ContractFunctionRevertedError } from 'viem';

import {
  getConvertOptions,
  setPaymentModalMeta,
  resetPaymentModalMeta,
  postConvertCHTtoVCHT,
  postConvertVCHTtoCHT,
  postConvertVCHTtoBG,
  resetConvertOptions
} from './paymentModalsActions';
import { toggleCurrentPageRefetch } from '../../app/appActions';
import { ButtonMUI, DialogMUI } from '../../shared';
import PaymentDiscretion from './components/PaymentDiscretion';
import WagmiTransfer from './components/WagmiTransfer';
import CoinsSelect from './components/CoinsSelect';
import { notifyError } from '../../helpers/notifySnack';
import { formatPrice, renderLoadingPrice } from '../../helpers/functions';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange-rate.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as BitgemIcon } from '../../assets/icons/bitgem.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';
import { ReactComponent as CHTIcon } from '../../assets/icons/cht.svg';

import styles from './styles.module.scss';

const ConvertCHTModal = () => {
  const {
    convertOptions,
    meta: { youPay, youGet, selectedCoin, initialConvertTo }
  } = useSelector(({ paymentModals, app }) => ({ ...paymentModals, ...app }));
  const dispatch = useDispatch();
  const [isTransferInProgress, setTransferInProgress] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [convertTo, setConvertTo] = useState(!!initialConvertTo ? initialConvertTo : 'CHT');

  const {
    noConvertOptions,
    rateCHTtoVCHT,
    minCHTtoVCHTtoConvert,
    maxCHTtoVCHTtoConvert,
    rateVCHTtoCHT,
    minVCHTtoCHTtoConvert,
    maxVCHTtoCHTtoConvert,
    rateVCHTtoBG,
    minVCHTtoBGtoConvert,
    maxVCHTtoBGtoConvert
  } = useMemo(() => {
    return {
      noConvertOptions: _isEmpty(convertOptions),
      rateCHTtoVCHT: convertOptions.rates?.CHT.VCHT.rate,
      minCHTtoVCHTtoConvert: convertOptions.rates?.CHT.VCHT.min_amount,
      maxCHTtoVCHTtoConvert: convertOptions.rates?.CHT.VCHT.max_amount,
      rateVCHTtoCHT: convertOptions.rates?.VCHT.CHT.rate,
      minVCHTtoCHTtoConvert: convertOptions.rates?.VCHT.CHT.min_amount,
      maxVCHTtoCHTtoConvert: convertOptions.rates?.VCHT.CHT.max_amount,
      rateVCHTtoBG: convertOptions.rates?.VCHT.BG.rate,
      minVCHTtoBGtoConvert: convertOptions.rates?.VCHT.BG.min_amount,
      maxVCHTtoBGtoConvert: convertOptions.rates?.VCHT.BG.max_amount
    };
  }, [convertOptions]);

  const { rate, minAmount, maxAmount } = useMemo(() => {
    return {
      rate:
        selectedCoin === 'CHT'
          ? rateCHTtoVCHT
          : convertTo === 'CHT'
            ? rateVCHTtoCHT
            : rateVCHTtoBG,
      minAmount:
        selectedCoin === 'CHT'
          ? minCHTtoVCHTtoConvert
          : convertTo === 'CHT'
            ? minVCHTtoCHTtoConvert
            : minVCHTtoBGtoConvert,
      maxAmount:
        selectedCoin === 'CHT'
          ? maxCHTtoVCHTtoConvert
          : convertTo === 'CHT'
            ? maxVCHTtoCHTtoConvert
            : maxVCHTtoBGtoConvert
    };
  }, [
    selectedCoin,
    convertTo,
    rateCHTtoVCHT,
    minCHTtoVCHTtoConvert,
    maxCHTtoVCHTtoConvert,
    rateVCHTtoCHT,
    minVCHTtoCHTtoConvert,
    maxVCHTtoCHTtoConvert,
    rateVCHTtoBG,
    minVCHTtoBGtoConvert,
    maxVCHTtoBGtoConvert
  ]);

  useEffect(() => {
    dispatch(getConvertOptions());
  }, [noConvertOptions]);

  useEffect(
    () => () => {
      dispatch(resetConvertOptions());
    },
    []
  );

  useEffect(() => {
    if (rate && minAmount) {
      dispatch(
        setPaymentModalMeta({
          youPay: minAmount,
          youGet: formatPrice(minAmount * rate)
        })
      );
    }
  }, [selectedCoin, convertTo, rate, minAmount]);

  const isButtonDisabled = useMemo(() => {
    return (
      !youPay ||
      !youGet ||
      noConvertOptions ||
      submitting ||
      youPay < minAmount ||
      youPay > maxAmount ||
      (selectedCoin === 'vCHT' && convertTo === 'BitGems' && youGet % 1 !== 0)
    );
  }, [
    youPay,
    youGet,
    convertOptions,
    submitting,
    minAmount,
    maxAmount,
    selectedCoin,
    convertTo
  ]);

  const isFixTheAmountDisabled = useMemo(() => {
    return youGet % 1 === 0;
  }, [youGet, minAmount, maxAmount]);

  const onClose = () => {
    dispatch(resetPaymentModalMeta());
  };

  const onYouPayChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: value,
        youGet: formatPrice(value * rate)
      })
    );
  };

  const onYouGetChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: formatPrice(value / rate),
        youGet: value
      })
    );
  };

  const onFixAmountClick = () => {
    if (youGet % 1 === 0) return;

    const amount = youGet - (youGet % 1);
    onYouGetChange(amount || 1);
  };

  const onConvertClick = () => {
    if (selectedCoin === 'CHT') {
      setTransferInProgress(true);
      return;
    }

    setSubmitting(true);

    if (convertTo === 'CHT') {
      dispatch(postConvertVCHTtoCHT({ amount: youPay })).then((res) => {
        setSubmitting(false);
        if (res.type.includes('_SUCCESS')) {
          dispatch(toggleCurrentPageRefetch());
          onClose();
        }
      });
    } else {
      dispatch(postConvertVCHTtoBG({ amount: youPay })).then((res) => {
        setSubmitting(false);
        if (res.type.includes('_SUCCESS')) {
          dispatch(toggleCurrentPageRefetch());
          onClose();
        }
      });
    }
  };

  const onTransferSuccess = (transaction_hex) => {
    dispatch(
      postConvertCHTtoVCHT({
        amount: youPay,
        transaction_hex
      })
    ).then((res) => {
      setTransferInProgress(false);
      if (res.type.includes('_SUCCESS')) {
        onClose();
      }
    });
  };

  const onTransferError = (err) => {
    if (err instanceof BaseError) {
      const revertError = err.walk(
        (err) => err instanceof ContractFunctionRevertedError
      );
      if (revertError instanceof ContractFunctionRevertedError) {
        const errorText = revertError.data?.args[0] ?? 'Something went wrong';
        notifyError(errorText);
      }
    }

    console.log(err);

    setTransferInProgress(false);
  };

  const onCoinChange = (code) => {
    dispatch(setPaymentModalMeta({ selectedCoin: code }));
  };

  const renderError = (errorText) => (
    <div className={styles.paymentModal__inputError}>
      <ErrorIcon />
      <span>{errorText}</span>
    </div>
  );

  return (
    <>
      <DialogMUI
        open
        onClose={onClose}
        paperClassName={styles.paymentModal__dialog}
      >
        {isTransferInProgress ? (
          <div className={styles.paymentModal__wrapper}>
            <WagmiTransfer
              amount={String(youPay)}
              onSuccess={onTransferSuccess}
              onError={onTransferError}
              contractAddress={
                convertOptions.rates?.CHT.VCHT.extra.cht_contract_address
              }
              walletAddress={
                convertOptions.rates?.CHT.VCHT.extra.cht_our_wallet_address
              }
            />
          </div>
        ) : (
          <div className={styles.paymentModal__wrapper}>
            <h2 className={styles.paymentModal__title}>Convert Chest token</h2>

            <div className={styles.upperBlock}>
              <div className={styles.upperBlock__header}>
                <div className={styles.upperBlock__title}>You pay</div>
              </div>

              <div className={styles.upperBlock__body}>
                <input
                  type='number'
                  placeholder='0'
                  className={styles.upperBlock__input}
                  value={youPay}
                  disabled={noConvertOptions}
                  onChange={(e) => onYouPayChange(e.target.value)}
                />

                <CoinsSelect
                  coins={[
                    {
                      code: 'CHT',
                      name: 'Chest Token',
                      iconComponent: <CHTIcon />
                    },
                    {
                      code: 'vCHT',
                      name: 'Chest Token',
                      iconComponent: <VCHTIcon />
                    }
                  ]}
                  selectedCoin={selectedCoin}
                  onChange={onCoinChange}
                />
              </div>

              {youPay < minAmount &&
                renderError(
                  `The minimum amount of Chest token you can convert is ${formatPrice(
                    minAmount
                  )}.`
                )}

              {youPay > maxAmount &&
                renderError(
                  `The maximum amount of Chest token you can convert is ${maxAmount}.`
                )}
            </div>

            <div className={styles.paymentModal__rateSeparator}>
              <div className={styles.paymentModal__rate}>
                <ExchangeIcon />1 {selectedCoin} =&nbsp;
                {renderLoadingPrice(rate, noConvertOptions, 15)}&nbsp;
                {convertTo}
              </div>
            </div>

            <div className={styles.lowerBlock}>
              <div className={styles.lowerBlock__header}>
                <div className={styles.lowerBlock__title}>You get</div>
                {selectedCoin === 'vCHT' && convertTo === 'BitGems' && (
                  <button
                    className={styles.lowerBlock__fixAmount}
                    role='button'
                    onClick={onFixAmountClick}
                    disabled={isFixTheAmountDisabled}
                  >
                    Fix the amount
                  </button>
                )}
              </div>

              <div className={styles.lowerBlock__body}>
                <input
                  type='number'
                  placeholder='0'
                  className={styles.lowerBlock__input}
                  value={youGet}
                  disabled={noConvertOptions}
                  onChange={(e) => onYouGetChange(e.target.value)}
                />

                {selectedCoin === 'CHT' ? (
                  <div className={styles.lowerBlock__CHT}>
                    <VCHTIcon />
                    <span>Chest token</span>
                  </div>
                ) : (
                  <CoinsSelect
                    coins={[
                      {
                        code: 'CHT',
                        name: 'Chest Token',
                        iconComponent: <CHTIcon />
                      },
                      {
                        code: 'BitGems',
                        name: 'BitGems',
                        iconComponent: <BitgemIcon />
                      }
                    ]}
                    selectedCoin={convertTo}
                    onChange={setConvertTo}
                  />
                )}
              </div>

              {selectedCoin === 'vCHT' &&
                convertTo === 'BitGems' &&
                youGet % 1 !== 0 &&
                renderError(
                  'Note that BitGems can only be purchased in whole numbers. Fractional purchases are not supported.'
                )}
            </div>

            <PaymentDiscretion />

            <ButtonMUI
              disabled={isButtonDisabled}
              loading={noConvertOptions || submitting}
              onClick={onConvertClick}
              loaderType='three-dots'
            >
              Convert
            </ButtonMUI>
          </div>
        )}
      </DialogMUI>
    </>
  );
};

export default ConvertCHTModal;
