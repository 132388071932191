import React, { useRef } from 'react';
import styles from './LandingFooter.module.scss'
import { Link } from 'react-router-dom';
import owlab from './images/owlab.svg'
import discord from './images/discord.svg'
import telegram from './images/telegram.svg'
import twitter from './images/twitter.svg'
import { ButtonMUI } from '../../../../shared';
import {
    rootMainPath,
    rootAuthPath,
    termsAndConditionsPath,
    privacyPolicyPath,
    walletAndExchangeTermsPath
} from '../../../../routes/paths';


const LandingFooter = () => {

    return (
        <div className={styles.root}>
            <div className={styles.top__wrapper}>
                <div className="container">
                    <div className={styles.top}>
                        <a href={'mailto:hello@cryptochests.com'} className={styles.email}>
                            hello@cryptochests.com
                        </a>
                        <div className={styles.menu}>
                            <Link to={termsAndConditionsPath} className={styles.menu__link}>
                                Term & Conditions
                            </Link>
                            <Link to={privacyPolicyPath} className={styles.menu__link}>
                                Privacy policy
                            </Link>
                            <button
                                className={styles.menu__link}
                                data-featurebase-feedback-portal>
                                Knowledge base
                            </button>
                        </div>
                        <div className={styles.social}>
                            <a className={styles.social__link} href={'https://discord.gg/h2zQMC3Q9K'} replace target={'blank'}>
                                <img src={discord} alt="Discord" />
                            </a>
                            <a className={styles.social__link} href={'https://t.me/cchests'} replace target={'blank'}>
                                <img src={telegram} alt="Telegram" />
                            </a>
                            <a className={styles.social__link} href={'https://twitter.com/CChests'} replace target={'blank'}>
                                <img src={twitter} alt="Twitter" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottom__wrapper}>
                <div className="container">
                    <div className={styles.bottom}>
                        <div className={styles.owlab}>
                            <p>Developed in cooperation with</p>
                            <a href="https://owlab.group/" target='blank'>
                                <img src={owlab} alt="Owlab" />
                            </a>
                        </div>
                        <div className={styles.copyright}>
                            <p>©2023 All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingFooter