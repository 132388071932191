import { LANDING } from "./landingActionTypes";

export function getSlides() {
    return {
      type: LANDING.GET_SLIDES,
      payload: {
        client: 'default',
        request: {
          url: `landing/futurein/`,
          method: 'get'
        }
      }
    };
  }