import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './LandingSlider.module.scss';
import { getSlides } from '../../landingAcrions';

const LandingSlider = () => {
    const dispatch = useDispatch();
    const { slides } = useSelector(({ landing }) => landing);

    useEffect(() => {
        dispatch(getSlides());
    }, [dispatch]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slides.length < 5 ? slides.length : 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slides.length < 4 ? slides.length : 4,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: slides.length < 3 ? slides.length : 3,
                }
            },
            {
                breakpoint: 810,
                settings: {
                    rows: 3,
                    slidesToShow: 1,
                    //infinite: true,
                    dots: true,
                    arrows: false,
                }
            },
        ]
    };

    return (
        <div className={styles.root}>
            <div className="container">
                <p className={styles.title}>As featured in</p>
                <div className={styles.wrapper}>
                    <Slider {...settings}>
                        {slides.map((item, idx) => (
                            <div key={idx}>
                                <div className={styles.item}>
                                    <a href={item.url} target='blank'>
                                        <img className={styles.logo} src={item.logo} alt={`slide-${idx}`} />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default LandingSlider;

