export const PAYMENT_MODALS = {
  GET_COINS_FOR_PAYMENT: 'GET_COINS_FOR_PAYMENT',
  GET_COINS_FOR_PAYMENT_SUCCESS: 'GET_COINS_FOR_PAYMENT_SUCCESS',
  GET_COINS_FOR_PAYMENT_FAIL: 'GET_COINS_FOR_PAYMENT_FAIL',

  GET_COIN_RATE: 'GET_COIN_RATE',
  GET_COIN_RATE_SUCCESS: 'GET_COIN_RATE_SUCCESS',
  GET_COIN_RATE_FAIL: 'GET_COIN_RATE_FAIL',

  GET_CONVERT_OPTIONS: 'GET_CONVERT_OPTIONS',
  GET_CONVERT_OPTIONS_SUCCESS: 'GET_CONVERT_OPTIONS_SUCCESS',
  GET_CONVERT_OPTIONS_FAIL: 'GET_CONVERT_OPTIONS_FAIL',

  POST_CREATE_BITGEMS_INVOICE: 'POST_CREATE_BITGEMS_INVOICE',
  POST_CREATE_BITGEMS_INVOICE_SUCCESS: 'POST_CREATE_BITGEMS_INVOICE_SUCCESS',
  POST_CREATE_BITGEMS_INVOICE_FAIL: 'POST_CREATE_BITGEMS_INVOICE_FAIL',

  POST_CREATE_VCHT_INVOICE: 'POST_CREATE_VCHT_INVOICE',
  POST_CREATE_VCHT_INVOICE_SUCCESS: 'POST_CREATE_VCHT_INVOICE_SUCCESS',
  POST_CREATE_VCHT_INVOICE_FAIL: 'POST_CREATE_VCHT_INVOICE_FAIL',

  POST_CREATE_BITGEMS_PAYOUT: 'POST_CREATE_BITGEMS_PAYOUT',
  POST_CREATE_BITGEMS_PAYOUT_SUCCESS: 'POST_CREATE_BITGEMS_PAYOUT_SUCCESS',
  POST_CREATE_BITGEMS_PAYOUT_FAIL: 'POST_CREATE_BITGEMS_PAYOUT_FAIL',

  POST_CREATE_VCHT_PAYOUT: 'POST_CREATE_VCHT_PAYOUT',
  POST_CREATE_VCHT_PAYOUT_SUCCESS: 'POST_CREATE_VCHT_PAYOUT_SUCCESS',
  POST_CREATE_VCHT_PAYOUT_FAIL: 'POST_CREATE_VCHT_PAYOUT_FAIL',

  POST_SELL_ESTIMATE: 'POST_SELL_ESTIMATE',
  POST_SELL_ESTIMATE_SUCCESS: 'POST_SELL_ESTIMATE_SUCCESS',
  POST_SELL_ESTIMATE_FAIL: 'POST_SELL_ESTIMATE_FAIL',

  POST_CONVERT_BITGEMS_TO_VCHT: 'POST_CONVERT_BITGEMS_TO_VCHT',
  POST_CONVERT_BITGEMS_TO_VCHT_SUCCESS: 'POST_CONVERT_BITGEMS_TO_VCHT_SUCCESS',
  POST_CONVERT_BITGEMS_TO_VCHT_FAIL: 'POST_CONVERT_BITGEMS_TO_VCHT_FAIL',

  POST_CONVERT_CHT_TO_VCHT: 'POST_CONVERT_CHT_TO_VCHT',
  POST_CONVERT_CHT_TO_VCHT_SUCCESS: 'POST_CONVERT_CHT_TO_VCHT_SUCCESS',
  POST_CONVERT_CHT_TO_VCHT_FAIL: 'POST_CONVERT_CHT_TO_VCHT_FAIL',

  POST_CONVERT_VCHT_TO_CHT: 'POST_CONVERT_VCHT_TO_CHT',
  POST_CONVERT_VCHT_TO_CHT_SUCCESS: 'POST_CONVERT_VCHT_TO_CHT_SUCCESS',
  POST_CONVERT_VCHT_TO_CHT_FAIL: 'POST_CONVERT_VCHT_TO_CHT_FAIL',

  POST_CONVERT_VCHT_TO_BG: 'POST_CONVERT_VCHT_TO_BG',
  POST_CONVERT_VCHT_TO_BG_SUCCESS: 'POST_CONVERT_VCHT_TO_BG_SUCCESS',
  POST_CONVERT_VCHT_TO_BG_FAIL: 'POST_CONVERT_VCHT_TO_BG_FAIL',

  GET_PAYMENT_RECEIPT: 'GET_PAYMENT_RECEIPT',
  GET_PAYMENT_RECEIPT_SUCCESS: 'GET_PAYMENT_RECEIPT_SUCCESS',
  GET_PAYMENT_RECEIPT_FAIL: 'GET_PAYMENT_RECEIPT_FAIL',

  GET_BG_TO_CHT_RATE: 'GET_BG_TO_CHT_RATE',
  GET_BG_TO_CHT_RATE_SUCCESS: 'GET_BG_TO_CHT_RATE_SUCCESS',
  GET_BG_TO_CHT_RATE_FAIL: 'GET_BG_TO_CHT_RATE_FAIL',

  SET_PAYMENT_MODAL_META: 'SET_PAYMENT_MODAL_META',
  SET_PAYMENT_RECEIPT: 'SET_PAYMENT_RECEIPT',
  RESET_PAYMENT_MODAL_META: 'RESET_PAYMENT_MODAL_META',
  RESET_CONVERT_OPTIONS: 'RESET_CONVERT_OPTIONS'
};
