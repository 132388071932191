import { put, takeEvery, all } from 'redux-saga/effects';
import { handleAuthSnacks, handleAuthLoaders } from '../features/Auth/authSaga';
import {
  handleMyAccountSnacks,
  handleMyAccountLoaders
} from '../features/MyAccount/myAccountSaga';
import { isDataObject } from '../helpers/functions';
import {
  handleMarketLoaders
} from '../features/Market/marketSaga';
import { handleArenaSnacks } from '../features/Arena/storage/arenaSaga';
import { handleWalletsSnacks } from '../features/Wallets/walletsSaga';
import { handlePaymentModalsSnacks } from '../features/PaymentModals/paymentModalsSaga';

export default function* rootSaga() {
  yield all([
    handleAuthLoaders(),
    handleSnack(),
    handleAuthSnacks(),
    handleMyAccountSnacks(),
    handleMyAccountLoaders(),
    handleMarketLoaders(),
    handleArenaSnacks(),
    handleWalletsSnacks(),
    handlePaymentModalsSnacks()
  ]);
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* openErrorSnack(e) {
  const data = typeof e === 'string' ? e : e?.error?.response?.data;
  yield put({
    type: 'ERROR_SNACK_OPEN',
    payload: isDataObject(data)
      ? Object.values(data).join('.\n')
      : typeof data === 'string'
        ? data
        : e?.error?.response?.statusText
          ? e?.error?.response?.data?.detail
          : 'Something went wrong'
            ? e?.error?.data
            : 'Something went wrong'
  });
}

export function* openSuccessSnack(e) {
  yield put({ type: 'SUCCESS_SNACK_OPEN', payload: e });
}

export function* resetErrorSnack() {
  yield delay(100);
  yield put({ type: 'ERROR_SNACK_CLOSE' });
}

export function* resetSuccessSnack() {
  yield delay(100);
  yield put({ type: 'SUCCESS_SNACK_CLOSE' });
}

export function* handleSnack() {
  yield takeEvery('ERROR_SNACK_OPEN', resetErrorSnack);
  yield takeEvery('SUCCESS_SNACK_OPEN', resetSuccessSnack);
}

export function* enableLoader() {
  yield put({ type: 'LOADING', payload: true });
}

export function* disableLoader() {
  yield put({ type: 'LOADING', payload: false });
}

export function* enableButtonLoader() {
  yield put({ type: 'BUTTON_LOADING', payload: true });
}

export function* disableButtonLoader() {
  yield put({ type: 'BUTTON_LOADING', payload: false });
}
