import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import styles from './ButtonMUI.module.scss';
import { clickSound } from '../../app/appActions';
import { useDispatch } from 'react-redux';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <Link ref={ref} to={href} {...other} />;
});

const ButtonMUI = forwardRef(
  (
    {
      children,
      className = '',
      color = 'primary',
      component = 'button',
      disabled = false,
      disableElevation = false,
      endIcon,
      fullWidth = false,
      href,
      size = 'medium',
      startIcon,
      variant = 'contained',
      formAction = false,
      onClick,
      loading = false,
      loaderType = 'default',
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const renderLoader = () => {
      switch (loaderType) {
        case 'three-dots':
          return <ThreeDots color='white' height={24} width={24} />;
        default:
          return <CircularProgress size={24} color='inherit' />;
      }
    };

    return (
      <Button
        className={`${styles.root}${className && ` ${className}`}`}
        color={color}
        component={component}
        disabled={disabled}
        disableElevation={disableElevation}
        disableRipple={variant === 'contained'}
        endIcon={!loading && endIcon}
        fullWidth={fullWidth}
        href={href}
        LinkComponent={LinkBehavior}
        size={size}
        startIcon={!loading && startIcon}
        variant={variant}
        type={formAction ? 'submit' : 'button'}
        onClick={e => {
          dispatch(clickSound());
          !!onClick && onClick(e);
        }}
        {...props}
        ref={ref}
      >
        {loading ? renderLoader() : children}
      </Button>
    );
  }
);

ButtonMUI.muiName = 'Button';
ButtonMUI.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'error',
    'warning'
  ]),
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  endIcon: PropTypes.node,
  formAction: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  loaderType: PropTypes.oneOf(['default', 'three-dots']),
  startIcon: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'transparent'])
};

export default ButtonMUI;
