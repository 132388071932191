import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backgroundAudio from '../../assets/sound/The Medieval Looped.mp3'
import click from '../../assets/sound/Click.wav'
import anticipationSound from '../../assets/sound/Anticipation Chests.mp3'
import { mainPath } from '../../routes/paths';
import { matchPath, useHistory } from 'react-router-dom';
import { clickSound, setCustomSound } from '../../app/appActions';

const Sound = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { musicStatus, clickSoundCounter, preOpenChestSoundCounter, customSound, surroundSound, router, roomStatus, musicVolume } = useSelector(({ app, router, arena }) => ({ ...app, router, ...arena }));
  const backgroundMusic = useRef();
  const backgroundSounds = useRef();
  const clickSoundRef = useRef();
  const surroundSoundRef = useRef();
  const chestPreOpenSoundRef = useRef();
  const currentPath = useMemo(() => Object.values(mainPath).find(({ path }) => matchPath(history.location.pathname, { path: path, exact: true })), [router])

  useEffect(() => {
    let tempArr = [
      backgroundMusic,
      backgroundSounds,
      clickSoundRef,
      surroundSoundRef,
      chestPreOpenSoundRef
    ]

    tempArr.forEach(el => {
      el.current.volume = musicVolume
    })
  }, [musicVolume]);

  useEffect(() => {
    if (!history.location.pathname.includes('/main/arena/room/')) dispatch(setCustomSound(backgroundAudio))
  }, [router]);

  useEffect(() => {
    if (musicStatus) {
      backgroundMusic.current.play()
    }
    else backgroundMusic.current.load()
  }, [musicStatus, customSound]);

  useEffect(() => {
    if (musicStatus && !!currentPath.sound && !!customSound) {
      backgroundSounds.current.play()
    }
    else backgroundSounds.current.load()
  }, [currentPath, musicStatus, customSound]);

  useEffect(() => {
    if (musicStatus && !!clickSoundCounter) {
      clickSoundRef.current.load()
      clickSoundRef.current.play()
    } else clickSoundRef.current.load()
  }, [clickSoundCounter, musicStatus]);

  useEffect(() => {
    if (musicStatus && !!surroundSound) {
      surroundSoundRef.current.load()
      surroundSoundRef.current.play()
    } else surroundSoundRef.current.load()
  }, [surroundSound]);

  useEffect(() => {
    if (musicStatus && !!preOpenChestSoundCounter) {
      chestPreOpenSoundRef.current.load()
      chestPreOpenSoundRef.current.play()
    } else chestPreOpenSoundRef.current.load()
  }, [preOpenChestSoundCounter]);

  return <>
    <audio src={customSound.toString()} ref={backgroundMusic} loop={roomStatus === 'waiting'} muted={!musicStatus} />
    <audio src={currentPath?.sound} ref={backgroundSounds} loop muted={!musicStatus || !currentPath?.sound} />
    <audio src={surroundSound.toString()} ref={surroundSoundRef} muted={!musicStatus} />
    <audio src={anticipationSound} ref={chestPreOpenSoundRef} muted={!musicStatus} />
    <audio src={click} ref={clickSoundRef} muted={!musicStatus} />
  </>
};

export default Sound;