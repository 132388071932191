import React from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { mainPath } from '../../routes/paths';
import styles from './NavSidebar.module.scss';
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg';
import { ReactComponent as Company } from '../../assets/icons/company_nav.svg';
import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as Twitter } from '../../assets/icons/Dribbble.svg';
import { ReactComponent as Feedback } from '../../assets/icons/feedback_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { classList } from '../../helpers/functions';
import { clickSound } from '../../app/appActions';

const NavSidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roomStatus, profileInfo } = useSelector(({ arena, auth }) => ({ ...arena, ...auth }))

  let isRoom = history.location.pathname.includes('/main/arena/room/'),
    isRoomWaiting = roomStatus === 'waiting';
  const isActiveLink = isRoom && isRoomWaiting ? mainPath.waitingRoom.path.replace(':roomID', profileInfo.current_room) : false;

  return (
    <aside className={styles.root}>
      <Link to={mainPath.overview.path} className={classList(styles.logo, { 'disable': !!isActiveLink })}>
        <Logo />
      </Link>
      <div className={styles.navList}>
        {Object.values(mainPath)
          .filter(({ isNavItem }) => !!isNavItem)
          .map(({ name, path, icon, iconActive }, idx) => (
            <NavLink
              className={classList(styles.navItem, { 'disable': !!isActiveLink })}
              activeClassName={styles.active}
              key={idx}
              to={path}
              onClick={() => dispatch(clickSound())}
            >
              {icon}
              {iconActive}
              <span>{name}</span>
            </NavLink>
          ))}
        <hr />
        <button
          data-featurebase-feedback-portal
          className={classList(styles.navItem, { 'disable': !!isActiveLink })}
          onClick={() => dispatch(clickSound())}
        >
          <Feedback />
          <span>Feedback & updates</span>
        </button>
        <Link
          className={classList(styles.navItem, { 'disable': !!isActiveLink })}
          to={mainPath.myAccount.path + '?section=company-info'}
          onClick={() => dispatch(clickSound())}
        >
          <Company />
          <span>Company info</span>
        </Link>
      </div>
      <div className={styles.news}>
        <div>
          <Discord onClick={() => extendLink(profileInfo.company_contact_info?.support_discord_link)} />
          <Telegram onClick={() => extendLink(profileInfo.company_contact_info?.support_telegram_link)} />
          <Twitter onClick={() => extendLink(profileInfo.company_contact_info?.support_twitter_link)} />
        </div>
        <p>Stay turned for news and updates!</p>
      </div>
    </aside>
  );
};

const extendLink = link => window.open(link, '_blank')

export default NavSidebar;
