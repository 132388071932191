import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import _isUndefined from 'lodash/isUndefined';

import { clickSound } from '../../../app/appActions';
import { renderLoadingText } from '../../../helpers/functions';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/select-arrow.svg';

import styles from './Components.module.scss';

const CoinsSelect = ({ coins, selectedCoin, onChange }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const selectedCoinData = useMemo(() => {
    return coins.find(({ code }) => code === selectedCoin);
  }, [coins, selectedCoin]);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(clickSound());
  };

  const closePopover = () => {
    setAnchorEl(null);
    dispatch(clickSound());
  };

  const onCoinClick = useCallback(
    (code) => {
      closePopover();
      onChange(code);
    },
    [dispatch]
  );

  const renderCoin = useCallback(
    (coin) => (
      <div
        key={coin.id}
        className={clsx([
          styles.coinsSelect__coin,
          selectedCoin === coin.code && styles.selected
        ])}
        onClick={() => onCoinClick(coin.code)}
      >
        <div className={styles.coinsSelect__coinIconWrapper}>
          {coin.iconComponent ? (
            coin.iconComponent
          ) : (
            <img
              src={coin.logo}
              alt={coin.name}
              className={styles.coinsSelect__coinIcon}
            />
          )}
        </div>

        <div
          className={clsx([
            styles.coinsSelect__coinName,
            selectedCoin === coin.code && styles.selected
          ])}
        >
          {coin.name}
        </div>

        {coin.name !== coin.code && (
          <div
            className={clsx([
              styles.coinsSelect__coinCode,
              selectedCoin === coin.code && styles.selected
            ])}
          >
            {coin.code}
          </div>
        )}
      </div>
    ),
    [onCoinClick, selectedCoin]
  );

  const renderPopover = useCallback(
    (coinList) => {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          classes={{
            paper: `${styles.coinsSelect__popover} pretty-scroll`
          }}
        >
          {coinList.map(renderCoin)}
        </Popover>
      );
    },
    [anchorEl, renderCoin]
  );

  return (
    <div className={styles.coinsSelect}>
      <Button
        aria-describedby={id}
        className={styles.coinsSelect__coinSelect}
        onClick={openPopover}
        style={{
          textTransform:
            selectedCoinData?.name === 'BitGems' ? 'none' : 'uppercase'
        }}
      >
        {selectedCoinData &&
          (selectedCoinData.iconComponent || (
            <img
              src={selectedCoinData.logo}
              alt={selectedCoinData.name}
              className={styles.coinsSelect__coinIcon}
            />
          ))}

        <span>
          {renderLoadingText(
            selectedCoinData?.code,
            _isUndefined(selectedCoinData?.code),
            14
          )}
        </span>

        <ArrowIcon />
      </Button>

      {renderPopover(coins)}
    </div>
  );
};

export default CoinsSelect;
