import React from 'react'
import styles from './LandingChestTokens.module.scss'
import banner from './images/growth.png'
import bannerMob from './images/growth_mob.png'

const LandingChestTokens = () => {
    return (
        <div className={styles.root}>
            <div className="container">
                <p className={styles.subtitle}>Chest Tokens are</p>
                <h2 className={styles.title}>Your Utility and <br></br> Investment Token</h2>
                <div className={styles.flex}>
                    <div className={styles.flex__box}>
                        <p className={styles.flex__box_title}>Investment</p>
                        <div>
                            After every game is played, we reinvest in a token, and every month, we burn tokens lost by players, ensuring its stable growth.
                        </div>
                    </div>
                    <div className={styles.flex__box}>
                        <p className={styles.flex__box_title}>Utility</p>
                        <div>
                            Play, stake, participate in jackpots, enjoy discounts, special events, and more.
                        </div>
                    </div>
                </div>
                <img src={banner} alt="Growth forecasting" className={styles.banner} />
                <img src={bannerMob} alt="Growth forecasting" className={styles.bannerMob} />
            </div>
        </div>
    )
}

export default LandingChestTokens