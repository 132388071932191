import React from 'react';
import { useDispatch } from 'react-redux';

import { ButtonMUI, DialogMUI } from '../../shared';
import { resetPaymentModalMeta } from './paymentModalsActions';
import { usePaymentModals } from './index';
import { prettyScroll } from '../../helpers/functions';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';

import styles from './styles.module.scss';

const CHTmoreInfoModal = () => {
  const dispatch = useDispatch();
  const { handleBuyVCHTModal } = usePaymentModals();

  const onCloseDialog = () => {
    dispatch(resetPaymentModalMeta());
  };

  const onBuyVCHTClick = () => {
    handleBuyVCHTModal({
      coin: 'USDTERC20',
      amount: 0
    });
  };

  return (
    <DialogMUI
      open
      onClose={onCloseDialog}
      paperClassName={styles.moreInfoDialog__paper}
    >
      <div className={styles.moreInfoDialog}>
        <h2>Understanding CHT and vCHT: Your Chest Tokens</h2>

        <div className={styles.moreInfoDialog__text} ref={prettyScroll}>
          <p>
            In the Crypto Chests world, you might have noticed two types of
            tokens: CHT (Chest Tokens) and vCHT (Virtual Chest Tokens). We're
            here to clarify the differences and help you make the most of your
            rewards.
          </p>

          <h4>1. CHT (Chest Tokens):</h4>

          <ul>
            <li>
              Blockchain-Backed: CHT operates on the blockchain, ensuring
              security, transparency, and decentralization.
            </li>
            <li>
              Exchangeable: You can easily exchange CHT for other cryptocurrencies
              or use them for various transactions.
            </li>
            <li>
              Network Fees: Please note that using CHT on the blockchain may incur
              network fees, so be prepared for those.
            </li>
          </ul>

          <h4>2. vCHT (Virtual Chest Token):</h4>

          <ul>
            <li>
              Exclusive to Crypto Chests: vCHT is unique to our platform and is an
              integral part of the Crypto Chests experience.
            </li>
            <li>
              No Network Fees: Since vCHT is part of our platform, you won't face
              any blockchain network fees when using or transferring them.
            </li>
            <li>
              Convertibility: You have the flexibility to convert vCHT to CHT
              whenever you like, maintaining the same value without losing out. To
              ensure a smooth exchange process and cover associated fees, we allow
              you to exchange vCHT to CHT after you accumulate a minimum of 2500
              vCHT.
            </li>
          </ul>

          <h3>Why Two Types?</h3>

          <p>
            We offer both CHT and vCHT to provide you with choices that balance
            different advantages. CHT on the blockchain ensures the highest level
            of security and transparency, while vCHT within our platform
            streamlines transactions and avoids extra fees.
          </p>

          <h3>Your Choice: Easy Conversion</h3>

          <p>
            This minimum entry requirement helps us make sure that your conversion
            experience remains efficient and cost-effective. It's all about making
            your Crypto Chests experience seamless and user-friendly.
          </p>

          <br />

          <p>
            If you have any more questions about CHT and vCHT or need assistance
            with conversions, feel free to reach out to our support team. Happy
            gaming!
          </p>
        </div>

        <div className={styles.moreInfoDialog__buttonWrapper}>
          <ButtonMUI onClick={onBuyVCHTClick}>
            <VCHTIcon /> &nbsp; Buy vCHT
          </ButtonMUI>
        </div>
      </div>
    </DialogMUI>
  );
};

export default CHTmoreInfoModal;
