export const MARKET = {
  GET_BITGEMS_PACKAGES: 'GET_BITGEMS_PACKAGES',
  GET_BITGEMS_PACKAGES_SUCCESS: 'GET_BITGEMS_PACKAGES_SUCCESS',
  GET_BITGEMS_PACKAGES_FAIL: 'GET_BITGEMS_PACKAGES_FAIL',

  GET_COIN_RATE: 'GET_COIN_RATE',
  GET_COIN_RATE_SUCCESS: 'GET_COIN_RATE_SUCCESS',
  GET_COIN_RATE_FAIL: 'GET_COIN_RATE_FAIL',

  SET_SELECTED_COIN: 'SET_SELECTED_COIN'
};
