import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import Slider from 'react-slick';

import { getBitgemPackages } from '../marketActions';
import CoinsList from './components/CoinsList';
import BitgemRate from './components/BitgemRate';
import { ButtonMUI } from '../../../shared';
import { classList, formatPrice } from '../../../helpers/functions';
import { usePaymentModals } from '../../PaymentModals';
import VCHTBalance from './components/VCHTBalance';

import SliderIcon from '../../../assets/images/bitgem-slider.png';
import Pack10Icon from '../../../assets/images/bitgem-pack-10.jpg';
import Pack100Bg from '../../../assets/images/bitgem-pack-bg-100.png';
import Pack1000Bg from '../../../assets/images/bitgem-pack-bg-1000.png';
import BestValueIcon from '../../../assets/images/best-value.png';
import MostPopularIcon from '../../../assets/images/most-popular.png';
import Extra5CHTIcon from '../../../assets/images/extra-5-vcht.png';
import Extra20CHTIcon from '../../../assets/images/extra-20-vcht.png';
import Extra30CHTIcon from '../../../assets/images/extra-30-vcht.png';
import { ReactComponent as BackArrow } from '../../../assets/icons/arrow back.svg';

import styles from './BitgemPacks.module.scss';

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={classList(styles.arrow, styles.arrowPrev, className)}
      onClick={onClick}
      style={style}
    >
      <BackArrow />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={classList(
        styles.arrow,
        styles.arrowNext,
        styles.hidden,
        className
      )}
      onClick={onClick}
      style={style}
    >
      <BackArrow />
    </div>
  );
}

const BitgemPacks = ({ isFull = true }) => {
  const {
    bitgem_packages: bp,
    fetchingRate,
    coinRate,
    selectedCoin,
    boolToRefetchCurrentPage
  } = useSelector(({ market, app }) => ({ ...market, ...app }));
  const { handleBuyBitgemsModal } = usePaymentModals();
  const dispatch = useDispatch();
  const sliderRef = useRef();
  let sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 2.25,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (i) => {
      if (Math.ceil(i) === bp.packages.length - 1)
        document
          .querySelector(`.${styles.arrowNext}`)
          .classList.add(styles.hidden);
      else
        document
          .querySelector(`.${styles.arrowNext}`)
          .classList.remove(styles.hidden);

      if (Math.ceil(i) === 0)
        document
          .querySelector(`.${styles.arrowPrev}`)
          .classList.add(styles.hidden);
      else
        document
          .querySelector(`.${styles.arrowPrev}`)
          .classList.remove(styles.hidden);
    }
  };

  const onBuyClick = (amount = null) => {
    handleBuyBitgemsModal({
      coin: selectedCoin,
      amount: amount || 0
    });
  };

  useEffect(() => {
    dispatch(getBitgemPackages()).then((res) => {
      if (res.type.includes('_SUCCESS') && !isFull)
        sliderRef.current.slickGoTo(
          res.payload.data?.packages?.length - 1,
          true
        );
    });
  }, [boolToRefetchCurrentPage]);

  const renderPacks = {
    manualPack: () => (
      <div className={styles.bitgemPack} key={'zero'}>
        <img
          src={SliderIcon}
          alt='manual'
          className={styles.bitgemPack__sliderIcon}
        />

        <div className={styles.bitgemPack__manualText}>
          Click to select the number of BitGems you need
        </div>

        <ButtonMUI
          disabled={fetchingRate}
          className={styles.bitgemPack__buyButton}
          onClick={() => onBuyClick()}
          color='secondary'
        >
          Buy manually
        </ButtonMUI>
      </div>
    ),
    packs: () =>
      bp.packages.map(({ id, amount, label }) => {
        let customBg;
        let extraCHTIcon = Extra5CHTIcon;
        if (amount === 100) {
          customBg = Pack100Bg;
          extraCHTIcon = Extra20CHTIcon;
        }
        if (amount === 1000) {
          customBg = Pack1000Bg;
          extraCHTIcon = Extra30CHTIcon;
        }
        const withCustomBg = Boolean(customBg);
        return (
          <div key={id}>
            <div
              className={styles.bitgemPack}
              style={
                withCustomBg ? { backgroundImage: `url('${customBg}')` } : {}
              }
            >
              {label === 'best_value' && (
                <img
                  src={BestValueIcon}
                  alt='best value'
                  className={styles.bitgemPack__labelIcon}
                />
              )}

              {label === 'most_popular' && (
                <img
                  src={MostPopularIcon}
                  alt='most popular'
                  className={styles.bitgemPack__labelIcon}
                />
              )}

              <img
                src={extraCHTIcon}
                alt='extra CHT'
                className={styles.bitgemPack__extraCHT}
              />

              {!withCustomBg && (
                <img
                  src={Pack10Icon}
                  alt={`${amount} BitGems`}
                  className={styles.bitgemPack__bigtgemsIcon}
                />
              )}

              <div
                className={clsx([
                  styles.bitgemPack__amount,
                  withCustomBg && styles.withCustomBg
                ])}
              >
                <strong>{amount}</strong>&nbsp;BitGems
              </div>

              <ButtonMUI
                disabled={fetchingRate}
                className={styles.bitgemPack__buyButton}
                onClick={() => onBuyClick(amount)}
                loading={fetchingRate}
                loaderType='three-dots'
              >
                {`${formatPrice(
                  amount * (coinRate.buy?.bitgems_rate || 0)
                )} ${selectedCoin}`}
              </ButtonMUI>
            </div>
          </div>
        );
      }),
    render: () => [renderPacks.manualPack(), ...renderPacks.packs()]
  };

  if (_isEmpty(bp)) return null;

  return (
    <section
      className={classList(styles.bitgemPacksSection, {
        [styles.withSlider]: !isFull
      })}
    >
      {isFull && <h1 className={styles.title}>BitGems packs</h1>}

      <div
        className={classList(styles.header, { [styles.withSlider]: !isFull })}
      >
        <CoinsList />
        <BitgemRate isFull={isFull} />
      </div>

      {isFull && <VCHTBalance />}

      <div
        className={classList(styles.bitgemPacks, {
          [styles.withSlider]: !isFull
        })}
      >
        {isFull ? (
          renderPacks.render()
        ) : (
          <Slider
            ref={sliderRef}
            {...sliderSettings}
            children={renderPacks.render()}
          />
        )}
      </div>
    </section>
  );
};

export default BitgemPacks;
