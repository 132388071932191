import { MARKET } from './marketActionTypes';

export function getBitgemPackages() {
  return {
    type: MARKET.GET_BITGEMS_PACKAGES,
    payload: {
      client: 'default',
      request: {
        url: `buy/bitgems-packages/`,
        method: 'get'
      }
    }
  };
}

export function getCoinRate(coinCode) {
  return {
    type: MARKET.GET_COIN_RATE,
    payload: {
      client: 'default',
      request: {
        url: `coins/${coinCode}/rate/`,
        method: 'get'
      }
    }
  };
}

export function setSelectedCoin(coinCode) {
  return {
    type: MARKET.SET_SELECTED_COIN,
    coinCode
  };
}
