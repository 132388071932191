// You need to import the function into rootReducer.js
import { LANDING } from './landingActionTypes';

const INITIAL_STATE = {
    slides: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LANDING.GET_SLIDES_SUCCESS: 
        return {
            ...state, 
            slides: action.payload.data
        }
        
    default:
      return state;
  }
}
