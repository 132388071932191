import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { getBGtoCHTrate } from '../paymentModalsActions';
import { renderLoadingPrice } from '../../../helpers/functions';
import { ReactComponent as MoreInfoIcon } from '../../../assets/icons/more-info.svg';
import { ReactComponent as GiftIcon } from '../../../assets/icons/gift.svg';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/bonus-cht-tooltip.svg';

import styles from './Components.module.scss';

const ExtraCHTForBitgems = () => {
  const dispatch = useDispatch();
  const {
    meta: { youGet },
    fetchingCoinRate,
    bgToCHTRate
  } = useSelector(({ paymentModals }) => paymentModals);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    dispatch(getBGtoCHTrate());
  }, []);

  const getPercent = () => {
    if (youGet >= 1000) return 30;
    if (youGet >= 100) return 20;
    return 5;
  };

  return (
    <div className={styles.extraCHT}>
      <div className={styles.extraCHT__leftBlock}>
        <GiftIcon />
        <div>
          <div
            className={styles.extraCHT__label}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            Bonus
            <MoreInfoIcon className={styles.extraCHT__moreInfo} />
            <TooltipIcon
              className={clsx([
                styles.extraCHT__tooltip,
                isTooltipOpen && styles.isOpen
              ])}
            />
          </div>
          <div>More BitGems more bonuses</div>
        </div>
      </div>
      <div className={styles.extraCHT__rightBlock}>
        <div className={styles.extraCHT__label}>
          {renderLoadingPrice(
            (youGet * bgToCHTRate) * (getPercent() / 100),
            fetchingCoinRate || !bgToCHTRate,
            14
          )}
          &nbsp;vCHT
        </div>
        <div>{getPercent()}% extra</div>
      </div>
    </div>
  );
};

export default ExtraCHTForBitgems;
