import React, { useState } from 'react'
import styles from './LandingTokenomics.module.scss'
import arbirtum from './images/arbitrum.png'
import itemBg from './images/item_bg.png'
import copyIcon from './images/copy_icon.svg'
import token from './images/token.jpg'

const LandingTokenomics = () => {
    const [textToCopy, setTextToCopy] = useState('0xA21D9fb5D212649Ecb387923932a1521500a8e18');
    const [copyMessage, setCopyMessage] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(textToCopy)
        setCopyMessage(true);
        setTimeout(() => {
            setCopyMessage(false);
        }, 4000)
      };

    return (
        <div className={styles.root}>
            <div className="container">
                <h3 className={styles.title}>Tokenomics</h3>
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <div className={styles.item}>
                            <p className={styles.item__title}>$0.005</p>
                            <p>Presale price</p>
                        </div>
                        <div className={styles.item}>
                            <p className={styles.item__title}><img src={arbirtum} alt="arbirtum" />Arbitrum</p>
                            <p>Token network</p>
                        </div>
                        <div className={styles.item}>
                            <img src={itemBg} alt="background" className={styles.item__bg} />
                            <p className={styles.item__title}>CHT</p>
                            <p>Token Symbol</p>
                        </div>
                        <div className={styles.item}>
                        <p className={`${styles.copy__message} ${copyMessage ? styles.copy__message_active : ''}`}>Copied to clipboard</p>
                            <button onClick={handleCopyClick}><img src={copyIcon} alt="copy" /></button>
                            <p className={styles.item__smart_contract}><button  onClick={handleCopyClick}>0xA21D...0a8e18</button></p>
                            <p>Smart contract</p>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <img src={token} alt="Token distribution" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingTokenomics