import { AUTH } from './authActionTypes';

export function postCheckExists(crypto_address) {
  return {
    type: AUTH.CHECK_EXISTS,
    payload: {
      request: {
        url: `auth/check-exists/`,
        method: 'post',
        data: {
          crypto_address
        }
      }
    }
  };
}

export function postSignIn({ crypto_address, challenge, signature }) {
  return {
    type: AUTH.SIGN_IN,
    payload: {
      request: {
        url: `auth/login/`,
        method: 'post',
        data: {
          crypto_address,
          challenge,
          signature
        }
      }
    }
  };
}

export function postCheckUsername(username) {
  console.log('postCheckUsername');
  return {
    type: AUTH.CHECK_USERNAME,
    payload: {
      request: {
        url: `auth/check-username/`,
        method: 'post',
        data: {
          username
        }
      }
    }
  };
}

export function postSignUp({ username, crypto_address, challenge, signature }) {
  return {
    type: AUTH.SIGN_UP,
    payload: {
      request: {
        url: `auth/register/`,
        method: 'post',
        data: {
          username,
          crypto_address,
          challenge,
          signature
        }
      }
    }
  };
}

export function postLogout() {
  return {
    type: AUTH.LOGOUT,
    payload: {
      request: {
        url: `auth/logout/`,
        method: 'post'
      }
    }
  };
}

export function setAuthData({ crypto_address = '', challenge = '', signature = '' }) {
  return {
    type: AUTH.SET_AUTH_DATA,
    payload: {
      crypto_address,
      challenge,
      signature
    }
  };
}

export function getProfileInfo() {
  return {
    type: AUTH.GET_PROFILE_INFO,
    payload: {
      request: {
        url: `user/profile/`,
        method: 'GET'
      }
    }
  };
}

export function patchProfileInfo(data) {
  return {
    type: AUTH.GET_PROFILE_INFO,
    payload: {
      request: {
        url: `user/profile/`,
        method: 'PATCH',
        data
      }
    }
  };
}

export function setProfileInfo(data) {
  return {
    type: AUTH.GET_PROFILE_INFO_SUCCESS,
    payload: { data }
  };
}

export function setTempProfilePermanent() {
  return {
    type: AUTH.SET_TEMP_PROFILE_PERMANENT,
  };
}