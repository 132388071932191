import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { prettyScroll } from '../../helpers/functions';
import { ReactComponent as BackBubbleIcon } from '../../assets/icons/back-bubble.svg';

import styles from './TextPageConstructor.module.scss';

const TextPageConstructor = (props) => {
  const { pageComponents } = props;
  const history = useHistory();

  return (
    <main className={`${styles.root} page-wrap`} ref={prettyScroll}>
      <div className={styles.arrowBack} onClick={() => history.goBack()}>
        <BackBubbleIcon />
      </div>

      <div className={styles.container}>
        {pageComponents.map((component, idx) => {
          switch (component.type) {
            case 'title':
              return <h1 key={idx}>{component.text}</h1>;
            case 'subtitle':
              return <h2 key={idx}>{component.text}</h2>;
            case 'heading':
              return <h3 key={idx}>{component.text}</h3>;
            case 'paragraph':
              return (
                <p
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: component.text }}
                />
              );
            case 'list':
              return (
                <div key={idx}>
                  <p className={styles.textBeforeList}>{component.text}</p>
                  <ul>
                    {component.listItems.map((item, idx) => (
                      <li
                        key={idx}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    ))}
                  </ul>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </main>
  );
};

TextPageConstructor.propTypes = {
  pageComponents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        'title',
        'subtitle',
        'heading',
        'paragraph',
        'list'
      ]).isRequired,
      text: PropTypes.string,
      listItems: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired
};

export default TextPageConstructor;
