import React from 'react';
import clsx from 'clsx';

import { parseWalletAddress } from '../../helpers/functions';
import { notifySuccess } from '../../helpers/notifySnack';
import { ReactComponent as CopyIcon } from '../../assets/icons/ci_copy.svg';

import styles from './CopyAddress.module.scss';

const CopyAddress = ({ containerStyle, address }) => {
  const onCopyClick = () => {
    navigator.clipboard.writeText(address);
    notifySuccess('Address copied to clipboard');
  };

  return (
    <div
      className={clsx([styles.copyAddress, containerStyle])}
      onClick={onCopyClick}
    >
      {parseWalletAddress(address)}
      <CopyIcon />
    </div>
  );
};

export default CopyAddress;
