import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isUndefined from 'lodash/isUndefined';

import { getCoinRate } from '../../marketActions';
import { classList, renderLoadingPrice } from '../../../../helpers/functions';
import { ReactComponent as ChartIcon } from '../../../../assets/icons/line-chart.svg';
import { ReactComponent as ExchangeIcon } from '../../../../assets/icons/exchange-rate.svg';

import styles from './Components.module.scss';

const BitgemRate = ({ isFull = true }) => {
  const {
    selectedCoin,
    coinRate,
    bitgem_packages: { bitgem_usd_rate },
    fetchingRate
  } = useSelector(({ market }) => market);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoinRate(selectedCoin));
  }, [selectedCoin]);

  const renderBitgemRate = useMemo(
    () => (
      <div className={styles.bitgemRate__selectedCoinRate}>
        <ExchangeIcon />
        <span>
          1 BitGem =&nbsp;
          {renderLoadingPrice(
            coinRate.buy?.bitgems_rate,
            _isUndefined(coinRate.buy) || fetchingRate,
            14
          )}
          &nbsp;
          {selectedCoin}
        </span>
      </div>
    ),
    [coinRate, fetchingRate]
  );

  return (
    <div
      className={classList(styles.bitgemRate, { [styles.inModal]: !isFull })}
    >
      <div className={styles.bitgemRate__usdRate}>
        <ChartIcon />1 BitGem always worth {bitgem_usd_rate} USD
      </div>
      {renderBitgemRate}
    </div>
  );
};

export default BitgemRate;
