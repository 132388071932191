import { MY_ACCOUNT } from './myAccountActionTypes';

const INITIAL_STATE = {
  /**
   * @type {Object}
   * @property {number} id
   * @property {string} crypto_address
   * @property {string} avatar
   * @property {string} username
   * @property {number} balance_cht
   * @property {number} balance_bitgems
   * @property {Object} games
   * @property {number} games.this_week_count
   * @property {number} games.all_time_count
   * @property {number} games.rank
   * @property {number | null} games.week_percent_change
   * @property {Object} winning_stats
   * @property {number} winning_stats.won_bitgems_7days
   * @property {number} winning_stats.won_bitgems_all_time
   * @property {number} winning_stats.won_cht_7days
   * @property {number} winning_stats.won_cht_all_time
   * @property {Object} company_contact_info
   * @property {string} company_contact_info.support_discord_link
   * @property {string} company_contact_info.support_telegram_link
   * @property {string} company_contact_info.support_email
   */
  accountInfo: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MY_ACCOUNT.GET_MY_ACCOUNT_SUCCESS:
      return { ...state, accountInfo: action.payload.data };
    case MY_ACCOUNT.PATCH_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountInfo: { ...state.accountInfo, ...action.payload.data }
      };
    default:
      return state;
  }
}
