import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  postCreateVCHTInvoice,
  getCoinRate,
  setPaymentModalMeta,
  resetPaymentModalMeta,
  getCoinsForPayment
} from './paymentModalsActions';
import { ButtonMUI, DialogMUI } from '../../shared';
import CoinsSelect from './components/CoinsSelect';
import PaymentDiscretion from './components/PaymentDiscretion';
import { formatPrice, renderLoadingPrice } from '../../helpers/functions';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange-rate.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as VCHTIcon } from '../../assets/icons/vcht.svg';

import styles from './styles.module.scss';

const BuyVCHTModal = () => {
  const {
    coins,
    meta: { youPay, youGet, selectedCoin, blockchainCoinRate },
    fetchingCoinRate,
    profileInfo: { balance_cht }
  } = useSelector(({ paymentModals, auth }) => ({ ...paymentModals, ...auth }));
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(null);

  useEffect(() => {
    dispatch(getCoinsForPayment());
  }, []);

  useEffect(() => {
    dispatch(getCoinRate(selectedCoin));
  }, [selectedCoin]);

  const selectedCoinData = useMemo(() => {
    return coins.find(({ code }) => code === selectedCoin);
  }, [coins, selectedCoin]);

  const { coinRate, minVCHTtoBuy, maxVCHTtoBuy } = useMemo(() => {
    return {
      coinRate: blockchainCoinRate.buy.cht_rate,
      minVCHTtoBuy: blockchainCoinRate.buy.cht_min_amount,
      maxVCHTtoBuy: Math.min(blockchainCoinRate.buy.cht_max_amount, balance_cht)
    };
  }, [blockchainCoinRate, balance_cht]);

  useEffect(() => {
    if (coinRate) {
      const newYouGet = youGet === 0 ? minVCHTtoBuy : youGet;
      dispatch(
        setPaymentModalMeta({
          youGet: newYouGet,
          youPay: formatPrice(newYouGet * coinRate)
        })
      );
    }
  }, [coinRate]);

  const isButtonDisabled = useMemo(() => {
    return (
      !youPay ||
      !youGet ||
      fetchingCoinRate ||
      submitting ||
      youGet % 1 !== 0 ||
      youGet < minVCHTtoBuy ||
      youGet > maxVCHTtoBuy
    );
  }, [
    youPay,
    youGet,
    fetchingCoinRate,
    submitting,
    minVCHTtoBuy,
    maxVCHTtoBuy
  ]);

  const isFixTheAmountDisabled = useMemo(() => {
    if (fetchingCoinRate) return true;
    return (
      youGet % 1 === 0 && youGet >= minVCHTtoBuy && youGet <= maxVCHTtoBuy
    );
  }, [youGet, minVCHTtoBuy, maxVCHTtoBuy, fetchingCoinRate]);

  const onClose = () => {
    dispatch(resetPaymentModalMeta());
  };

  const onYouPayChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: value,
        youGet: formatPrice(value / coinRate)
      })
    );
  };

  const onYouGetChange = (value) => {
    dispatch(
      setPaymentModalMeta({
        youPay: formatPrice(value * coinRate),
        youGet: value
      })
    );
  };

  const onFixAmountClick = () => {
    if (youGet < minVCHTtoBuy) {
      onYouGetChange(minVCHTtoBuy);
      return;
    }

    if (youGet > maxVCHTtoBuy) {
      onYouGetChange(maxVCHTtoBuy);
      return;
    }

    if (youGet % 1 === 0) return;

    const amount = youGet - (youGet % 1);
    onYouGetChange(amount || 1);
  };

  const onCoinChange = (code) => {
    dispatch(setPaymentModalMeta({ selectedCoin: code }));
  };

  const onBuyClick = () => {
    setSubmitting(true);
    dispatch(
      postCreateVCHTInvoice({
        amount: youGet,
        code: selectedCoin
      })
    ).then((res) => {
      if (res.payload?.data?.url) {
        window.location.href = res.payload.data.url;
      } else {
        setSubmitting(false);
      }
    });
  };

  const renderError = (errorText) => (
    <div className={styles.paymentModal__inputError}>
      <ErrorIcon />
      <span>{errorText}</span>
    </div>
  );

  return (
    <DialogMUI
      open
      onClose={onClose}
      paperClassName={styles.paymentModal__dialog}
    >
      <div className={styles.paymentModal__wrapper}>
        <h2 className={styles.paymentModal__title}>Buy Chest token</h2>

        <div className={styles.upperBlock}>
          <div className={styles.upperBlock__header}>
            <div className={styles.upperBlock__title}>
              You pay
              {selectedCoinData?.name && (
                <div className={styles.upperBlock__coinName}>
                  {selectedCoinData.name}
                </div>
              )}
            </div>
          </div>

          <div className={styles.upperBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.upperBlock__input}
              value={youPay}
              disabled={fetchingCoinRate}
              onChange={(e) => onYouPayChange(e.target.value)}
            />

            <CoinsSelect
              coins={coins}
              selectedCoin={selectedCoin}
              onChange={onCoinChange}
            />
          </div>
        </div>

        <div className={styles.paymentModal__rateSeparator}>
          <div className={styles.paymentModal__rate}>
            <ExchangeIcon />1 CHT ={' '}
            {renderLoadingPrice(coinRate, fetchingCoinRate, 15)} {selectedCoin}
          </div>
        </div>

        <div className={styles.lowerBlock}>
          <div className={styles.lowerBlock__header}>
            <div className={styles.lowerBlock__title}>You get</div>
            <button
              className={styles.lowerBlock__fixAmount}
              role='button'
              onClick={onFixAmountClick}
              disabled={isFixTheAmountDisabled}
            >
              Fix the amount
            </button>
          </div>

          <div className={styles.lowerBlock__body}>
            <input
              type='number'
              placeholder='0'
              className={styles.lowerBlock__input}
              value={youGet}
              disabled={fetchingCoinRate}
              onChange={(e) => onYouGetChange(e.target.value)}
            />

            <div className={styles.lowerBlock__CHT}>
              <VCHTIcon />
              <span>Chest token</span>
            </div>
          </div>

          {youGet % 1 !== 0 &&
            renderError(
              'Note that tokens can only be purchased in whole numbers. Fractional purchases are not supported.'
            )}

          {youGet < minVCHTtoBuy &&
            renderError(
              `The minimum amount of tokens you can buy is ${formatPrice(
                minVCHTtoBuy
              )}.`
            )}

          {youGet > maxVCHTtoBuy &&
            renderError(
              `The maximum amount of tokens you can buy is ${maxVCHTtoBuy}.`
            )}
        </div>

        <PaymentDiscretion />

        <ButtonMUI
          disabled={isButtonDisabled}
          loading={fetchingCoinRate || submitting}
          onClick={onBuyClick}
          loaderType='three-dots'
        >
          Buy
        </ButtonMUI>
      </div>
    </DialogMUI>
  );
};

export default BuyVCHTModal;
