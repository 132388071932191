import React from 'react';

import styles from './Components.module.scss';

const TimeSwitchers = (props) => {
  const { timePeriod, onTimePeriodChange } = props;

  return (
    <div className={styles.timeSwitchers}>
      <p
        className={`${styles.timeSwitchers__item} ${
          timePeriod === 'week' ? styles.timeSwitchers__item__active : ''
        }`}
        onClick={() => onTimePeriodChange('week')}
      >
        Week
      </p>
      <p
        className={`${styles.timeSwitchers__item} ${
          timePeriod === 'allTime' ? styles.timeSwitchers__item__active : ''
        }`}
        onClick={() => onTimePeriodChange('allTime')}
      >
        All time
      </p>
    </div>
  );
};

export default TimeSwitchers;
