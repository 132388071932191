// You need to import the function into rootReducer.js
import { WALLETS } from './walletsActionTypes';

const INITIAL_STATE = {
  /**
   * Wallets info
   * @type {Object}
   * @property {number} bitgems_balance
   * @property {number} bitgems_usdt_amount
   * @property {number} bitgems_all_time_profit_usdt
   * @property {number} cht_balance
   * @property {number} cht_min_amount_to_sell
   * @property {string} cht_blockchain_address
   * @property {number} cht_blockchain_balance
   * @property {number} cht_blockchain_usdt
   */
  walletsInfo: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case WALLETS.GET_WALLETS_INFO_SUCCESS:
      return {
        ...state,
        walletsInfo: action.payload.data
      };
    default:
      return state;
  }
}
