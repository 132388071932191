import React, { useEffect, useState } from 'react';
import styles from './Arena.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRooms, joinRoom, selectRoom, setIsCHT } from './storage/arenaActions';
import { classList } from '../../helpers/functions';
import { ReactComponent as VCHTSwitcherIcon } from '../../assets/icons/vcht_switcher.svg';
import { ReactComponent as BitSwitcherIcon } from '../../assets/icons/bit_switcher.svg';
import { ReactComponent as TokensBurned } from '../../assets/icons/tokens_burned.svg';
import { ReactComponent as BitIcon } from '../../assets/icons/Bit_icon.svg';
import { ReactComponent as LastTakes } from '../../assets/icons/last_takes.svg';
import { ReactComponent as PlayerWins } from '../../assets/icons/player_wins.svg';
import { ButtonMUI } from '../../shared';
import AgreementModal from './AgreementModal';
import LowBalanceModal from './LowBalanceModal';
import { useHistory } from 'react-router-dom';
import { mainPath } from '../../routes/paths';
import { clickSound } from '../../app/appActions';

const Arena = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedRoom, rooms, profileInfo, isCHT } = useSelector(({ arena, auth }) => ({ ...arena, ...auth }))
  const [openAgreementModal, setOpenAgreementModal] = useState(false)

  useEffect(() => {
    dispatch(getRooms())
  }, [])

  const handleJoin = () => {
    if (!!profileInfo.do_not_show_entry_payment) {
      dispatch(joinRoom(selectedRoom.type, { current_room: selectedRoom.room_id })).then(res => {
        if (res.type.includes('_SUCCESS')) history.push(mainPath.waitingRoom.path.replace(':roomID', res.payload.data.id))
      })
    } else setOpenAgreementModal(true)
  }

  return (!!selectedRoom
    ? <main className={`${styles.arena} page-wrap`} style={{ backgroundImage: `url("${roomsBackground[selectedRoom.name]}")` }}>
      <div className={styles.arenaOverlay} />
      <div className={styles.arenaOverlay2} />
      <div className={styles.arenaContainer}>
        <div className={styles.switcher}>
          {switchButtons.map((el, idx) => (
            <ButtonMUI
              key={idx}
              className={classList(styles.switcherBtn, { [styles.active]: isCHT === el.is_cht })}
              variant="transparent"
              onClick={() => {
                dispatch(setIsCHT(el.is_cht))
                dispatch(clickSound())
                dispatch(selectRoom(el.is_cht && isCHT ? selectedRoom : rooms.find(room => room.type === (el.is_cht ? selectedRoom.type + '_cht' : selectedRoom.type.replace('_cht', '')))))
              }}
            >
              {el.img}
            </ButtonMUI>
          ))}
        </div>
        <div className={styles.room}>
          <div className={styles.roomSelected}>
            <h1>{selectedRoom.name}</h1>
            <p>{selectedRoom.description}</p>
            <div className={styles.roomActions}>
              <ButtonMUI
                className={styles.roomJoin}
                onClick={handleJoin}
              >
                <span>Join</span>
                <div className={styles.separator} />
                <div className={styles.stake}>{isCHT ? <VCHTSwitcherIcon /> : <BitSwitcherIcon />}{selectedRoom.stake}</div>
              </ButtonMUI>
              {selectedRoom?.participants?.length > 0 && (
                <div className={styles.roomAvatars}>
                  {selectedRoom.participants.slice(0, 3).map(({ avatar, user_id, username }, idx) => (
                    <div
                      className={classList({ [styles.notLast]: selectedRoom.participants.length > 3 })}
                      data-more-count={selectedRoom.participants.length - idx}
                      key={user_id}
                      style={{ zIndex: `${100 + idx}` }}
                    >
                      <img
                        src={avatar}
                        alt={username}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={styles.roomSelector}>
            {rooms.filter(el => isCHT ? el.type.includes('_cht') : !el.type.includes('_cht')).map((el, idx) => (
              <div
                key={idx}
                className={classList(styles.roomItem, { [styles.selected]: el.type === selectedRoom.type })}
                style={{ backgroundImage: `url("${roomsBackground[el.name]}")` }}
                onClick={() => {
                  dispatch(selectRoom(el))
                  dispatch(clickSound())
                }}
              >
                <div className={styles.roomStake}>
                  {isCHT ? <VCHTSwitcherIcon /> : <BitSwitcherIcon />}{el.stake}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.roomStatistic}>
          <div className={styles.roomStatisticItem}>
            <PlayerWins />
            <div>
              <p>Player wins</p>
              <span>{selectedRoom.win_amount} {isCHT ? 'vCHT' : 'BitGems'}</span>
            </div>
          </div>
          <div className={styles.roomStatisticItem}>
            {isCHT
              ? <><TokensBurned />
                <div>
                  <p>The last one's</p>
                  <span>Tokens burned</span>
                </div></>
              : <><LastTakes />
                <div>
                  <p>Last takes</p>
                  <span>{selectedRoom.last_takes_cht} vCHT</span>
                </div></>
            }
          </div>
        </div>
      </div>
      <AgreementModal
        open={openAgreementModal}
        onClose={() => setOpenAgreementModal(false)}
      />
      <LowBalanceModal />
    </main>
    : null);
};

export const roomsBackground = {
  "Magic forest": require('../../assets/images/Magic forest.jpeg'),
  "Arena": require('../../assets/images/Arena.jpeg'),
  "Old castle": require('../../assets/images/Old castle.jpeg')
}

export const roomsMusic = {
  "Magic forest": require('../../assets/sound/Magic Forest by Rafael Krux.mp3'),
  "Arena": require('../../assets/sound/Arena Background.mp3'),
  "Old castle": require('../../assets/sound/Walking In The Old Castle.mp3')
}

const switchButtons = [
  {
    is_cht: false,
    img: <BitSwitcherIcon />,
  },
  {
    is_cht: true,
    img: <VCHTSwitcherIcon />,
  }
]

export default Arena;
