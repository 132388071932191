import { ARENA } from './arenaActionTypes';

export function getRooms() {
  return {
    type: ARENA.GET_ARENA_ROOMS,
    payload: {
      client: 'default',
      request: {
        url: `arena/rooms/`,
        method: 'GET'
      }
    }
  };
}

export function getRoomInner(id) {
  return {
    type: ARENA.GET_ROOM_INNER,
    payload: {
      client: 'default',
      request: {
        url: `arena/rooms/${id}/`,
        method: 'GET'
      }
    }
  };
}

export function joinRoom(type, additionalParams) {
  let returnableObj = {
    type: ARENA.JOIN_ROOM,
    payload: {
      client: 'default',
      request: {
        url: `arena/room/${type}/join/`,
        additionalParams,
        method: 'POST'
      }
    }
  }
  if (!!additionalParams && !!additionalParams.do_not_show_entry_payment) returnableObj.payload.request.data = { do_not_show_entry_payment: additionalParams.do_not_show_entry_payment }
  return returnableObj;
}

export function leaveRoom() {
  return {
    type: ARENA.LEAVE_ROOM,
    payload: {
      client: 'default',
      request: {
        url: `arena/rooms/leave/current/`,
        method: 'POST'
      }
    }
  };
}

export function setRooms(rooms) {
  return {
    type: ARENA.GET_ARENA_ROOMS_SUCCESS,
    payload: {
      data: rooms
    }
  };
}

export function setRoomStatus(status) {
  return {
    type: ARENA.SET_ARENA_ROOMS_STATUS,
    payload: {
      data: status
    }
  };
}

export function setRoomResults(results) {
  return {
    type: ARENA.SET_ARENA_ROOMS_RESULTS,
    payload: {
      data: results
    }
  };
}

export function selectRoom(arena) {
  return {
    type: ARENA.SELECT_ROOM,
    payload: {
      data: arena
    }
  };
}

export function setLowBalanceModalStatus(status) {
  return {
    type: ARENA.SET_LOW_BALANCE_MODAL_STATUS,
    payload: {
      data: status
    }
  };
}

export function setIsCHT(isCHT) {
  return {
    type: ARENA.SET_IS_CHT,
    payload: {
      data: isCHT
    }
  };
}