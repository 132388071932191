import { ReactComponent as Overview } from '../assets/icons/nav/overview.svg';
import { ReactComponent as OverviewActive } from '../assets/icons/nav/overview_active.svg';
import { ReactComponent as Market } from '../assets/icons/nav/market.svg';
import { ReactComponent as MarketActive } from '../assets/icons/nav/market_active.svg';
import { ReactComponent as Wallets } from '../assets/icons/nav/wallets.svg';
import { ReactComponent as WalletsActive } from '../assets/icons/nav/wallets_active.svg';
import { ReactComponent as Arena } from '../assets/icons/nav/arena.svg';
import { ReactComponent as ArenaActive } from '../assets/icons/nav/arena_active.svg';

import DashboardComponent from '../features/Dashboard';
import MarketComponent from '../features/Market';
import WalletsComponent from '../features/Wallets';
import ArenaComponent from '../features/Arena';
import MyAccount from '../features/MyAccount';
import GameHistory from '../features/GameHistory';
import WaitingRoom from '../features/Arena/WaitingRoom';

export const rootAuthPath = '/auth';
export const rootMainPath = '/main';
export const termsAndConditionsPath = `/terms-and-conditions`;
export const privacyPolicyPath = `/privacy-policy`;
export const walletAndExchangeTermsPath = `/wallet-and-exchange-terms`;

export const authPath = {
  signIn: { path: `${rootAuthPath}/sign-in`, name: 'Sign In' },
  signUp: { path: `${rootAuthPath}/sign-up`, name: 'Sign up' },
  passRecovery: { path: `${rootAuthPath}/password-recovery`, name: 'Password recovery' },
  passConfirm: { path: `${rootAuthPath}/password-confirm`, name: 'Reset password' }
};

let paths = {
  overview: {
    path: `${rootMainPath}/overview`,
    name: 'Overview',
    isNavItem: true,
    comingSoon: false,
    icon: <Overview />,
    iconActive: <OverviewActive />,
    component: DashboardComponent,
    sound: require('../assets/sound/Medieval Town Background 1.mp3')
  },
  market: {
    path: `${rootMainPath}/market`,
    name: 'Market',
    isNavItem: true,
    icon: <Market />,
    iconActive: <MarketActive />,
    component: MarketComponent,
    sound: require('../assets/sound/Medieval Market.mp3')
  },
  wallets: {
    path: `${rootMainPath}/wallets`,
    name: 'Wallets',
    isNavItem: true,
    icon: <Wallets />,
    iconActive: <WalletsActive />,
    component: WalletsComponent,
    sound: require('../assets/sound/Medieval Town Background 1.mp3')
  },
  arena: {
    path: `${rootMainPath}/arena`,
    name: 'Arena',
    isNavItem: true,
    comingSoon: false,
    icon: <Arena />,
    iconActive: <ArenaActive />,
    component: ArenaComponent,
    sound: require('../assets/sound/Crowd Arena.wav')
  },
  myAccount: {
    path: `${rootMainPath}/my-account`,
    name: 'My Account',
    isNavItem: false,
    comingSoon: false,
    component: MyAccount
  },
  gameHistory: {
    path: `${rootMainPath}/game-history`,
    name: 'Game History',
    isNavItem: false,
    component: GameHistory
  }
};

paths.waitingRoom = { path: `${rootMainPath}/arena/room/:roomID`, component: WaitingRoom, parent: paths.arena }
/* paths.audioInner = { path: `${rootMainPath}/audio/track/:trackId`, component: Track, parent: paths.audio }
paths.albumInner = { path: `${rootMainPath}/audio/album/:albumId`, component: Album, parent: paths.audio } */

export const mainPath = paths;
