import { takeEvery } from 'redux-saga/effects';
import {
  disableLoader,
  enableLoader,
  openSuccessSnack,
  disableButtonLoader,
  enableButtonLoader
} from '../../app/rootSaga';

export function* handleMyAccountSnacks() {
  yield takeEvery('PATCH_MY_ACCOUNT_SUCCESS', () =>
    openSuccessSnack('Profile updated')
  );
  yield takeEvery('POST_CONTACT_SUPPORT_SUCCESS', () =>
    openSuccessSnack(
      'Thank you for contacting us. We will get back to you shortly'
    )
  );
}

export function* handleMyAccountLoaders() {
  yield takeEvery('GET_MY_ACCOUNT', enableLoader);
  yield takeEvery('GET_MY_ACCOUNT_SUCCESS', disableLoader);
  yield takeEvery('GET_MY_ACCOUNT_FAIL', disableLoader);

  yield takeEvery('PATCH_MY_ACCOUNT', enableButtonLoader);
  yield takeEvery('PATCH_MY_ACCOUNT_SUCCESS', disableButtonLoader);
  yield takeEvery('PATCH_MY_ACCOUNT_FAIL', disableButtonLoader);

  yield takeEvery('POST_CONTACT_SUPPORT', enableButtonLoader);
  yield takeEvery('POST_CONTACT_SUPPORT_SUCCESS', disableButtonLoader);
  yield takeEvery('POST_CONTACT_SUPPORT_FAIL', disableButtonLoader);
}
